import React, { CSSProperties, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import { getGroupMamorioQrCodes } from '../../../modules/Rental'
import { errorIcon } from '../../../icons'
import { isSafari, toId } from '../../../utils/commonUtils'
import './PrintBrochureModal.css'
import { useAppDispatch } from '../../../hooks'
import { generateRentalDigitCSV } from '../../../helpers/CommonMamorioHelper'

type PropType = {
  modalProps: {
    group: {
      id: string,
    }
  }
}

const PrintButton = () => (
  <button type="button" className="btn btn-primary btn-sm">
    印刷する
  </button>
)

const PrintBrochureModal = ({
  modalProps,
}: PropType) => {
  const dispatch = useAppDispatch()

  const [mamorioQrCodes, setMamorioQrCodes] = useState<IQrCode[]>([])

  let printContentRef: React.ReactInstance | null = null

  const createColumn = (qrCode: IQrCode, width: { width: string }) => (
    <td className="print-col" key={`${Date.now()}${qrCode.id}`} style={width}>
      <div className="print-col-inside">
        <div className="print-col-image">
          <img src={qrCode ? qrCode.image_url : errorIcon} className="print-col-qr" alt="qr-code" />
        </div>
        <div className="print-col-desc">
          <div className="print-col-title">
            {qrCode.resource_name}
          </div>
          <div className="print-col-code">
            {qrCode.digit_code}
          </div>
        </div>
      </div>
    </td>
  )

  const emptyColumn = (index: number, width: CSSProperties) => (
    <td className="print-col" key={index} style={width} />
  )

  const createPrintContent = (mamorioList: IQrCode[]) => {
    const columnsPerRow = 2
    const pageBreakAt = isSafari ? 7 : 9
    const width = { width: `${100 / columnsPerRow}%` }
    const rows = []

    let index = 0

    for (let i = 1; i <= Math.ceil(mamorioList.length / columnsPerRow); i += 1) {
      const cols = []
      let leftToPrint = columnsPerRow
      for (let c = index; c < Math.min(columnsPerRow * i, mamorioList.length); c += 1) {
        const mamorio = mamorioList[c]

        if (mamorio) {
          cols.push(createColumn(mamorio, width))
          index += 1
          leftToPrint -= 1
        }
      }

      // If number of Mamorio is odd
      if (leftToPrint !== 0) {
        for (let l = 0; l < leftToPrint; l += 1) {
          cols.push(emptyColumn(index + l, width))
        }
      }
      rows.push(
        <div className="print-row" key={index}>
          <table>
            <tbody>
            <tr>
              {cols}
            </tr>
            </tbody>
          </table>
        </div>,
      )

      if (i % pageBreakAt === 0 && index < mamorioList.length) {
        rows.push(
          <footer key={`footer${index}`} />,
        )
      }
    }
    return rows
  }

  useEffect(() => {
    const { group } = modalProps

    dispatch(getGroupMamorioQrCodes({
      groupId: toId<GroupId>(group.id),
    }))
      .unwrap()
      .then((qrCodes) => setMamorioQrCodes(qrCodes))
      .catch((e) => {
        throw new Error(e.toString())
      })
  }, [dispatch, modalProps])

  return (
    <div className="print-brochure">
      <div className="button-section">
        <ReactToPrint
          trigger={PrintButton}
          content={() => printContentRef}
          bodyClass="print-brochure"
        />
        <button type="button" className="btn btn-primary btn-sm" onClick={() => generateRentalDigitCSV(mamorioQrCodes)}>
          CSVをダウンロード
        </button>
      </div>
      <div className="print-brochure-content" ref={(el) => { printContentRef = el }}>
        {
          createPrintContent(mamorioQrCodes)
        }
      </div>
    </div>
  )
}

PrintBrochureModal.defaultProps = {
  modalProps: {},
}

PrintBrochureModal.propTypes = {
  modalProps: PropTypes.shape({
    group: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
}

export default PrintBrochureModal
