import React from 'react'
import { getFromNowAsText } from '../../helpers/timeHelper'
import GoogleMapWrapperForDetailView from './GoogleMapWrapperForDetailView'
import { firstMapPinDeViceIcon } from '../../icons'

type Props = {
  address: string
  detectionLogs: Pick<IDetectionLog, 'detected_at' | 'latitude' | 'longitude'>[]
  blueprints: IBlueprint[]
}

const DetailDeviceViewMap = ({
  address,
  detectionLogs,
  blueprints,
}: Props) => (
  <div className="map-view">
    <div className="info-box">
      <div className="found-at">
        <div className="address">
          {address}
        </div>
      </div>
      <div className="detected-at">
        {getFromNowAsText(detectionLogs[0]?.detected_at)}
      </div>
    </div>
    <div className="map">
      <GoogleMapWrapperForDetailView
        containerStyle={{ height: '445px' }}
        zoom={18}
        detectionLogs={detectionLogs}
        mapPinIconSrc={firstMapPinDeViceIcon}
        blueprints={blueprints}
      />
    </div>
  </div>
)

export default DetailDeviceViewMap
