import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { closeModal, showModal } from '../../../modules/UI'
import { deleteGroup, updateGroup } from '../../../modules/Group'
import { CONFIRM_MODAL, ERROR_MODAL } from '../../common/RootModal'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getErrorString } from '../../../utils/errorUtils'
import { isValidString } from '../../../utils/commonUtils'
import './EditGroupModal.css'
import { getRootGroupId } from '../../../modules/common/session'

type Props = {
  didUpdageGroupFunction?: (event: React.MouseEvent | void) => void
  closeModalFunction?: (event: React.MouseEvent | void) => void
  group: Pick<IGroup, 'id'>
  inputValue: string
}

const EmptyInputErrorMessage = 'グループ名を入力してください。'

const EditGroupModal = ({
  didUpdageGroupFunction,
  closeModalFunction,
  group,
  inputValue,
}: Props) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.user.user)
  const isAdmin = currentUser?.admin_groups?.some((group_id) => (group_id === group.id))

  const [newGroupName, setNewGroupName] = useState(inputValue)

  /**
   * エラーモーダルを表示する
   */
  const showErrorModal = useCallback((message: string) => {
    if (!isValidString(message)) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  /**
   * グループ名入力欄の値が変化した際の処理
   */
  const handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewGroupName(event.target.value)
  }

  /**
   * 「このグループを削除」ボタン押下時の処理
   */
  const handleClickDeleteButton = () => {
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: 'グループを削除すると、グループに登録されたMAMORIOはMAMORIO OFFICEから見守れなくなります。グループを削除しますか？',
        confirmButtonText: '解除',
        onActionConfirmed: async () => {
          try {
            await dispatch(deleteGroup({ groupId: group.id })).unwrap()

            dispatch(closeModal({ modalType: CONFIRM_MODAL }))

            if (closeModalFunction) closeModalFunction()
          } catch (error: unknown) {
            showErrorModal(getErrorString(error))
          }
        },
      },
    }))
  }

  /**
   * 「保存ボタン」押下時の処理
   */
  const handleClickSaveButton = async () => {
    if (!isValidString(newGroupName)) {
      showErrorModal(EmptyInputErrorMessage)
      return
    }

    try {
      await dispatch(updateGroup({
        groupId: group.id,
        groupName: newGroupName,
      })).unwrap()

      if (closeModalFunction) closeModalFunction()
      if (didUpdageGroupFunction) didUpdageGroupFunction()
    } catch (error: any) {
      showErrorModal(getErrorString(error))
    }
  }

  return (
    <div className="edit-group-modal modal-form">
      <div className="edit-group-form">
        <label>
          グループ名*
        </label>
        <input
          type="text"
          name="add-member-input"
          placeholder="例：MAMORIO株式会社"
          value={newGroupName}
          onChange={handleChangeInputValue}
        />
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClickSaveButton}
      >
        保存
      </button>
      <button
        type="button"
        className="btn btn-outline-primary cancel-button"
        onClick={closeModalFunction}
      >
        キャンセル
      </button>
      {
        (group.id !== getRootGroupId() && isAdmin) && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleClickDeleteButton}
          >
            このグループを削除
          </button>
        )
      }
    </div>
  )
}

EditGroupModal.propTypes = {
  didUpdageGroupFunction: PropTypes.func.isRequired,
  closeModalFunction: PropTypes.func.isRequired,
  group: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }).isRequired,
  inputValue: PropTypes.string.isRequired,
}

export default EditGroupModal
