import React from 'react'
import PropTypes from 'prop-types'
import CheckableListItem from './CheckableListItem'
import './CheckableList.css'

type CheckableListProps = {
  list: {
    checked: boolean
    id: GeneralId,
    label: string
  }[]
  onCheckItem: (id: GeneralId, label: string) => void
}

const CheckableList = ({
  list,
  onCheckItem,
}: CheckableListProps) => (
  <div className="selectable-list">
    {list.map((item) => (
      <CheckableListItem
        key={item.id.toString()}
        id={item.id}
        label={item.label}
        onCheckItem={onCheckItem}
        checked={item.checked}
      />
    ))}
  </div>
)

CheckableList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onCheckItem: PropTypes.func.isRequired,
}

export default CheckableList
