import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import PropTypes from 'prop-types'
import { iconSuccess } from '../../icons'
import './SuccessPopup.css'

type Props = {
  message: string
  expireTime?: number
}

const SuccessPopup = ({
  message,
  expireTime,
}: Props): JSX.Element | null => {
  const [isShown, setIsShow] = useState(true)

  const timer = useRef<number | null>(null)

  const clearTimer = () => {
    setIsShow(false)

    if (timer.current) {
      window.clearTimeout(timer.current)
    }
    timer.current = null
  }

  const setTimer = useCallback(() => {
    if (timer && timer.current) window.clearTimeout(timer.current)

    timer.current = window.setTimeout(clearTimer, expireTime)
  }, [expireTime])

  useEffect(() => {
    setIsShow(true)
    setTimer()

    return () => clearTimer()
  }, [message, expireTime, setTimer])

  if (!isShown) return null

  return (
    <div
      className="success-popup"
      data-testid="success-popup"
    >
      <img src={iconSuccess} className="success-icon" alt="success" />
      {message}
    </div>
  )
}

SuccessPopup.defaultProps = {
  expireTime: 2000,
}

SuccessPopup.propTypes = {
  message: PropTypes.string.isRequired,
  expireTime: PropTypes.number,
}

export default SuccessPopup
