import React from 'react'
import './NotFound.css'

const NotFound = () => (
  <div className="notfound">
    <div className="notice">
      <h1>
        NotFound
      </h1>
      <h2>
        ページが存在しません
      </h2>
    </div>
  </div>
)

export default NotFound
