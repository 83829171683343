import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { logout, updateUser } from '../../modules/User'
import { closeModal, showModal } from '../../modules/UI'
import { ERROR_MODAL } from '../../components/common/RootModal'
import ChannelService from '../../modules/ChannelService'
import { LOGIN } from '../../constants/Paths'
import { GroupNavi, HeaderNavi } from '../../components/navigation'
import Loading from '../../components/common/Loading'
import SuccessPopup from '../../components/common/SuccessPopup'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { defaultUserIcon, miniCameraIcon, miniSettingsIcon } from '../../icons'
import FormThumbNail from '../../components/common/FormThumbNail'
import './User.css'
import { getErrorString } from '../../utils/errorUtils'

const enum InputType {
  Name,
  Password,
  PasswordRepeat,
}

/**
 * User
 * @returns {JSX.Element}
 * @constructor
 */
const User = () => {
  // Redux -----------------------------------------
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user.user)
  const isLoading = useAppSelector((state) => state.ui.loading)

  // States -----------------------------------------
  const [name, setName] = useState<{ value: Nullable<string>, valid: boolean }>({
    value: user?.name || '',
    valid: (user?.name && user?.name?.length > 0) || false,
  })

  const [email] = useState<{ value: Nullable<string> }>({
    value: user?.email,
  })

  const [password, setPassword] = useState<{ value: Nullable<string>, valid: boolean }>({
    value: '',
    valid: true,
  })

  const [passwordRepeat, setPasswordRepeat] = useState<{ value: Nullable<string>, valid: boolean }>({
    value: '',
    valid: true,
  })

  const [image, setImage] = useState<File | null>(null)
  const [redirect, setRedirect] = useState<boolean>(false)
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false)

  // redirect === true ならログアウトする
  useEffect(() => {
    if (!redirect) return

    dispatch(logout())
  }, [redirect, dispatch])

  // Event userApiHandlers -----------------------------------------
  /**
   * アップデートリクエストが成功した際の処理
   */
  const onUpdateComplete = () => {
    setSaveSuccessful(true)

    const passwordInput = document.querySelectorAll('input[name="password"]')[0] as HTMLInputElement
    passwordInput.value = ''

    const passwordRepeatInput = document.querySelectorAll('input[name="password_repeat"]')[0] as HTMLInputElement
    passwordRepeatInput.value = ''

    setTimeout(() => setSaveSuccessful(false), 2000)
  }

  /**
   * アップデートリクエストが失敗した際の処理
   * @param message エラーメッセージ
   */
  const onUpdateError = (message: string) => {
    setSaveSuccessful(false)

    if (message === null || message === undefined) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }

  /**
   * テキスト入力欄の onChange イベントのハンドラー
   * @param type InputType
   * @param target HTMLInputElement
   */
  const handleChangeTextInput = (type: InputType, target: HTMLInputElement) => {
    switch (type) {
      case InputType.Name:
        setName({
          value: target.value,
          valid: target.value.length > 0,
        })
        return
      case InputType.Password:
        setPassword({
          value: target.value,
          valid: target.validity.valid && target.value.length > 7,
        })
        return
      case InputType.PasswordRepeat:
        setPasswordRepeat({
          value: target.value,
          valid: target.validity.valid && target.value.length > 7,
        })
        return
      default:
        // eslint-disable-next-line no-console
        console.warn(`${type} does not exist type`)
        break
    }
  }

  /**
   * 保存ボタンの onClick イベントのハンドラー
   */
  const handleClickSaveButton = async (event: React.MouseEvent) => {
    event.preventDefault()

    const data = {
      name: name.value,
      password: password.value && password.value.length > 0 ? password.value : null,
    }

    if (password.value !== passwordRepeat.value) {
      onUpdateError('パスワードとパスワード (確認) の値が一致していません。')
      return
    }

    try {
      await dispatch(updateUser(data)).unwrap()

      onUpdateComplete()
    } catch (e: unknown) {
      onUpdateError(getErrorString(e))
    }
  }

  /**
   * ログアウトボタンの onClick イベントのハンドラー
   */
  const handleClickLogout = () => {
    ChannelService.shutdown()

    setRedirect(true)
  }

  /**
   * 画像変更ボタンの onClick イベントのハンドラー
   */
  const handleClickChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // NOTE: can not send json body and formData in Swagger v2
    // So, we send image only request immediately.
    if (!event.target.files) return

    const imageFile = event.target.files[0]
    if (imageFile) setImage(imageFile)

    try {
      await dispatch(updateUser({ image: imageFile })).unwrap()

      onUpdateComplete()
    } catch (e: unknown) {
      onUpdateError(getErrorString(e))
    }
  }

  // Methods -----------------------------------------
  /**
   * すべての入力値が正しい値かどうかを返す
   * @return boolean
   */
  const isEveryInputValueValid = (): boolean => {
    if (!password.value || !passwordRepeat.value) return false

    if (password.value.length > 0 || passwordRepeat.value.length > 0) {
      return (
        name.valid
        && password.valid
        && passwordRepeat.valid
        && password.value === passwordRepeat.value
      )
    }

    return name.valid
  }

  // redirect == true ならログイン画面に飛ばす
  if (redirect) {
    return <Redirect to={LOGIN} />
  }

  let thumbnailImageUrl: string
  if (image) {
    thumbnailImageUrl = window.URL.createObjectURL(image)
  } else {
    thumbnailImageUrl = user?.image?.url || defaultUserIcon
  }

  return (
    <div className="content">
      <HeaderNavi />
      <Loading show={isLoading} />
      <GroupNavi>
        <div className="user-settings">
          {saveSuccessful ? <SuccessPopup message="保存しました!" /> : null}
          <div className="user-settings-title">
            <img src={miniSettingsIcon} alt="title" />
            ユーザー設定
            <div className="logout clearfix float-right" onClick={handleClickLogout}>
              ログアウト
            </div>
          </div>
          <div className="user-settings-box">
            <form autoComplete="off">
              <div className="user-settings-inner">
                <div className="user-settings-thumbnail-column">
                  <FormThumbNail imageUrl={thumbnailImageUrl} />
                  <img src={miniCameraIcon} className="user-settings-image" alt="camera" />
                  <div
                    className="user-settings-thumbnail-change"
                    data-testid="user-settings-thumbnail-change"
                  >
                    <label htmlFor="image">
                      写真を変更する
                    </label>
                    <input type="file" name="image" id="image" onChange={handleClickChangeImage} />
                  </div>
                </div>
                <div
                  className="user-settings-column"
                  data-testid="user-settings-email"
                >
                  <label>
                    メールアドレス*
                  </label>
                  <input
                    type="email"
                    name="mail"
                    size={80}
                    placeholder="example@mamorio.jp"
                    value={email.value || ''}
                    disabled
                  />
                </div>
                <div
                  className="user-settings-column"
                  data-testid="user-settings-username"
                >
                  <label htmlFor="name">
                    ユーザー名
                  </label>
                  <input
                    type="text"
                    name="name"
                    size={80}
                    placeholder="例：布田 護雄"
                    value={name.value || ''}
                    onChange={(event) => handleChangeTextInput(InputType.Name, event.target)}
                  />
                </div>
                <div
                  className="user-settings-column"
                  data-testid="user-settings-password"
                >
                  <label htmlFor="password">
                    パスワード
                  </label>
                  <input
                    type="password"
                    name="password"
                    size={80}
                    placeholder="新しいパスワードを入力"
                    autoComplete="new-password"
                    onChange={(event) => handleChangeTextInput(InputType.Password, event.target)}
                  />
                </div>
                <div
                  className="user-settings-column"
                  data-testid="user-settings-password-repeat"
                >
                  <label htmlFor="password">
                    パスワード（確認）
                  </label>
                  <input
                    type="password"
                    name="password_repeat"
                    size={80}
                    placeholder="新しいパスワードを入力"
                    autoComplete="new-password"
                    onChange={(event) => handleChangeTextInput(InputType.PasswordRepeat, event.target)}
                  />
                </div>
                <div
                  className="user-settings-button-column"
                  data-testid="user-settings-save-button"
                >
                  <button
                    type="button"
                    name="save"
                    className="btn btn-primary"
                    disabled={!isEveryInputValueValid}
                    onClick={handleClickSaveButton}
                  >
                    保存
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </GroupNavi>
    </div>
  )
}

export default User
