import moment from 'moment'

moment.locale('ja')

export const getDurationFrom = (dateTime: moment.MomentInput): moment.Duration => {
  const logMoment = moment(dateTime)
  const currentMoment = moment()
  const diff = currentMoment.diff(logMoment)
  return moment.duration(diff)
}

export const getFullDate = (dateTime: moment.MomentInput): string => (moment(dateTime).format())
export const getFormattedDate = (dateTime: moment.MomentInput, format: string = 'YYYY/MM/DD HH:mm'): string => (moment(dateTime).format(format))

export const getFromNowAsText = (dateTime: moment.MomentInput): string => {
  if (getDurationFrom(dateTime).asHours() < 1) {
    return moment(dateTime).fromNow()
  }

  return getFormattedDate(dateTime)
}

export const isWithinLast10Minutes = (dateTime: moment.MomentInput): boolean => {
  const duration = getDurationFrom(dateTime)
  return duration.asMinutes() <= 10
}

export default getDurationFrom
