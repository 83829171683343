import React from 'react'

const GroupCreationInquiryInfo = () => (
  <p className="message">
    グループを作成したい際は
    <a target="blank" href="https://mamorio.typeform.com/to/dwak6v">
      MAMORIOまでお問い合わせ
    </a>
    ください。
  </p>
)

export default GroupCreationInquiryInfo
