import React from 'react'
import PropTypes from 'prop-types'
import './ErrorModal.css'
import errorIcon from '../../icons/icon_error@2x.png'

type Props = {
  closeModalFunction: (arg: unknown | void) => void
  modalProps: {
    message: string
  }
}

const ErrorModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const buildMessageDom = (message: any) => (
    message.toString()
      .split('\n')
      .map((m: string) => (
        <p key={m}>
          {m}
        </p>
      ))
  )

  return (
    <div data-testid="error-modal">
      <div className="mamorio-error-modal">
        <div className="mamorio-error-modal-item">
          <img src={errorIcon} className="mamorio-error-modal-image" alt="error" />
          <div className="mamorio-error-modal-message">
            {buildMessageDom(modalProps.message || '')}
          </div>
          <button
            type="button"
            name="settings"
            className="btn btn-primary"
            onClick={closeModalFunction}
          >
            OK
          </button>
        </div>
      </div>
      <div id="overlay" />
    </div>
  )
}

ErrorModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
}

export default ErrorModal
