import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MamoriosMapDetailView from './MamoriosMapDetailView'
import { MAMORIO_SHAPE } from '../../constants/Shapes'
import { closeIcon } from '../../icons'
import { getMamoriosWithLocation } from '../../helpers/CommonMamorioHelper'
import './MamoriosMapModal.css'

type Props = {
  closeModalFunction: (arg: any | void) => void
  modalProps: {
    mamorios: IMamorio[]
  }
}

const MamoriosMapModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const [mamorios] = useState<IMamorio[]>(modalProps.mamorios)
  const [nameFilter, setNameFilter] = useState<string | null>(null)

  return (
    <div className="detail-modal-container">
      <div
        className="overlay"
        onClick={closeModalFunction}
      />
      <div className="detail-base mamorios-map-modal" data-testid="mamorios-map-modal">
        <div className="scrollable">
          <div className="title-row" style={{ border: '0px' }}>
            <div className="detail-title">
              {
                mamorios[0] && mamorios[0].type === 'Devices::GroupDevice' ? '端末一覧' : 'MAMORIO一覧'
              }
            </div>
            <div>
              <button
                onClick={closeModalFunction}
                className="close-button"
                type="button"
              >
                <img
                  src={closeIcon}
                  alt="close-button"
                />
              </button>
            </div>
          </div>
          <div className="name-filter">
            名前で検索
            <input
              type="text"
              onChange={(event) => setNameFilter(event.target.value)}
            />
          </div>
          <div className="detail-modal-content">
            <MamoriosMapDetailView
              mamorios={getMamoriosWithLocation(mamorios)}
              nameFilter={nameFilter}
              zoom={13}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

MamoriosMapModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    mamorios: PropTypes.arrayOf(MAMORIO_SHAPE).isRequired,
  }).isRequired,
}

export default MamoriosMapModal
