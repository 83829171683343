import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { createGroup } from '../../../modules/Group'
import { closeModal, showModal } from '../../../modules/UI'
import CheckableList from '../../common/CheckableList'
import { ERROR_MODAL } from '../../common/RootModal'
import { deleteElement } from '../../../utils/arrayUtil'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { isValidString } from '../../../utils/commonUtils'
import './CreateGroupModal.css'
import { getErrorString } from '../../../utils/errorUtils'

type Props = {
  closeModalFunction?: (event: React.MouseEvent | void) => void
}

const ErrorMessageOnEmptyInput = 'グループ名を入力してください。'

/**
 * グループ作成モーダル
 * @param closeModalFunction このモーダルを閉じるための関数
 */
const CreateGroupModal = ({
  closeModalFunction,
}: Props) => {
  const dispatch = useAppDispatch()
  const rootGroupUsers = useAppSelector((state) => state.group?.rootGroup?.users)

  const [inputValue, setInputValue] = useState('')
  const [selectedEmailAddresses, setSelectedEmailAddresses] = useState<string[]>([])

  const handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)

  /**
   * グループの作成に失敗した際の処理
   */
  const onFailedToCreateGroup = useCallback((message: string) => {
    if (!isValidString(message)) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  /**
   * 「保存」ボタン押下時の処理
   */
  const handleClickSaveButton = async () => {
    if (!isValidString(inputValue)) {
      dispatch(showModal({
        modalType: ERROR_MODAL,
        modalProps: {
          message: ErrorMessageOnEmptyInput,
        },
      }))
    } else {
      try {
        await dispatch(createGroup({
          groupName: inputValue,
          emailAddresses: selectedEmailAddresses,
        })).unwrap()

        if (closeModalFunction) closeModalFunction()
      } catch (error: unknown) {
        onFailedToCreateGroup(getErrorString(error))
      }
    }
  }

  /**
   * クリックされたメールアドレスの選択状態を切り替える
   * @param _
   * @param emailAddress 対象のメールアドレス
   */
  const handleToggleEmailAddress = (_: number, emailAddress: string) => {
    if (selectedEmailAddresses.includes(emailAddress)) {
      // 選択済みリストに存在する場合はリストから削除する
      setSelectedEmailAddresses(deleteElement(selectedEmailAddresses, (u) => u === emailAddress))
    } else {
      // リストに存在しない場合は追加する
      setSelectedEmailAddresses([...selectedEmailAddresses, emailAddress])
    }
  }

  return (
    <div className="create-group-modal modal-form">
      <div className="group-name-culum">
        <div>
          グループ名*
        </div>
        <div>
          <input
            type="text"
            name="add-member-input"
            placeholder="例：MAMORIO株式会社"
            onChange={handleChangeInputValue}
          />
        </div>
      </div>
      <div className="group-menber-culum">
        <div className="group-text-label">
          メンバー
        </div>
        <CheckableList
          list={
            rootGroupUsers
              ? rootGroupUsers.map((user) => ({
                id: user.id,
                label: user.email,
                checked: selectedEmailAddresses.includes(user.email),
              }))
              : []
          }
          onCheckItem={handleToggleEmailAddress}
        />
      </div>
      <div>
        メンバーは後からでも追加することができます。
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleClickSaveButton}
      >
        保存
      </button>
      <button
        type="button"
        className="btn btn-outline-primary cancel-button"
        onClick={closeModalFunction}
      >
        キャンセル
      </button>
    </div>
  )
}

CreateGroupModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
}

export default CreateGroupModal
