import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { LOGIN, OVERVIEW } from '../../constants/Paths'
import { toggleGroupNavi } from '../../modules/UI'
import {
  defaultUserIcon,
  headerLogoIcon2,
  headerDrawerOpenIcon,
  headerDrawerCloseIcon,
  iconFaq,
  iconNews,
} from '../../icons'
import './HeaderNavi.css'

const HeaderNavi = () => {
  const [redirect] = useState(false)

  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user.user)
  const newsItems = useAppSelector((state) => state.news?.newsItems)
  const showGroupNavi = useAppSelector((state) => state.ui.showGroupNavi)

  function unreadNewsCount(): Number {
    if (!newsItems) {
      return 0
    }
    // ログアウト状態
    if (user?.email === '') {
      return 0
    }
    if (!user?.read_news_at) {
      return newsItems.length
    }
    return newsItems.filter((n) => n.created_at > user.read_news_at!).length
  }

  if (redirect) return <Redirect to={LOGIN} />

  return (
    <div className="mamorio-nav" data-testid="header-navi">
      {
        user
        && (
          <div
            className="mamorio-nav-left"
            onClick={() => {
              dispatch(toggleGroupNavi())
            }}
          >
            <img
              className="burger-icon"
              src={showGroupNavi ? headerDrawerCloseIcon : headerDrawerOpenIcon}
              alt="burger"
            />
          </div>
        )
      }
      <Link className="mamorio-home" to={OVERVIEW} data-testid="header-logo">
        <img className="logo" src={headerLogoIcon2} alt="MAMORIO" />
      </Link>
      {
        user && user.email !== ''
        && (
          <div className="mamorio-nav-right">
            <a className="faq-icon" href="https://mamorio.wraptas.site/17859a4dc5dd4f7d8fa76b189e9fee89" target="_blank" rel="noreferrer">
              <img
                src={iconFaq}
                alt="faq"
                onError={(e) => { e.currentTarget.src = defaultUserIcon }}
              />
            </a>
            <Link className="news-icon" to="/news" data-testid="header-news">
              <img
                src={iconNews}
                alt="news"
                onError={(e) => { e.currentTarget.src = defaultUserIcon }}
              />
              {
                unreadNewsCount().valueOf() > 0 && (
                  <span className="unreadNewsCount">
                    {unreadNewsCount()}
                  </span>
                )
              }
            </Link>
            <Link className="current-user" to={`/users/${user.id}/setting`} data-testid="header-user-info">
              <img
                src={user.image?.normal?.url || defaultUserIcon}
                alt="user"
                onError={(e) => { e.currentTarget.src = defaultUserIcon }}
              />
              <span data-testid="header-user-email">{user.name ? user.name : user.email}</span>
            </Link>
          </div>
        )
      }
    </div>
  )
}

export default HeaderNavi
