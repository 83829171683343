import React from 'react'
import Loading from './Loading'

const CommonLoadingView = () => (
  <div
    className="common"
    style={{
      position: 'relative',
      height: '100%',
      width: '100%',
    }}
    data-testid="common-loading-view"
  >
    <div
      className="loading"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    >
      <Loading show />
    </div>
  </div>
)

export default CommonLoadingView
