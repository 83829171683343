import React from 'react'
import PropTypes from 'prop-types'

type Props = {
  onClick: (event: React.MouseEvent) => Promise<void> | void
}

const ViewAllMamoriosInMapButton = ({
  onClick,
}: Props) => (
  <button
    type="button"
    className="btn btn-primary"
    onClick={onClick}
    data-testid="view-all-mamorios-in-map-button"
  >
    地図で見る
  </button>
)

ViewAllMamoriosInMapButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ViewAllMamoriosInMapButton
