import React from 'react'
import {
  iconSpotSideMenu,
  iconEmptyStateSpot,
} from '../../../icons'
import './SettingsMamorioSpot.css'
import { isAnyElementInArray } from '../../../utils/arrayUtil'
import TabMenu from '../../navigation/TabMenu'
import { useAppSelector } from '../../../hooks'
import SettingTabsInfo from '../../../constants/SettingTabsInfo'

const CreateSpotItem = (device: IDevice) => (
  <div className="spot-item" key={device.id}>
    <div className="image">
      <img src={iconSpotSideMenu} alt="スポット画像" />
    </div>
    <div className="name">
      {device.name}
    </div>
  </div>
)

const Info = () => (
  <p className="message">
    Spotを追加したい際は
    <a target="blank" href="https://mamorio.typeform.com/to/dwak6v">
      MAMORIOまでお問い合わせ
    </a>
    ください。
  </p>
)

const ListView = (devices: IDevice[]) => (
  <>
    <button type="button" className="btn btn-primary" disabled>
      Spotを追加
    </button>
    <Info />
    {devices.map((device) => CreateSpotItem(device))}
  </>
)

const EmptyListView = () => (
  <div className="empty-list">
    <img src={iconEmptyStateSpot} alt="spot" />
    <p className="title">
      MAMORIO Spotがありません
    </p>
    <p className="description">
      MAMORIO Spotを追加すると、
      <br />
      物品のより詳しい位置情報を把握することが可能です。
    </p>
    <button type="button" className="btn btn-primary" disabled>
      Spotを追加
    </button>
    {Info()}
  </div>
)

export default () => {
  const team = useAppSelector((state) => state.team)
  const devices = team.groups?.flatMap((group) => group.devices).flat()
  const tabsInfo = SettingTabsInfo()
  return (
    <TabMenu tabsInfo={tabsInfo}>
      <div className="title">
        MAMORIO Spotの管理
      </div>
      <div className="mamorio-spot">
        {
          devices && isAnyElementInArray(devices) ? ListView(devices) : EmptyListView()
        }
      </div>
    </TabMenu>
  )
}
