import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import Toggle from 'react-toggle'
import { Redirect } from 'react-router-dom'
import { GROUP_SHAPE, GROUP_USER_SHAPE } from '../../../constants/Shapes'
import { closeModal, showModal } from '../../../modules/UI'
import { removeUserFromGroup, updateMemberInfo, updateUserInGroup } from '../../../modules/Group'
import { CONFIRM_MODAL, ERROR_MODAL } from '../../common/RootModal'
import FormThumbNail from '../../common/FormThumbNail'
import { defaultUserIcon } from '../../../icons'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { isValidString } from '../../../utils/commonUtils'
import { getErrorString } from '../../../utils/errorUtils'
import { UNAUTHORIZED } from '../../../constants/Paths'
import SuccessPopup from '../../common/SuccessPopup'
import Loading from '../../common/Loading'
import './EditUserModal.css'

type ModalProps = {
  user?: IUser
  group?: IGroup
  onActionConfirmed: () => void
}

type Props = {
  modalProps: ModalProps
  closeModalFunction?: () => void
  onSuccessRemoveUser: (userId: UserId) => void
}

const EditUserModal = ({
  closeModalFunction,
  modalProps,
  onSuccessRemoveUser,
}: Props) => {
  const dispatch = useAppDispatch()

  const [user, setUser] = useState<IUser | undefined>(modalProps.user)
  const [name, setName] = useState<{ value: Nullable<string>, valid: boolean }>({
    value: modalProps.user?.name || '',
    valid: (modalProps.user?.name && modalProps.user?.name?.length > 0) || false,
  })
  const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false)
  const isLoading = useAppSelector((state) => state.ui.loading)
  const currentUser = useAppSelector((state) => state.user.user)
  const isAdmin = currentUser?.admin_groups?.some((group_id) => (group_id === modalProps.group?.id))
  const userIsMe = user?.id === currentUser?.id

  const showErrorModal = useCallback((message: string) => {
    if (!isValidString(message)) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  const handleClickRemoveButton = (userId: UserId, group: IGroup) => {
    const groupId = group.id
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: `グループ「${group.name}」から削除すると、このメンバーはグループ内のMAMORIOを閲覧できなくなります。グループから削除しますか？`,
        confirmButtonText: '削除',
        onActionConfirmed: async () => {
          try {
            await dispatch(removeUserFromGroup({ groupId, userId })).unwrap()
            onSuccessRemoveUser(userId)
            if (closeModalFunction) closeModalFunction()
          } catch (error: unknown) {
            showErrorModal(getErrorString(error))
          } finally {
            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          }
        },
      },
    }))
  }

  const updateAdmin = async () => {
    if (!user || !modalProps.group) return
    const groupId = modalProps.group.id
    const userData = {
      ...user,
      admin: !user.admin,
    }
    await dispatch(updateUserInGroup({
      groupId,
      user: userData,
    })).unwrap()
    modalProps.onActionConfirmed()
    setUser({
      ...user,
      admin: !user.admin,
    })
  }

  const onToggleAdmin = async () => {
    if (userIsMe && isAdmin) {
      dispatch(showModal({
        modalType: CONFIRM_MODAL,
        modalProps: {
          message: '管理者権限をOFFにすると操作が制限され、自分で戻すことはできません。よろしいですか？',
          confirmButtonText: 'はい',
          onActionConfirmed: async () => {
            try {
              await updateAdmin()
            } catch (e: unknown) {
              showErrorModal(getErrorString(e))
            } finally {
              dispatch(closeModal({ modalType: CONFIRM_MODAL }))
            }
          },
        },
      }))
    } else {
      try {
        await updateAdmin()
      } catch (e: unknown) {
        showErrorModal(getErrorString(e))
      }
    }
  }

  const updateAllowApiAccess = async () => {
    if (!user || !modalProps.group) return
    const groupId = modalProps.group.id
    const userData = {
      ...user,
      allow_api_access: !user.allow_api_access,
    }
    await dispatch(updateUserInGroup({
      groupId,
      user: userData,
    })).unwrap()
    modalProps.onActionConfirmed()
    setUser({
      ...user,
      allow_api_access: !user.allow_api_access,
    })
    console.error(`switched ${!user.allow_api_access}`)
  }

  const onToggleAllowApiAccess = async () => {
    if (userIsMe && isAdmin) {
      dispatch(showModal({
        modalType: CONFIRM_MODAL,
        modalProps: {
          message: 'APIアクセスをOFFにすると管理コンソールへのアクセスが不可能になり、自分で戻すことはできません。よろしいですか？',
          confirmButtonText: 'はい',
          onActionConfirmed: async () => {
            try {
              await updateAllowApiAccess()
            } catch (e: unknown) {
              showErrorModal(getErrorString(e))
            } finally {
              dispatch(closeModal({ modalType: CONFIRM_MODAL }))
            }
          },
        },
      }))
    } else {
      try {
        await updateAllowApiAccess()
      } catch (e: unknown) {
        showErrorModal(getErrorString(e))
      }
    }
  }

  const onUpdateComplete = () => {
    modalProps.onActionConfirmed()
    setSaveSuccessful(true)
    setTimeout(() => setSaveSuccessful(false), 2000)
  }

  const handleClickSaveButton = async (event: React.MouseEvent) => {
    event.preventDefault()

    if (!user || !name.value || !modalProps.group) return
    const groupId = modalProps.group.id

    try {
      await dispatch(updateMemberInfo({
        groupId,
        userId: user.id,
        name: name.value!,
      })).unwrap()

      onUpdateComplete()
    } catch (e: unknown) {
      showErrorModal(getErrorString(e))
    }
  }

  if (!user) {
    return <Redirect to={UNAUTHORIZED} />
  }

  return (
    <div
      className="modal-form"
      data-testid="edit-user-modal"
    >
      <div className="edit-user-modal">
        <div>
          <Loading show={isLoading} />
          {saveSuccessful ? <SuccessPopup message="保存しました!" /> : null}
          <FormThumbNail
            imageUrl={!user.image ? defaultUserIcon : user.image.url}
          />
          <label className="permission-switch">
            <span>管理者</span>
            <Toggle
              checked={user.admin}
              icons={false}
              onChange={onToggleAdmin}
              disabled={!isAdmin}
            />
          </label>
          {
            user.allow_api_access !== undefined && (
              <label className="permission-switch">
                <span>管理コンソールへのアクセス</span>
                <Toggle
                  checked={user.allow_api_access}
                  icons={false}
                  onChange={onToggleAllowApiAccess}
                  disabled={!isAdmin}
                />
              </label>
            )
          }
          <div
            className="user-settings-column"
            data-testid="user-settings-email"
          >
            <label>
              メールアドレス*
            </label>
            <input
              type="email"
              name="mail"
              size={80}
              placeholder="example@mamorio.jp"
              value={user.email}
              disabled
            />
          </div>
          <div
            className="user-settings-column"
            data-testid="user-settings-username"
          >
            <label htmlFor="name">
              ユーザー名
            </label>
            <input
              type="text"
              name="name"
              size={80}
              placeholder="例：布田 護雄"
              defaultValue={name.value || ''}
              disabled={!isAdmin && !userIsMe}
              onChange={(event) => {
                setName({
                  value: (event.target as HTMLInputElement).value,
                  valid: (event.target as HTMLInputElement).value.length > 0,
                })
              }}
            />
          </div>

          <div
            className="user-settings-button-column"
            data-testid="user-settings-save-button"
          >
            <button
              type="button"
              name="save"
              className="btn btn-primary"
              disabled={!name.valid}
              onClick={handleClickSaveButton}
            >
              保存
            </button>
          </div>
          {
            (modalProps?.group && (isAdmin || userIsMe)) && (
              <div
                className="user-settings-button-column"
              >
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleClickRemoveButton(user.id, modalProps.group!)}
                >
                  グループから削除
                </button>
              </div>
            )
          }
          <div
            className="user-settings-button-column"
          >
            <button
              type="button"
              className="btn btn-outline-primary cancel-button"
              onClick={closeModalFunction}
            >
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

EditUserModal.defaultProps = {
  onSuccessRemoveUser: null,
}

EditUserModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    onActionConfirmed: PropTypes.func.isRequired,
    user: GROUP_USER_SHAPE.isRequired,
    group: GROUP_SHAPE,
  }).isRequired,
  onSuccessRemoveUser: PropTypes.func,
}

export default EditUserModal
