import React from 'react'
import PropTypes from 'prop-types'
import { closeIcon } from '../../icons'
import './BaseModal.css'

type Props = {
  closeModalFunction: (arg: unknown | void) => void
  modalProps: {
    children: React.ReactElement
    title: string
    onActionConfirmed?: (arg: unknown | void) => void
  }
}

/**
 * 「閉じる」ボタンを持つ汎用のモーダル
 * @param closeModalFunction
 * @param modalProps
 * @return {JSX.Element}
 * @constructor
 */
const BaseModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const { children } = modalProps
  const clonedChildren = React.cloneElement(children, {
    closeModalFunction,
    modalProps,
  })

  return (
    <div className="base-modal" data-testid="base-modal">
      <header className="navbar base-modal-header">
        <div className="header-center">
          {modalProps.title}
        </div>
        <div className="header-left">
          <div className="close-button" onClick={closeModalFunction}>
          <span className="base-modal-button">
              閉じる
          </span>
            <img src={closeIcon} className="close-button-image" alt="close-button" />
          </div>
        </div>
      </header>
      <div className="base-modal-container">
        {clonedChildren}
      </div>
    </div>
  )
}

BaseModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    title: PropTypes.string.isRequired,
    onActionConfirmed: PropTypes.func,
  }).isRequired,
}

export default BaseModal
