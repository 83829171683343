import React, { useCallback, useEffect } from 'react'
import { showModal } from '../../../../modules/UI'
import TabMenu from '../../../navigation/TabMenu'
import { BASE_MODAL } from '../../../common/RootModal'
import CreateGroupModal from '../../modal/CreateGroupModal'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import GroupCreationInquiryInfo from '../GroupCreationInquiryInfo'
import EmptyGroup from '../EmptyGroup'
import GroupDetails from '../GroupDetails'
import './SettingsGroupManagement.css'
import SettingTabsInfo from '../../../../constants/SettingTabsInfo'
import { getTeamGroups } from '../../../../modules/Team'
import { getErrorString } from '../../../../utils/errorUtils'

const SettingsGroupManagement = () => {
  const dispatch = useAppDispatch()
  const groups = useAppSelector((state) => state.team.groups)

  const handleClickCreateGroup = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'グループの作成',
        onActionConfirmed: undefined,
        children: <CreateGroupModal />,
      },
    }))
  }

  const fetchTeamGroups = useCallback(async () => {
    try {
      await dispatch(getTeamGroups(false)).unwrap()
    } catch (error: unknown) {
      // TODO: 例外処理
      console.error(getErrorString(error))
    }
  }, [dispatch])

  useEffect(() => {
    fetchTeamGroups()
  }, [fetchTeamGroups])

  if (!groups) {
    return (<EmptyGroup onClick={handleClickCreateGroup} />)
  }

  const tabsInfo = SettingTabsInfo()

  return (
    <TabMenu tabsInfo={tabsInfo}>
      <div className="title">
        グループの管理
      </div>
      <div className="group-manager">
        <div className="create-group clearfix">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClickCreateGroup}
              disabled
            >
              グループを作成
            </button>
            <GroupCreationInquiryInfo />
          </div>
        </div>
        <div className="group-lists-box clearfix">
          {
            groups?.map((group) => <GroupDetails key={`${Date.now()}${group.name}`} group={group} />)
          }
        </div>
      </div>
    </TabMenu>
  )
}

export default SettingsGroupManagement
