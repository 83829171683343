import React, {
  useCallback, forwardRef, useEffect, useImperativeHandle, useRef,
} from 'react'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Downshift from 'downshift'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}))

type Props = {
  onUpdateItems: (arg: any) => void
  className?: string
  existingItems?: any[]
  fullWidth?: boolean
  placeholder?: string
}

const ChipsInput = forwardRef(({
  onUpdateItems,
  className,
  existingItems,
  fullWidth,
  placeholder,
}: Props, ref) => {
  const classes = useStyles()

  // input の入力値
  const [inputValue, setInputValue] = React.useState('')
  // 現在追加されているアイテムの配列
  const [addedItems, setAddedItems] = React.useState<any[]>([])

  // create a ref for TextField
  const textInputRef = useRef(null)

  const updateItems = useCallback((items) => {
    onUpdateItems(items)
  }, [onUpdateItems])

  // effect which is reacted with "addedItems" update
  useEffect(() => {
    updateItems(addedItems)
  }, [addedItems, updateItems])

  /**
   * キー押下時の処理
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Enter キーまたは Space キー
    if (event.key === 'Enter' || event.key === 'Space') {
      // 空白を取り除いたあとの入力値が空ならスキップする
      const value = (event.target as HTMLInputElement).value.replace(/\s/g, '').trim()
      if (!value.length) return

      // 不正なフォーマットは受け付けない
      if (!(event.target as HTMLInputElement).validity.valid) {
        setInputValue('')
        return
      }

      // 対象のアイテムがすでに追加済みのアイテム (addedItems + existingItems) の中にあれば追加しない
      if (addedItems.concat(existingItems).indexOf(value) !== -1) {
        setInputValue('')
        return
      }

      setAddedItems([...addedItems, value])
      setInputValue('')
    }

    // バックスペース
    if (
      event.key === 'Backspace'
      && addedItems.length
      && !inputValue.length
    ) {
      setAddedItems(addedItems.slice(0, addedItems.length - 1))
    }
  }

  // Downshift の onChange イベントのハンドラー
  const handleChangeSelection = (selection: any) => {
    let newSelectedItem = [...addedItems] as any[]

    if (newSelectedItem.indexOf(selection) === -1) {
      newSelectedItem = [...newSelectedItem, selection]
    }

    setInputValue('')
    setAddedItems(newSelectedItem)
  }

  // Chip の onDelete イベントのハンドラー
  // 削除されたアイテムを addedItems のリストから削除する
  const handleDelete = (deletedItem: any) => () => {
    const newSelectedItem = [...addedItems]
    newSelectedItem.splice(newSelectedItem.indexOf(deletedItem), 1)
    setAddedItems(newSelectedItem)
  }

  // input 要素の change イベントのハンドラー
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  useImperativeHandle(ref, () => ({
    clear: () => {
      setAddedItems([])
    },
    focus: () => {
      // @ts-ignore
      textInputRef?.current?.focus()
    },
  }))

  return (
    <Downshift
      id="downshift-multiple"
      inputValue={inputValue}
      onChange={(selection) => handleChangeSelection(selection)}
      selectedItem={addedItems}
    >
      {({ getInputProps }) => {
        // input 用のプロパティを取得する
        const {
          onBlur,
          onChange,
          onFocus,
          ...inputProps
        } = getInputProps({
          onKeyDown: handleKeyDown,
          placeholder,
        })

        return (
          <div className={className}>
            {/* @ts-ignore */}
            <TextField
              InputProps={{
                startAdornment: addedItems.map((item) => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    className={classes.chip}
                    onDelete={handleDelete(item)}
                  />
                )),
                onBlur,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange(event)

                  if (onChange) {
                    onChange(event)
                  }
                },
                onFocus,
              }}
              {...inputProps}
              fullWidth={fullWidth}
              ref={textInputRef}
              variant="standard"
              type="email"
            />
          </div>
        )
      }}
    </Downshift>
  )
})

ChipsInput.defaultProps = {
  className: '',
  existingItems: [],
  fullWidth: false,
  placeholder: '',
}

ChipsInput.propTypes = {
  onUpdateItems: PropTypes.func.isRequired,
  className: PropTypes.string,
  existingItems: PropTypes.arrayOf(PropTypes.string),
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default ChipsInput
