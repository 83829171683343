import Bugsnag from '@bugsnag/js'

const getErrorString = (error: unknown): string => {
  // Bugsnag.notify(error)
  if (error instanceof Error) {
    Bugsnag.notify(error)
    return error.message
  }
  if (error instanceof Object) {
    Bugsnag.notify(new Error(error.toString()))
    return error.toString()
  }
  if (typeof error === 'string') {
    return error
  }

  // eslint-disable-next-line no-console
  console.log(`unknown error occured at getErrorString. ${typeof error} ${error}`)
  Bugsnag.notify(new Error(`unknown error occured at getErrorString. ${typeof error} ${error}`))

  return 'エラーが発生しました。'
}

const throwError = (error: unknown): void => {
  throw new Error(getErrorString(error))
}

// TODO: 不要
const retrieveResponseErrorMessage = (error: unknown): string | any => {
  if (!error) {
    Bugsnag.notify(new Error('error without message occured at retrieveResponseErrorMessage'))
    return 'エラーが発生しました。'
  }

  if (error instanceof Error) {
    Bugsnag.notify(error)
    return error.message
  }

  if (error instanceof Object) {
    Bugsnag.notify(new Error(error.toString()))
    return error.toString()
  }
  // eslint-disable-next-line no-console
  console.log(`unknown error occured at retrieveResponseErrorMessage. ${typeof error} ${error}`)
  Bugsnag.notify(new Error(`unknown error occured at retrieveResponseErrorMessage. ${typeof error} ${error}`))
  return 'エラーが発生しました。'
}

export { throwError, getErrorString, retrieveResponseErrorMessage }
