import React from 'react'
import ReactToPrint from 'react-to-print'
import './RentalModal.css'
import { Redirect } from 'react-router-dom'
import rentalGroupQrImage from '../../images/rental_howto_groupqr.png'
import rentalGroupQrBookImage from '../../images/rental_howto_mamorioqr-book.png'
import rentalGroupQrSealImage from '../../images/rental_howto_mamorioqr-seal.png'
import template from '../../images/group_qr_temp.svg'
import { BASE_MODAL } from '../common/RootModal'
import { showModal } from '../../modules/UI'
import PrintLabelModal from './qrPrinting/PrintLabelModal'
import PrintBrochureModal from './qrPrinting/PrintBrochureModal'
import InlineLoadingAnimation from '../common/InlineLoadingAnimation'
import { useAppDispatch } from '../../hooks'
import { UNAUTHORIZED } from '../../constants/Paths'

type Props = {
  group: IGroup,
}

const RentalModal = ({
  group,
}: Props) => {
  const dispatch = useAppDispatch()
  let componentRef: React.ReactInstance | null = null

  const onPrintBrochureClicked = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'MAMORIO QRを印刷する（冊子）',
        onActionConfirmed: undefined,
        children: <PrintBrochureModal />,
        group,
      },
    }))
  }

  const onPrintSealClicked = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'MAMORIO QRを印刷する（シール）',
        onActionConfirmed: undefined,
        children: <PrintLabelModal />,
        group,
      },
    }))
  }

  if (!group) {
    return <Redirect to={UNAUTHORIZED} />
  }

  return (
    <div className="group-rental-modal" data-testid="rental-modal">
      <div className="middle-section">
        <div className="qr-section" ref={(el) => { componentRef = el }}>
          <svg>
            <image xlinkHref={template} width="100%" height="100%" />
          </svg>
          <div className="qr-print">
            <div className="qr-title">
              グループQR
            </div>
            <div className="qr-code-content">
              {
                group.qr_code.image_url ? (
                  <img src={group.qr_code.image_url} className="qr-code" alt="qr-code" />
                ) : (
                  <div className="qr-code loader">
                    <InlineLoadingAnimation size="48px" color="#4ca7db" />
                  </div>
                )
              }
            </div>
            <div className="qr-desc">
              {
                group.name
              }
              <br />
              {
                group.qr_code.digit_code || '...'
              }
            </div>
          </div>
        </div>
        <div className="step-section">
          <div className="step">
            <div className="step-title">
              STEP1
            </div>
            <div className="step-desc">
              <span className="accent">
                グループQR
              </span>
              を印刷し、このグループのMAMORIOを返却してほしい場所に貼ってください。
            </div>
            <div className="tut-step1">
              <ReactToPrint
                trigger={() => (
                  <button type="button" className="btn btn-primary btn-sm">
                    グループQRを印刷する
                  </button>
                )}
                content={() => componentRef}
                bodyClass="group-print-qr"
              />
              <div className="tut-step1">
                <img src={rentalGroupQrImage} alt="tutorial step1" />
              </div>
            </div>
          </div>
          <div className="step">
            <div className="step-title">
              STEP2
            </div>
            <div className="step-desc">
              <span className="accent">
                MAMORIO QR
              </span>
              を印刷して設置してください。QRコードを各MAMORIOに貼る
              <span className="accent">
                シールタイプ
              </span>
              か、QRコードをまとめた冊子を返却場所に設置する
              <span className="accent">
                冊子タイプ
              </span>
              のどちらかをご利用ください。
            </div>
            <div className="button-group">
              <div>
                <button type="button" className="btn btn-primary btn-sm" onClick={onPrintSealClicked}>
                  MAMORIO QRを印刷する（シール）
                </button>
                <img src={rentalGroupQrSealImage} alt="tutorial step1" />
              </div>
              <div>
                <button type="button" className="btn btn-primary btn-sm" onClick={onPrintBrochureClicked}>
                  MAMORIO QRを印刷する（冊子）
                </button>
                <img src={rentalGroupQrBookImage} alt="tutorial step1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RentalModal
