import PropTypes from 'prop-types'
import React from 'react'
import './Pagination.css'
import { useAppSelector } from '../../hooks'

const MaxPageSize = 5
const HalfOfPages = 2

/**
 * 与えられたページ番号以降に残っているのページ数を返す
 * @param pageNumber 対象のページ番号
 * @param totalPages トータルページ数
 * @return {number} - 与えられたページ番号以降に残っているのページ数
 */
const getPageCountAfter = (pageNumber: number, totalPages: number) => {
  let result = HalfOfPages

  while (pageNumber + result > totalPages && result > 0) {
    result -= 1
  }

  return result
}

/**
 * 与えられたページ番号を中間ページとしたページの配列を返す
 * @param {number} pageNumber 対象のページ番号
 * @param {number} totalPages トータルページ数
 * @return {number[]}
 */
const getPagesOfCenterPosition = (pageNumber: number, totalPages: number): number[] => (
  [
    ...[...Array(HalfOfPages).keys()]
      .reverse()
      .map((index) => (pageNumber - (index + 1))),
    pageNumber,
    ...[...Array(getPageCountAfter(pageNumber, totalPages)).keys()]
      .map((index) => (pageNumber + (index + 1))),
  ]
)

/**
 * MaxPageSize 分のページを返す
 * @param {number} pageNumber 対象のページ番号
 * @param {number} totalPages トータルページ数
 * @return {number[]}
 */
const getPages = (pageNumber: number, totalPages: number): number[] => {
  if (pageNumber > HalfOfPages) {
    return getPagesOfCenterPosition(pageNumber, totalPages)
  }

  const maxPage = totalPages < MaxPageSize ? totalPages : MaxPageSize

  return [...Array(maxPage).keys()].map((index) => (index + 1))
}

type Props = {
  onChangePage: (pageNumber: number) => void
}
/**
 * Overview 下部のページ選択 UI
 */
const Pagination = ({ onChangePage }: Props) => {
  const currentPageNumber = useAppSelector((state) => state.mamorio.currentPage)
  const totalPages = useAppSelector((state) => state.mamorio.totalPages)

  return (
    <div className="pagination-wrap" data-testid="pagination">
      <ul className="pagination">
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            disabled={currentPageNumber <= 1}
            onClick={() => onChangePage(currentPageNumber - 1)}
          >
            «
          </button>
        </li>
        {
          getPages(currentPageNumber, totalPages).map((pageNumber) => (
            <li className={`${(pageNumber === currentPageNumber) ? 'active ' : ''}page-item`} key={pageNumber}>
              <button
                type="button"
                className="page-link"
                disabled={totalPages === 1}
                onClick={() => onChangePage(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))
        }
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            disabled={currentPageNumber >= totalPages}
            onClick={() => onChangePage(currentPageNumber + 1)}
          >
            »
          </button>
        </li>
      </ul>
    </div>
  )
}

Pagination.propTypes = {
  onChangePage: PropTypes.func.isRequired,
}

export default Pagination
