import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buildUrl, createRequest } from './common/httpUtils'
import { getRootGroupId } from './common/session'
import { requestStart, requestFinished } from './UI'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'

/**
 * ルートグループを取得する
 */
export const getRootGroup = createAsyncThunk<
IGroup,
undefined,
{
  rejectValue: string
}>('group/getRootGroup', async (_, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi
  const groupId = getRootGroupId()

  if (!groupId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(createRequest(buildUrl(`/api/v1/groups/${groupId}`), 'GET'))
    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return data.group
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export const getGroupUsers = createAsyncThunk<
{
  users: IUser[]
  total_pages: number
  current_page: number
},
{
  groupId: GroupId
  page: number
},
{
  rejectValue: string
}
>('group/getGroupUsers', async (arg, thunkApi) => {
  const { groupId, page } = arg
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/groups/${groupId}/group_users?page=${page}`), 'GET'),
    )

    const data = await response.json()

    const {
      users,
      meta,
    }: {
      users: IUser[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data

    if (response.status >= 400 || !users) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      users,
      total_pages: meta.total_pages,
      current_page: meta.current_page,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * 特定のグループを取得する
 */
export const getGroup = createAsyncThunk<
IGroup,
{
  groupId: GroupId
},
{
  rejectValue: string
}>('group/getGroup', async (arg, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi
  const { groupId } = arg
  if (!groupId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(createRequest(buildUrl(`/api/v1/groups/${groupId}`), 'GET'))
    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return data.group
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループの名前を変更する
 */
export const updateGroup = createAsyncThunk<
IGroup,
{
  groupId: GroupId
  groupName: string
},
{
  rejectValue: string
}
>('group/update', async (arg, thunkApi) => {
  const { groupId, groupName } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}`),
        'PATCH',
        true,
        'application/json',
        {
          group: {
            name: groupName,
          },
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error_message || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return data.group as IGroup
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループを削除する
 */
export const deleteGroup = createAsyncThunk<
boolean,
{
  groupId: GroupId
},
{
  rejectValue: string
}>('group/delete', async (arg, thunkApi) => {
  const { groupId } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}`),
        'DELETE',
        true,
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return true
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループにユーザーを追加する (招待は不要)
 * TODO: 一部ユーザーの招待が失敗した際のハンドリング
 */
export const addUsersToGroup = createAsyncThunk<
{
  errors: IUser[],
  successes: IUser[]
},
{
  groupId: GroupId
  emails: string[]
},
{
  rejectValue: string
}
>('group/addUser', async (arg, thunkApi) => {
  const { groupId, emails } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !emails || emails.length === 0) {
    return rejectWithValue('')
  }

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_users/add`),
        'POST',
        true,
        'application/json',
        {
          users: emails.map((email) => (
            {
              email,
              admin: false,
            }
          )),
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return data
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループにユーザーを招待する
 * TODO: 一部ユーザーの招待が失敗した際のハンドリング
 */
export const inviteUserToGroup = createAsyncThunk<
{
  errors: IUser[],
  successes: IUser[]
},
{
  groupId: GroupId
  emails: string[]
},
{
  rejectValue: string
}
>('group/inviteUser', async (arg, thunkApi) => {
  const { groupId, emails } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !emails || emails.length === 0) {
    return rejectWithValue('')
  }

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_users/invite`),
        'POST',
        true,
        'application/json',
        {
          users: emails.map((email) => (
            {
              email,
              admin: false,
            })),
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return data
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループからユーザーを削除する
 */
export const removeUserFromGroup = createAsyncThunk<
boolean,
{
  groupId: GroupId
  userId: UserId
},
{
  rejectValue: string
}
>('group/removeUser', async (arg, thunkApi) => {
  const { groupId, userId } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !userId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_users/${userId}`),
        'DELETE',
        true,
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    await dispatch(getRootGroup())

    return true
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループのユーザー情報を更新する
 */
export const updateUserInGroup = createAsyncThunk<
IUser,
{
  groupId: GroupId
  user: { id: UserId }
},
{
  rejectValue: string
}
>('group/updateUser', async (arg, thunkApi) => {
  const { groupId, user } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !user) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const { id: userId } = user

    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_users/${userId}`),
        'PATCH',
        true,
        'application/json',
        { group_user: user },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue((data.error_messages && data.error_messages[0]) || response.statusText)
    }

    await dispatch(getRootGroup())

    return data
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループのユーザー情報を更新する
 */
export const updateMemberInfo = createAsyncThunk<
IUser,
{
  groupId: GroupId
  userId: UserId
  name: string
},
{
  rejectValue: string
}
>('group/updateUser', async (arg, thunkApi) => {
  const { groupId, userId, name } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !userId || !name) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/users/${userId}`),
        'PATCH',
        true,
        'application/json',
        {
          user: {
            name,
          },
          group_id: groupId,
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue((data.error_messages && data.error_messages[0]) || response.statusText)
    }

    await dispatch(getRootGroup())

    return data
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループを作成する
 */
export const createGroup = createAsyncThunk<
any,
{
  groupName: string
  emailAddresses: string[]
},
{
  rejectValue: string
}
>('group/create', async (arg, thunkApi) => {
  const { groupName, emailAddresses } = arg
  const rootGroupId = getRootGroupId()
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupName || !emailAddresses || emailAddresses.length === 0 || !rootGroupId) {
    return rejectWithValue('')
  }

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl('/api/v1/groups'),
        'POST',
        true,
        'application/json',
        {
          parent_group_id: getRootGroupId(),
          group: {
            name: groupName,
          },
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue((data.error_messages && data.error_messages[0]) || response.statusText)
    }

    await dispatch(addUsersToGroup({
      groupId: data.group.id,
      emails: emailAddresses,
    })).unwrap()

    await dispatch(getRootGroup()).unwrap()

    return data
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループのデバイスの情報を更新する
 */
export const updateDeviceInGroup = createAsyncThunk<
IDevice,
{
  newName: string
  groupId: GroupId
  deviceId: DeviceId
},
{
  rejectValue: string
}
>('group/updateGroupDevice', async (arg, thunkApi) => {
  const { newName, groupId, deviceId } = arg
  const rootGroupId = getRootGroupId()
  const { dispatch, rejectWithValue } = thunkApi

  if (!newName || !groupId || !deviceId || !rootGroupId) {
    return rejectWithValue('')
  }

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_devices/${deviceId}`),
        'PATCH',
        true,
        'application/json',
        {
          group_device: {
            name: newName,
          },
        },
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue((data.error_messages && data.error_messages[0]) || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return data as IDevice
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループのデバイスを削除する
 */
export const removeDeviceFromGroup = createAsyncThunk<
boolean,
{
  groupId: GroupId
  deviceId: DeviceId
},
{
  rejectValue: string
}
>('group/deleteGroupDevice', async (arg, thunkApi) => {
  const { groupId, deviceId } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !deviceId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_devices/${deviceId}/unregister`),
        'DELETE',
        true,
        'application/json',
      ),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    await dispatch(getRootGroup()).unwrap()

    return true
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

interface IGroupState {
  group: IGroup | null
  rootGroup: IGroup | null
  users: {
    users: IUser[]
    total_pages: number
    current_page: number
  } | null
}

interface IGetGroupUsersAction {
  users: IUser[]
  total_pages: number
  current_page: number
}

/**
 * Slice
 */
const groupSlice = createSlice({
  name: 'group',
  initialState: {
    group: null,
    rootGroup: null,
    users: null,
  } as IGroupState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRootGroup.fulfilled, (state, action: PayloadAction<IGroup>): IGroupState => ({
        ...state,
        rootGroup: action.payload,
        group: action.payload,
      }))
      .addCase(getGroup.fulfilled, (state, action: PayloadAction<IGroup>): IGroupState => ({
        ...state,
        group: action.payload,
      }))
      .addCase(getGroupUsers.fulfilled, (state, action: PayloadAction<IGetGroupUsersAction>): IGroupState => ({
        ...state,
        users: {
          users: action.payload.users,
          total_pages: action.payload.total_pages,
          current_page: action.payload.current_page,
        },
      }))
  },
})

export default groupSlice.reducer
