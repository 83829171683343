import React, { useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { showModal } from '../../../../modules/UI'
import TabMenu from '../../../navigation/TabMenu'
import { BASE_MODAL } from '../../../common/RootModal'
import CreateGroupModal from '../../modal/CreateGroupModal'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import EmptyGroup from '../EmptyGroup'
import './SettingsGroupManagement.css'
import './SettingsGroupManagementUsers.css'
import SettingTabsInfo from '../../../../constants/SettingTabsInfo'
import GroupMemberList from '../GroupMemberList'
import { toId } from '../../../../utils/commonUtils'
import { getGroup } from '../../../../modules/Group'
import { usersIcon } from '../../../../icons'
import { SETTINGS_GROUP_MANAGEMENT } from '../../../../constants/Paths'

const SettingsGroupManagementUsers = () => {
  const { group_id }: { group_id: string } = useParams()
  const group = useAppSelector((state) => (
    state.team.groups?.find((child) => group_id && toId(child.id) === toId(group_id))))
  const dispatch = useAppDispatch()

  const handleClickCreateGroup = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'グループの作成',
        onActionConfirmed: undefined,
        children: <CreateGroupModal />,
      },
    }))
  }

  useEffect(() => {
    dispatch(getGroup({
      groupId: toId(group_id),
    }))
  }, [dispatch, group_id])

  if (!group) {
    return (<EmptyGroup onClick={handleClickCreateGroup} />)
  }

  const tabsInfo = SettingTabsInfo()
  return (
    <TabMenu tabsInfo={tabsInfo}>
      <div className="title">
        <div>
          <img src={usersIcon} className="group-settings-image" alt="users" />
          {`${group.name} メンバーの管理`}
          <NavLink style={{ marginLeft: '10px', color: '#757575', fontSize: '17px' }} to={SETTINGS_GROUP_MANAGEMENT}>
            グループの管理に戻る
          </NavLink>
        </div>
      </div>
      <div className="group-manager">
        {
          group && (
            <div className="group-lists-box clearfix">
              <GroupMemberList group={group} />
            </div>
          )
        }
      </div>
    </TabMenu>
  )
}

export default SettingsGroupManagementUsers
