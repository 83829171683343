import React from 'react'
import { iconEmptyStateMamorio } from '../../../icons'
import AddMamorioInquiryInfo from './AddMamorioInquiryInfo'

const EmptyListView = function () {
  return (
    <div className="mamorio-settings">
      <div className="empty-list">
        <img src={iconEmptyStateMamorio} alt="mamorio" />
        <p className="title">
          管理中のMAMORIOがありません
        </p>
        <p className="description">
          「MAMORIOを追加」から
          <br />
          管理したいMAMORIOを追加してください。
        </p>
        <button type="button" className="btn btn-primary" disabled>
          MAMORIOを追加
        </button>
        <AddMamorioInquiryInfo />
      </div>
    </div>
  )
}

export default EmptyListView
