import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Redirect } from 'react-router-dom'
import {
  OVERVIEW,
  GROUPS,
  UNAUTHORIZED,
  SETTINGS_EDIT_MAMORIO,
} from '../../constants/Paths'
import {
  // iconImportSideMenu,
  defaultTeamIcon,
  iconSettingsSideMenu,
  iconGroupSideMenu,
} from '../../icons'
import './GroupNavi.css'
import isAdminUser from '../../helpers/isAdminUser'
import { isMobile } from '../../utils/commonUtils'
import { setGroupNaviState } from '../../modules/UI'
import { useAppDispatch, useAppSelector } from '../../hooks'

//  Check if IE 11 or below
// @ts-ignore
const isRidiculouslyOldIE = !!document.documentMode

type NaviItemProps = {
  team: ITeam,
  user: Pick<IUser, 'admin'>
}

const NaviItem = ({
  team,
  user,
}: NaviItemProps) => (
  <div className="group-nav" id="groupViewContent" data-testid="group-navi-item">
    <div className="organization">
      <NavLink to={OVERVIEW} className="no-wrap">
        <img
          src={defaultTeamIcon}
          className="organization-icon"
          alt="organization"
          data-testid="group-navi-organization-icon"
        />
      </NavLink>
      <NavLink to={OVERVIEW} className="no-wrap">
        <span data-testid="group-navi-organization-name">{team.name}</span>
      </NavLink>
      <NavLink to={SETTINGS_EDIT_MAMORIO} className="no-wrap" data-testid="group-navi-organization-settings">
        {isAdminUser(user) && (
          <div className="organization-settings">
            <img src={iconSettingsSideMenu} alt="organization-settings" />
            <span> チーム設定</span>
          </div>
        )}
      </NavLink>
    </div>
    <hr />
    <div className="group-nav-header">
      グループ
    </div>
    <ul data-testid="group-navi-child-groups">
      {
        team.groups?.map((group) => (
          <li key={group.id}>
            <NavLink to={`${GROUPS}/${group.id}`} activeClassName="selected">
              <div className="child-group-name">
                <img src={iconGroupSideMenu} alt="group" />
                {group.name}
              </div>
            </NavLink>
          </li>
        ))
      }
    </ul>
  </div>
)

NaviItem.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    })),
  }).isRequired,
  user: PropTypes.shape({
    admin: PropTypes.bool,
  }).isRequired,
}

type GroupNaviProps = {
  children: React.ReactNode
}

const GroupNavi = ({
  children,
}: GroupNaviProps) => {
  const dispatch = useAppDispatch()
  const showGroupNavi = useAppSelector((state) => state.ui.showGroupNavi)
  const team = useAppSelector((state) => state.team)
  const user = useAppSelector((state) => state.user.user)

  const groupNaviStyle = {
    flex: showGroupNavi ? '0 0 300px !important' : '0 0 0% !important',
    maxWidth: showGroupNavi ? '300px' : '0%',
    minWidth: showGroupNavi ? '300px' : '0px',
    transition: 'all 0.5s',
  }

  let contentStyle: React.CSSProperties

  if (isRidiculouslyOldIE) {
    contentStyle = {
      flex: showGroupNavi ? 'auto' : '0 0 100%',
      transition: 'all 0.5s',
    }
  } else {
    contentStyle = {
      flex: showGroupNavi ? '0 0 calc(100% - 300px)' : '0 0 100%',
      transition: 'all 0.5s',
    }
  }

  const handleClickOverview = useCallback((event: React.MouseEvent<HTMLElement>) => {
    if (!isMobile()) return

    const element = document.getElementById('groupViewContent')
    if (!element) return

    const target = event.target as Element

    if (target.id !== 'groupViewContent' && !element.contains(target)) {
      dispatch(setGroupNaviState({ showGroupNavi: false }))
    }
  }, [dispatch])

  if (!user) {
    return <Redirect to={UNAUTHORIZED} />
  }

  return (
    <div className="overview" onClick={handleClickOverview} data-testid="group-navi">
      <div className="row h-100">
        <div className="col row-no-padding" id="group-nav" style={groupNaviStyle}>
          <NaviItem team={team as ITeam} user={user} />
        </div>
        <div className="col h-100 pt-3 inset-shadow" id="content" style={contentStyle}>
          {children}
        </div>
      </div>
    </div>
  )
}

GroupNavi.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
}

export default GroupNavi
