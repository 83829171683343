import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { requestStart, requestFinished } from './UI'
import { buildUrl, createRequest } from './common/httpUtils'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'

interface INewsState {
  newsItems: INews[]
}

// state
const initialState = {
  newsItems: [],
} as INewsState

/**
 * newsを取得
 */
export const getNews = createAsyncThunk<
INews[],
undefined,
{
  rejectValue: string
}
>('news/getNews', async (_, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl('/api/v1/news'),
        'GET',
        true,
        'application/json',
      ),
    )

    const data = await response.json()

    if (response.status >= 400 || !data) {
      return rejectWithValue(data.error || response.statusText)
    }
    return data
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * Slice
 */
const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNews.fulfilled, (state, action: PayloadAction<INews[]>): INewsState => ({
        ...state,
        newsItems: action.payload,
      }))
  },
})

export default newsSlice.reducer
