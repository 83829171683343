import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import 'moment/locale/ja'
import { getUserName } from '../../helpers/CommonMamorioHelper'
import { getFromNowAsText } from '../../helpers/timeHelper'
import { MAMORIO_SHAPE } from '../../constants/Shapes'
import { iconSpotSideMenu } from '../../icons'

type Props = {
  device: IMamorio
  onClickList: (arg: any | void) => void
}

const DevicesListItem = ({
  device,
  onClickList,
}: Props) => {
  const recentLog = device.recent_log

  return (
    <Fragment key={device.id + Math.round(Math.random() * 10000).toString()}>
      <tr
        className="list-row overview-item-list-item"
        key={device.id.toString()}
        onClick={onClickList}
      >
        {/* ----- アイテム名 ----- */}
        <td className="item item-name">
          <div className="img-name-item">
            <img className="item-icon" src={iconSpotSideMenu} alt="device" />
            <strong className="item-name-text">
              {device.name}
            </strong>
          </div>
        </td>
        {/* ----- 最終検知時刻 ----- */}
        <td className="item item-last-detected-at">
          {recentLog ? getFromNowAsText(recentLog.at) : '-'}
        </td>
        {/* ----- 所持者 ----- */}
        <td className="item">
          <div className="status">
            <div className="main">
              {device.is_rent
                ? (
                  <div className="sub">
                    <div className="status-rental rental">
                      持出中
                    </div>
                    <div className="rental-owner">
                      {device.user ? getUserName(device.user) : '所有者がいません'}
                    </div>
                  </div>
                )
                : (
                  <div className="sub">
                    <span className="rental-owner">
                      { device.user ? getUserName(device.user) : '-' }
                    </span>
                  </div>
                )}
            </div>
          </div>
        </td>
      </tr>

      <tr key={`divider-${device.id}`} className="divider">
        <td colSpan={6}>
          <div className="line" />
        </td>
      </tr>
    </Fragment>
  )
}

DevicesListItem.propTypes = {
  device: MAMORIO_SHAPE.isRequired,
  onClickList: PropTypes.func.isRequired,
}

export default DevicesListItem
