import React from 'react'
import PropTypes from 'prop-types'
import './FormThumbNail.css'

/**
 *
 * @param defaultIconUrl デフォルトアイコンの URL
 * @param originalImageUrl 元画像の URL
 * @param imageFile File | Blob | MediaSource
 * @constructor
 */
const FormThumbNail = ({
  imageUrl,
}: { imageUrl: string }) => (
  <div
    className="form-thumbnail"
    style={{ backgroundImage: `url(${imageUrl})` }}
    data-testid="form-thumbnail"
  />
)

FormThumbNail.propTypes = {
  imageUrl: PropTypes.string.isRequired,
}

export default FormThumbNail
