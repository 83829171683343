import React, { useEffect } from 'react'
import Pagination from '../../common/Pagination'
import {
  defaultItemIcon,
} from '../../../icons'
import { getFromNowAsText, getFullDate } from '../../../helpers/timeHelper'
import {
  getUserName,
  getLastStatus,
  getBatteryImageSrc,
  getLostFoundStatus,
  getMamorioImageSrc,
} from '../../../helpers/CommonMamorioHelper'
import {
  Filter, getTeamMamorios, updateGroupMamorio,
} from '../../../modules/Mamorio'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getErrorString } from '../../../utils/errorUtils'
import { closeModal, showModal } from '../../../modules/UI'
import { MAMORIO_DETAIL_MODAL, CONFIRM_MODAL, ERROR_MODAL } from '../../common/RootModal'
import EmptyListView from './EmptyMamorioList'
import { showLostAndBattery, showMemo } from '../../../modules/common/session'
import HeaderType from '../../../modules/common/headerType'
import getSortIcon from '../../../modules/common/sortUtil'
import { returnRental } from '../../../modules/Rental'
import MamoriosMapDetailView from '../../overview/MamoriosMapDetailView'

type Props = {
  filter: Filter
  onChangeSort: (args: any) => any
  onClickPagination: (args: any) => any
  showMap?: boolean
}

const MamorioList = ({
  filter,
  onChangeSort,
  onClickPagination,
  showMap,
}: Props) => {
  const dispatch = useAppDispatch()
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)

  const onModalError = (message: string) => {
    if (message === null || message === undefined) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }

  const onClickForceReturnRental = async (targetMamorio: IMamorio, detailViewCallback: VoidFunction) => {
    if (!targetMamorio.group_id) return
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: '強制的に返却すると、持ち出しが解除され<br />所有者がオーナーに戻ります。よろしいですか？',
        confirmButtonText: '強制的に返却する',
        onActionConfirmed: async () => {
          try {
            await dispatch(returnRental({
              groupId: targetMamorio.group_id,
              mamorioId: targetMamorio.id,
              filter,
            })).unwrap()
            detailViewCallback()
          } catch (e: unknown) {
            onModalError(getErrorString(e))
          } finally {
            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          }
        },
      },
    }))
  }

  const onClickResetBattery = async (targetMamorio: IMamorio, detailViewCallback: VoidFunction) => {
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: '電池交換日を本日に設定します。よろしいですか？',
        confirmButtonText: 'はい',
        onActionConfirmed: async () => {
          try {
            await dispatch(updateGroupMamorio({
              mamorio: targetMamorio,
              params: { battery_life_resetted_at: getFullDate(new Date()) },
            })).unwrap()
            detailViewCallback()
            const fetchTeamMamorios = async (filterArg: Filter) => {
              try {
                await dispatch(getTeamMamorios({
                  filter: filterArg,
                })).unwrap()
              } catch (error: unknown) {
                // TODO: 例外処理
                console.error(getErrorString(error))
              }
            }
            fetchTeamMamorios(filter)
          } catch (e: unknown) {
            onModalError(getErrorString(e))
          } finally {
            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          }
        },
      },
    }))
  }

  const onClickList = (mamorio: IMamorio) => {
    dispatch(showModal({
      modalType: MAMORIO_DETAIL_MODAL,
      modalProps: {
        mamorio,
        onRentalForceReturnClick: (callback: VoidFunction) => onClickForceReturnRental(mamorio, callback),
        onClickResetBattery: (callback: VoidFunction) => onClickResetBattery(mamorio, callback),
      },
    }))
  }

  useEffect(() => {
    const fetchTeamMamorios = async (filterArg: Filter) => {
      try {
        await dispatch(getTeamMamorios({
          filter: filterArg,
        })).unwrap()
      } catch (error: unknown) {
        // TODO: 例外処理
        console.error(getErrorString(error))
      }
    }
    fetchTeamMamorios(filter)
  }, [dispatch, filter])

  if (!mamorios || mamorios.length === 0) {
    return (<EmptyListView />)
  }

  const icon = getSortIcon(filter.sort, filter.order)
  const cursor = 'pointer'

  return (
    <>
      <div className="mamorio-settings">
        {
          showMap && (
            <div className="mamorios-map-list-top">
              <MamoriosMapDetailView
                mamorios={mamorios!}
                nameFilter={null}
                zoom={11}
              />
            </div>
          )
        }
        <table className="table">
          <thead className="header" data-testid="mamorio-item-list-header">
            <tr>
              <th>
                <div
                  className="title"
                  style={{ cursor }}
                  onClick={() => onChangeSort(HeaderType.Name)}
                >
                  MAMORIOの名前
                  <img alt="sort" src={icon.name} />
                </div>
              </th>
              {showMemo() && (
                <th>
                  <div
                    className="title"
                    style={{ cursor, minWidth: '40px' }}
                    onClick={() => onChangeSort(HeaderType.Memo)}
                  >
                    メモ
                    <img alt="sort" src={icon.memo} />
                  </div>
                </th>
              )}
              <th>
                <div
                  className="title"
                  style={{ cursor }}
                  onClick={() => onChangeSort(HeaderType.Time)}
                >
                  最終検知時刻
                  <img alt="sort" src={icon.time} />
                </div>
              </th>
              <th>
                <div className="title">
                  最後に検知した端末
                </div>
              </th>
              <th>
                <div
                  className="title"
                  style={{ cursor, minWidth: '75px' }}
                  onClick={() => onChangeSort(HeaderType.BatteryLifeRessetedAt)}
                >
                  バッテリー
                  <img alt="sort" src={icon.batteryLifeResettedAt} />
                </div>
              </th>
              {showLostAndBattery() && (
                <th>
                  <div
                    className="title"
                    style={{ cursor, minWidth: '40px' }}
                    onClick={() => onChangeSort(HeaderType.Status)}
                  >
                    状態
                    <img alt="sort" src={icon.status} />
                  </div>
                </th>
              )}
              <th>
                <div className="title">
                  所持者
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="list" data-testid="mamorio-item-list-body">
            {
              mamorios.map((mamorio: IMamorio) => (
                <tr
                  key={mamorio.id}
                  className="mamorio-item-list-item"
                  onClick={() => onClickList(mamorio)}
                >
                  <td className="item mamorio-item">
                    <div style={{ display: 'flex' }}>
                      <img width={40} className="image" src={getMamorioImageSrc(mamorio, defaultItemIcon)} alt="member" onError={(e) => { e.currentTarget.src = defaultItemIcon }} />
                      <div>
                        <span className="name">
                          {mamorio.name}
                        </span>
                        <br />
                        <span className="item-majorminor-text" style={{ fontSize: '12px', color: '#757575' }}>
                          {`${mamorio.major}-${mamorio.minor}`}
                        </span>
                      </div>
                    </div>
                  </td>
                  {showMemo() && (
                    <td className="item mamorio-item">
                      <span className="name">
                        {mamorio.memo ? mamorio.memo : '-'}
                      </span>
                    </td>
                  )}
                  <td className="item item-last-detected-at">
                    {mamorio?.recent_log ? getFromNowAsText(mamorio.recent_log.at) : '-'}
                  </td>
                  <td className="item item-detected-by">
                    <img className="item-icon" src={getLastStatus(mamorio).imgSrc} style={{ borderRadius: 0 }} alt="status" />
                    {getLastStatus(mamorio).text}
                  </td>

                  {/* ----- バッテリー残量 ----- */}
                  <td className="item item-detected-by">
                    <div className="img-name-item">
                      <img className="item-icon" src={getBatteryImageSrc(mamorio)} style={{ borderRadius: 0 }} alt="status" />
                    </div>
                  </td>

                  {/* ----- 紛失ステータス ----- */}
                  {showLostAndBattery() && (
                    <td className="item item-detected-by">
                      <div className="img-name-item">
                        {getLostFoundStatus(mamorio)}
                      </div>
                    </td>
                  )}
                  <td className="item">
                    {mamorio.is_rent
                      ? (
                        <div className="sub">
                          <div className="status-rental rental">
                            持出中
                          </div>
                          <div className="rental-owner">
                            {mamorio.user ? getUserName(mamorio.user) : '所有者がいません'}
                          </div>
                        </div>
                      )
                      : (
                        <div className="sub">
                          <span className="rental-owner">
                            { mamorio.user ? getUserName(mamorio.user) : '-' }
                          </span>
                        </div>
                      )}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <Pagination onChangePage={onClickPagination} />
      </div>
    </>
  )
}

MamorioList.defaultProps = {
  showMap: false,
}

export default MamorioList
