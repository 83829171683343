import {
  sortAscIcon,
  sortDescIcon,
  sortIcon,
} from '../../icons'

import { SortOrderAsc } from '../Mamorio'

const SortKey = {
  Name: 'name',
  Memo: 'memo',
  Time: undefined,
  BatteryLifeRessetedAt: 'battery_life_resetted_at',
  Status: 'status',
} as TSortKey

const getSortIcon = (sort: Nullable<string>, order: Nullable<string>): SortIcons => {
  const defaultIcons: SortIcons = {
    name: sortIcon,
    memo: sortIcon,
    time: sortIcon,
    batteryLifeResettedAt: sortIcon,
    status: sortIcon,
  }

  if (
    sort === SortKey.Name
    || sort === SortKey.Memo
    || sort === SortKey.Time
    || sort === SortKey.BatteryLifeRessetedAt
    || sort === SortKey.Status
  ) {
    const selectedIcon = order === SortOrderAsc ? sortAscIcon : sortDescIcon
    return { ...defaultIcons, [sort!]: selectedIcon }
  }

  return defaultIcons
}

export default getSortIcon
