import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { getAccessToken, removeSession } from '../../modules/common/session'
import { LOGIN } from '../../constants/Paths'
import { useAppDispatch } from '../../hooks'
import { getUser } from '../../modules/User'
import { getTeam, getTeamGroups } from '../../modules/Team'
import CommonLoadingView from '../common/CommonLoadingView'
import { getNews } from '../../modules/News'

type Props = {
  children: JSX.Element
}

const RequireAuth = ({ children }: Props) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    Promise.all(
      [
        dispatch(getUser()).unwrap(),
        dispatch(getTeam()).unwrap(),
        dispatch(getTeamGroups(true)).unwrap(),
        dispatch(getNews()).unwrap(),
      ],
    )
      .catch((error: any) => {
        // TODO: エラーハンドリング. モーダルを出す？
        console.error(error)
      })
      .finally(() => setIsLoading(false))
  }, [dispatch])

  if (!getAccessToken()) {
    removeSession()
    return <Redirect to={LOGIN} />
  }

  if (isLoading) {
    return (<CommonLoadingView />)
  }

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
}

export default RequireAuth
