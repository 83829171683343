import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DebounceInput } from 'react-debounce-input'
import Toggle from 'react-toggle'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import {
  iconSearchMainView,
} from '../../icons'
import './SearchBar.css'
import { Filter } from '../../modules/Mamorio'
import { showMemo } from '../../modules/common/session'

type Props = {
  rentalMode: boolean
  loadList: (useDeviceApi: boolean) => void
  updateGroupFilter: (newFilter: Pick<Filter, 'isRent' | 'name' | 'memo'>) => void
  showDeviceSwitch?: boolean
}

const BOUNCE_TIME = 500

/**
 * MAMORIO または端末の表示条件を入力・変更する UI
 * @param rentalMode
 * @param loadList
 * @param updateGroupFilter
 * @param showDeviceSwitch
 */
const SearchBar = ({
  rentalMode,
  loadList,
  updateGroupFilter,
  showDeviceSwitch = true,
}: Props) => {
  const [isEnableDeviceListMode, setIsEnableDeviceListMode] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isRent, setIsRent] = useState<boolean | null>(null)

  const loadDeviceList = useCallback(() => {
    loadList(isEnableDeviceListMode)
  }, [loadList, isEnableDeviceListMode])

  const updateFilterIsRent = (value: boolean | null) => {
    updateGroupFilter({ isRent: value })
    setIsRent(value)
  }

  useEffect(() => {
    loadDeviceList()
  }, [loadDeviceList, isEnableDeviceListMode])

  return (
    <div className="search-bar" data-testid="search-bar">
      <div className="filter-menu">
        <div className="filter-title top">
          <img src={iconSearchMainView} className="search-icon" alt="search" />
          <div className="label">
            物品を検索する
          </div>
        </div>
        <div className="filter-input">
          <div className="filter-title">
            MAMORIOの名前
          </div>
          <DebounceInput
            debounceTimeout={BOUNCE_TIME}
            disabled={isEnableDeviceListMode}
            onChange={
              (event: React.ChangeEvent<HTMLInputElement>) => updateGroupFilter({
                name: event.target.value,
              })
            }
            type="text"
            className="form-control"
            placeholder="MAMORIOの名前"
            data-testid="search-bar-mamorio-name-input"
          />
        </div>
        {showMemo() && (
          <div className="filter-input">
            <div className="filter-title" style={{ width: '30px' }}>
              メモ
            </div>
            <DebounceInput
              debounceTimeout={BOUNCE_TIME}
              disabled={isEnableDeviceListMode}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => updateGroupFilter({
                  memo: event.target.value,
                })
              }
              type="text"
              className="form-control"
              placeholder="メモの内容"
              data-testid="search-bar-mamorio-name-input"
            />
          </div>
        )}
        {rentalMode && (
          <div className="filter-status">
            <div className="filter-title">
              持出状況
            </div>
            <Dropdown
              isOpen={isOpen}
              toggle={() => setIsOpen(!isOpen)}
              data-testid="search-bar-lend-status-dropdown"
            >
              <DropdownToggle
                caret
                color="white"
                disabled={isEnableDeviceListMode}
                data-testid="search-bar-lend-status-dropdown-toggle"
              >
                {isRent === null && 'すべて'}
                {isRent === true && '持出中'}
                {isRent === false && '返却済'}
              </DropdownToggle>
              <DropdownMenu data-testid="search-bar-lend-status-dropdown-menu">
                <DropdownItem onClick={() => updateFilterIsRent(null)}>
                  すべて
                </DropdownItem>
                <DropdownItem onClick={() => updateFilterIsRent(true)}>
                  持出中
                </DropdownItem>
                <DropdownItem onClick={() => updateFilterIsRent(false)}>
                  返却済
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        )}
        {
          showDeviceSwitch && (
            <div className="filter-toggle">
              <div className="filter-title">
                端末の一覧を見る
              </div>
              <Toggle
                defaultChecked={isEnableDeviceListMode}
                icons={{
                  checked: <FontAwesomeIcon icon={faMobileAlt} />,
                  unchecked: null,
                }}
                onChange={
                  (e: React.ChangeEvent<HTMLInputElement>) => setIsEnableDeviceListMode(e.target.checked)
                }
                data-testid="search-bar-display-mode-toggle"
              />
            </div>
          )
        }
      </div>
    </div>
  )
}

SearchBar.defaultProps = {
  rentalMode: false,
  showDeviceSwitch: true,
}

SearchBar.propTypes = {
  rentalMode: PropTypes.bool,
  loadList: PropTypes.func.isRequired,
  updateGroupFilter: PropTypes.func.isRequired,
  showDeviceSwitch: PropTypes.bool,
}

export default SearchBar
