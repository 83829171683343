import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { usersIcon } from '../../../icons'
import { showModal } from '../../../modules/UI'
import EditGroupModal from '../modal/EditGroupModal'
import { useAppDispatch } from '../../../hooks'
import { BASE_MODAL } from '../../common/RootModal'
import { getTeamGroups } from '../../../modules/Team'
import { getErrorString } from '../../../utils/errorUtils'

type Props = {
  group: Pick<IGroup, 'id' | 'name' | 'users'>
}

const GroupDetails = ({
  group,
}: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const fetchTeamGroups = useCallback(async () => {
    try {
      await dispatch(getTeamGroups(false)).unwrap()
    } catch (error: unknown) {
      // TODO: 例外処理
      console.error(getErrorString(error))
    }
  }, [dispatch])

  const handleClickSettingButton = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'グループの編集',
        children: <EditGroupModal group={group} inputValue={group.name} didUpdageGroupFunction={fetchTeamGroups} />,
      },
    }))
  }

  const handleClickMembersButton = () => {
    history.push(`/settings/group-management/${group.id}`)
  }

  return (
    <div>
      <div className="group-settings-column">
        <div className="group-settings-cell">
          <div className="group-settings">
            <img src={usersIcon} className="group-settings-image" alt="users" />
            <div className="group-name">
              {group.name}
            </div>
            <div className="group-update desktop">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleClickMembersButton}
              >
                メンバーを編集する
              </button>
            </div>
            <div className="group-update desktop">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleClickSettingButton}
              >
                グループ設定
              </button>
            </div>
            <div className="group-update mobile">
              <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={handleClickMembersButton}
              >
                メンバー編集
              </button>
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleClickSettingButton}
              >
                グループ設定
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupDetails
