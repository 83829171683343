import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { buildUrl, createRequest } from './common/httpUtils'
import { requestStart, requestFinished } from './UI'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'
import { HOSTNAME } from '../constants/Constants'

/**
 * チーム情報を取得する
 */
export const getTeam = createAsyncThunk<
ITeam,
undefined,
{
  rejectValue: string
}>('team/getTeam', async (_, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi
  const teamDomain = HOSTNAME?.split('.')[0]

  if (!teamDomain) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(createRequest(buildUrl(`/api/v1/teams?team_domain=${teamDomain}`), 'GET'))
    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    const { team } = data
    return team
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export const getTeamGroups = createAsyncThunk<
{
  groups: IGroup[],
},
boolean,
{
  rejectValue: string
}>('team/getTeamGroups', async (no_users, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi
  const teamDomain = HOSTNAME?.split('.')[0]

  if (!teamDomain) return rejectWithValue('')

  dispatch(requestStart())
  try {
    let url = `/api/v1/team_groups?team_domain=${teamDomain}`
    if (no_users) {
      url += '&no_users=true'
    }
    const response = await fetch(createRequest(buildUrl(url), 'GET'))
    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    const {
      groups,
      meta,
    }: {
      groups: IGroup[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data
    // console.error('groups', groups)
    // console.error('meta',meta)

    return {
      groups,
      meata: {
        total_pages: meta.total_pages,
        current_page: meta.current_page,
      },
    }
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

interface ITeamState {
  id: number | null
  name: string | null
  rental_mode: boolean | false
  biz_plus: boolean | false
  groups?: IGroup[] | null
}

interface IGetGroupsAction {
  groups: IGroup[]
}

/**
 * Slice
 */
const teamSlice = createSlice({
  name: 'team',
  initialState: {
    id: null,
    name: null,
    groups: null,
    rental_mode: false,
    biz_plus: false,
  } as ITeamState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTeam.fulfilled, (state, action: PayloadAction<ITeam>): ITeamState => ({
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        rental_mode: action.payload.rental_mode,
        biz_plus: action.payload.biz_plus,
      }))
      .addCase(getTeamGroups.fulfilled, (state, action: PayloadAction<IGetGroupsAction>): ITeamState => ({
        ...state,
        groups: action.payload.groups,
      }))
  },
})

export default teamSlice.reducer
