import React, { useState, useEffect } from 'react'
import {
  Filter, SortOrderAsc, SortOrder, SortOrderDesc, getTeamMamoriosWithoutUpdatingState,
} from '../../../../modules/Mamorio'
import TabMenu from '../../../navigation/TabMenu'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import MamorioList from '../MamorioList'
import './SettingsEditMamorio.css'
import { HOSTNAME } from '../../../../constants/Constants'
import SearchBar from '../../../overview/SearchBar'
import SettingTabsInfo from '../../../../constants/SettingTabsInfo'
import ReloadButton from '../../../overview/ReloadButton'
import ViewAllMamoriosInMapButton from '../../../overview/ViewAllMamoriosInMapButton'
import { showModal } from '../../../../modules/UI'
import { BASE_MODAL } from '../../../common/RootModal'
import GetRentalsModal from '../../modal/GetRentalsModal'
import MamoriosMapModal from '../../../overview/MamoriosMapModal'
import { getMamoriosWithLocation } from '../../../../helpers/CommonMamorioHelper'
import MamorioSettingList from '../MamorioSettingList'
import HeaderType from '../../../../modules/common/headerType'
import { showMemo, showMapOnListTop } from '../../../../modules/common/session'
import CSVModal from '../../modal/CSVModal'
import { getErrorString } from '../../../../utils/errorUtils'
import ChannelService from '../../../../modules/ChannelService'

type SortAndOrder = {
  sort?: string
  order?: SortOrder
}

const SortKey = {
  Name: 'name',
  Memo: 'memo',
  Time: undefined,
  BatteryLifeRessetedAt: 'battery_life_resetted_at',
  Status: 'status',
} as TSortKey

type Props = {
  showTabs?: Boolean
  showEditButton?: Boolean
}

const SettingsEditMamorio = ({ showTabs, showEditButton }: Props) => {
  const dispatch = useAppDispatch()
  const [filter, setFilter] = useState({
    teamDomain: HOSTNAME?.split('.')[0],
    sort: 'name',
    order: SortOrderAsc as SortOrder,
    page: 1,
  })
  const team = useAppSelector((state) => state.team)
  const rentalGroup = team.groups?.find((group) => group.mode === 'rental')
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)
  const user = useAppSelector((state) => state.user)

  const showGetRentalsModal = (group: IGroup) => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: '持ち出し履歴の出力',
        children: <GetRentalsModal group={group} />,
      },
    }))
  }

  const reloadList = async () => {
    setFilter({ ...filter })
  }

  const showCSVModal = () => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'CSVによる出力と更新',
        children: <CSVModal didUpdteMamorios={reloadList} />,
      },
    }))
  }

  const showMamoriosMapModal = () => {
    if (!mamorios) return

    const showMap = (_mamorios: IMamorio[]) => {
      dispatch(showModal({
        modalType: MamoriosMapModal,
        modalProps: {
          mamorios: getMamoriosWithLocation(_mamorios),
        },
      }))
    }

    dispatch(getTeamMamoriosWithoutUpdatingState({
      filter: {
        teamDomain: HOSTNAME?.split('.')[0],
        sort: 'name',
        order: SortOrderAsc as SortOrder,
        page: 1,
        maxMamorios: true,
      },
    })).unwrap().then((result) => {
      showMap(result.mamorios)
    }).catch((error) => {
      console.error(getErrorString(error))
      showMap(mamorios)
    })
  }

  const updateGroupFilter = async (newFilter: Filter) => {
    setFilter({ ...filter, ...newFilter, page: 1 })
  }

  const updatePageNumber = (pageNumber: number) => {
    setFilter({ ...filter, page: pageNumber })
  }

  const updateSort = (type: HeaderType) => {
    let params = {
      sort: '',
      order: SortOrderAsc,
    } as SortAndOrder
    const { sort, order } = filter
    switch (type) {
      case HeaderType.Name:
        if (sort === SortKey.Time) {
          params = {
            sort: SortKey.Name,
            order: SortOrderAsc,
          } as SortAndOrder
          break
        }

        params = {
          sort: SortKey.Name,
          order: order === SortOrderDesc ? SortOrderAsc : SortOrderDesc,
        }
        break
      case HeaderType.Memo:
        if (sort === SortKey.Time) {
          params = {
            sort: SortKey.Memo,
            order: SortOrderAsc,
          } as SortAndOrder
          break
        }

        params = {
          sort: SortKey.Memo,
          order: order === SortOrderDesc ? SortOrderAsc : SortOrderDesc,
        }
        break
      case HeaderType.Time:
        if (sort === SortKey.Name) {
          params = {
            sort: SortKey.Time,
            order: SortOrderAsc,
          } as SortAndOrder
          break
        }
        params = {
          sort: SortKey.Time,
          order: order === SortOrderDesc ? SortOrderAsc : SortOrderDesc,
        }
        break
      case HeaderType.BatteryLifeRessetedAt:
        if (sort !== SortKey.BatteryLifeRessetedAt) {
          params = {
            sort: SortKey.BatteryLifeRessetedAt,
            order: SortOrderAsc,
          } as SortAndOrder
          break
        }
        params = {
          sort: SortKey.BatteryLifeRessetedAt,
          order: order === SortOrderDesc ? SortOrderAsc : SortOrderDesc,
        }
        break
      case HeaderType.Status:
        if (sort !== SortKey.Status) {
          params = {
            sort: SortKey.Status,
            order: SortOrderAsc,
          } as SortAndOrder
          break
        }
        params = {
          sort: SortKey.Status,
          order: order === SortOrderDesc ? SortOrderAsc : SortOrderDesc,
        }
        break
      default:
        // eslint-disable-next-line no-console
        console.warn(`${type} does not exist type`)
        break
    }

    setFilter({ ...filter, ...params, page: 1 })
  }

  const tabsInfo = showTabs ? SettingTabsInfo() : []
  const listTitle = showTabs ? 'MAMORIOの設定' : '閲覧可能なMAMORIO一覧'

  useEffect(() => {
    if (typeof window.ChannelIO === 'undefined') {
      ChannelService.boot({
        pluginKey: '6182dd84-c259-45c6-9a5c-f2d1f9323ebc',
        memberId: user.user?.id,
      })
    }
  }, [user.user?.id])

  return (
    <TabMenu tabsInfo={tabsInfo}>
      <div className="title">
        <div>
          {listTitle}
        </div>
        <div className="page-title">
          <div>
            <ReloadButton onClick={reloadList} />
          </div>
          <div>
            <ViewAllMamoriosInMapButton onClick={showMamoriosMapModal} />
          </div>
          {rentalGroup && (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => showGetRentalsModal(rentalGroup)}
              >
                持ち出し履歴の出力
              </button>
            </div>
          )}
          {showMemo() && (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => showCSVModal()}
              >
                CSV
              </button>
            </div>
          )}
        </div>
      </div>
      <SearchBar
        rentalMode={team.groups?.find((group) => group.mode === 'rental') !== undefined}
        loadList={() => {}}
        updateGroupFilter={updateGroupFilter}
        showDeviceSwitch={false}
      />
      {(showEditButton!) ? (
        <MamorioSettingList
          filter={filter}
          onChangeSort={updateSort}
          onClickPagination={updatePageNumber}
        />
      ) : (
        <MamorioList
          filter={filter}
          onChangeSort={updateSort}
          onClickPagination={updatePageNumber}
          showMap={showMapOnListTop()}
        />
      )}
    </TabMenu>
  )
}

SettingsEditMamorio.renderInfo = () => (
  <p className="message">
    MAMORIOを追加したい際は
    <a target="blank" href="https://mamorio.typeform.com/to/dwak6v">
      MAMORIOまでお問い合わせ
    </a>
    ください。
  </p>
)

SettingsEditMamorio.defaultProps = {
  showTabs: true,
  showEditButton: true,
}

export default SettingsEditMamorio
