import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'
import React, { useEffect, useState } from 'react'
import { getGroupMamorioQrCodes } from '../../../modules/Rental'
import { isSafari, toId } from '../../../utils/commonUtils'
import { errorIcon } from '../../../icons'
import { useAppDispatch } from '../../../hooks'
import './PrintLabelModal.css'
import { generateRentalDigitCSV } from '../../../helpers/CommonMamorioHelper'

type PropType = {
  modalProps: {
    group: {
      id: string,
    }
  }
}

const PrintButton = () => (
  <button type="button" className="btn btn-primary btn-sm">
    印刷する
  </button>
)

const PrintLabelModal = ({
  modalProps,
}: PropType) => {
  const dispatch = useAppDispatch()

  const [mamorioQrCodes, setMamorioQrCodes] = useState<IQrCode[]>([])

  let printContentRef: React.ReactInstance | null = null

  useEffect(() => {
    const { group } = modalProps

    dispatch(getGroupMamorioQrCodes({
      groupId: toId<GroupId>(group.id),
    }))
      .unwrap()
      .then((qrCodes) => setMamorioQrCodes(qrCodes))
      .catch((e) => {
        throw new Error(e.toString())
      })
  }, [dispatch, modalProps])

  return (
    <div className="print-seal">
      <div className="button-section">
        <ReactToPrint
          trigger={PrintButton}
          content={() => printContentRef}
          bodyClass="print-seal"
        />
        <button type="button" className="btn btn-primary btn-sm" onClick={() => generateRentalDigitCSV(mamorioQrCodes)}>
          CSVをダウンロード
        </button>
      </div>
      <div
        className="print-seal-content"
        ref={(el) => {
          printContentRef = el
        }}
      >
        {
          PrintLabelModal.createPrintContent(mamorioQrCodes)
        }
      </div>
    </div>
  )
}

PrintLabelModal.createColumn = (qrCode: IQrCode) => (
  <div className="print-col" key={`${qrCode.id}-${Date.now()}`}>
    <div className="print-col-title">
      {qrCode.resource_name}
    </div>
    <div className="print-col-inside">
      <div>
        <img
          src={qrCode ? qrCode.image_url : errorIcon}
          className="print-col-qr"
          alt="qr-code"
        />
      </div>
      <div className="print-col-code">
        <div>
          {qrCode.digit_code.substr(0, 4)}
        </div>
        <div>
          {qrCode.digit_code.substr(4, 4)}
        </div>
      </div>
    </div>
  </div>
)

PrintLabelModal.createPrintContent = (mamorioQrCodes: IQrCode[]) => {
  const columnsPerRow = 5
  const pageBreakAt = isSafari ? 6 : 6
  const rows = []

  let index = 0

  for (let i = 1; i <= mamorioQrCodes.length; i += 1) {
    const cols = []

    for (let c = index; c < Math.min(columnsPerRow * i, mamorioQrCodes.length); c += 1) {
      const qrCode = mamorioQrCodes[c]

      if (qrCode) {
        cols.push(PrintLabelModal.createColumn(qrCode))
        index += 1
      }
    }

    rows.push(
      <div className="print-row" key={`print-row-${Date.now()}-${mamorioQrCodes[i]?.id}`}>
        {cols}
      </div>,
    )

    if (i % pageBreakAt === 0 && index < mamorioQrCodes.length) {
      rows.push(
        <footer key={`footer-${Date.now()}`} />,
      )
    }
  }

  return rows
}

PrintLabelModal.defaultProps = {
  modalProps: {},
}

PrintLabelModal.propTypes = {
  modalProps: PropTypes.shape({
    group: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
}

export default PrintLabelModal
