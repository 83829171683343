import { getAccessToken } from './session'

export const buildUrl = (path: string) => `${process.env.REACT_APP_BASE_PATH}${path}`

export const createRequest = (
  url: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  needsAuthHeader: boolean = true,
  contentType: string | null = null,
  data: any = null,
) => {
  const headers = new Headers()

  if (needsAuthHeader) {
    const accessToken = getAccessToken()
    headers.set('Authorization', `Bearer ${accessToken}`)
  }

  if (contentType && contentType.length > 0) {
    headers.set('Content-Type', contentType)
  }

  let body: any = null
  if (data !== null) {
    body = data instanceof FormData ? data : JSON.stringify(data)
  }

  return new Request(url, {
    method,
    headers,
    body,
  })
}

export const normalizeQueryParams = (params: { [k:string]: any }): { [k:string]: any } => {
  const newParams: { [k:string]: any } = {}

  Object.keys(params).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key) && params[key] !== undefined && params[key] !== null && params[key].toString().length !== 0) {
      const value = params[key]

      if (value instanceof Blob || value instanceof File || Array.isArray(value)) {
        newParams[key] = value
      } else {
        newParams[key] = value === null || value === undefined ? '' : value.toString()
      }
    }
  })

  return newParams
}
