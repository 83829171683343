import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import { isMobile } from '../utils/commonUtils'
import { deleteLastElement } from '../utils/arrayUtil'

type ModalProps = {
  closeModalFunction: (arg: unknown | void) => void
  modalProps: any
}

interface IUIState {
  showGroupNavi?: boolean
  modals?: {
    key: string
    modalProps: any
    modalType: React.ComponentType<ModalProps>
  }[]
  loading: boolean
}

interface IModalProps {
  children?: JSX.Element
  message?: string
  modalProps?: any
  group?: any
  user?: any
  title?: string
  mamorio?: IMamorio
  device?: any
  mamorios?: any[]
  confirmButtonText?: string
  onActionConfirmed?: Function
  onRentalForceReturnClick?: Function
  onClickResetBattery?: Function
}

// state
const initialState = {
  showGroupNavi: !isMobile(),
  modals: [],
  loading: false,
} as IUIState

interface IGroupNaviAction {
  showGroupNavi: boolean
}

interface IModalAction {
  modalType: any
  modalProps?: IModalProps
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleGroupNavi: (state) => ({
      ...state,
      showGroupNavi: !state.showGroupNavi,
    }),
    setGroupNaviState: (state, action: PayloadAction<IGroupNaviAction>) => ({
      ...state,
      showGroupNavi: action.payload.showGroupNavi,
    }),
    showModal: (state, action: PayloadAction<IModalAction>) => {
      if (!state.modals) return state

      const newModal = {
        key: `${action.payload.modalType}-${Object.keys(state.modals).length}`,
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      }

      if (isMobile()) {
        return {
          ...state,
          modals: [...state.modals, newModal],
          showGroupNavi: false,
        }
      }
      return {
        ...state,
        modals: [...state.modals, newModal],
      }
    },
    closeModal: (state, action: PayloadAction<IModalAction>) => {
      if (!state.modals) return state

      const newModals = deleteLastElement(state.modals, (m) => m.modalType === action.payload.modalType)

      return {
        ...state,
        modals: newModals,
      }
    },
    requestStart: (state) => {
      if (state.loading) return state

      return {
        ...state,
        loading: true,
      }
    },
    requestFinished: (state) => {
      if (!state.loading) return state

      return {
        ...state,
        loading: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addDefaultCase((state) => state)
  },
})

export const {
  toggleGroupNavi,
  setGroupNaviState,
  showModal,
  closeModal,
  requestStart,
  requestFinished,
} = uiSlice.actions

export default uiSlice.reducer
