import React from 'react'
import PropTypes from 'prop-types'
import { showModal } from '../../modules/UI'
import { DEVICE_DETAIL_MODAL } from '../common/RootModal'
import { isAnyElementInArray } from '../../utils/arrayUtil'
import DevicesListItem from './DevicesListItem'
import './OverviewItemList.css'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Filter } from '../../modules/Mamorio'

type Props = {
  filter?: Filter
}

const DevicesList = ({
  filter,
}: Props) => {
  const dispatch = useAppDispatch()
  const devices = useAppSelector((state) => state.mamorio.mamorios)

  const onClickList = (device: IMamorio) => {
    dispatch(showModal({
      modalType: DEVICE_DETAIL_MODAL,
      modalProps: {
        device,
      },
    }))
  }

  const emptyItem = () => (
    <tr>
      <td colSpan={6} className="list-row">
        {
          filter && Object.values(filter)
            .some(Boolean)
            ? '検索条件に該当するMAMORIOがありません。'
            : 'このグループにはまだMAMORIOがありません。'
        }
      </td>
    </tr>
  )

  const cursor = 'pointer'

  return (
    <div className="overview-list" data-testid="overview-item-list">
      <table className="table">
        <thead className="header" data-testid="overview-item-list-header">
        <tr>
          <th>
            <div
              className="title"
              style={{ cursor }}
            >
              端末の名前
            </div>
          </th>
          <th>
            <div
              className="title"
              style={{ cursor }}
            >
              最終検知時刻
            </div>
          </th>
          <th>
            <div className="title">
              所持者
            </div>
          </th>
        </tr>
        </thead>
        <tbody className="list" data-testid="overview-item-list-body">
        {
          !devices || !isAnyElementInArray(devices)
            ? emptyItem()
            : devices.map((device: IMamorio) => DevicesListItem({
              device,
              onClickList: () => onClickList(device),
            }))
        }
        </tbody>
      </table>
    </div>
  )
}

DevicesList.defaultProps = {
  filter: undefined,
}

DevicesList.propTypes = {
  // @ts-ignore
  filter: PropTypes.shape(),
}

export default DevicesList
