import React from 'react'
import PropTypes from 'prop-types'

type Props = {
  onClick: (event: React.MouseEvent) => Promise<void>
}

const ReloadButton = ({
  onClick,
}: Props) => (
    <button
      type="button"
      className="btn btn-primary"
      onClick={onClick}
      data-testid="reload-button"
    >
      更新
    </button>
)

ReloadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ReloadButton
