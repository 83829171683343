import React from 'react'
import unauthorizedImg from '../../images/unauthorized.png'
import './Unauthorized.css'

const Unauthorized = () => (
  <div className="unauthorized">
    <div className="notice">
      <img src={unauthorizedImg} alt="unauthorized" />
      <h1>
        Unauthorized
      </h1>
      <h2>
        閲覧する権限がありません
      </h2>
    </div>
  </div>
)

export default Unauthorized
