import React from 'react'
import GoogleMapWrapperForMamoriosView from './GoogleMapWrapperForMamoriosView'

type Props = {
  mamorios: IMamorio[],
  nameFilter: string | null,
  zoom: number,
}

const MamoriosMapDetailView = ({ mamorios, nameFilter, zoom }: Props) => {
  const maxZoom = zoom
  let selectedMamorios: IMamorio[]
  if (nameFilter && nameFilter !== '') {
    selectedMamorios = mamorios.filter((m) => m.name.includes(nameFilter))
  } else {
    selectedMamorios = mamorios
  }

  const hasRecentLog = selectedMamorios.filter((m) => m.recent_log).length > 0

  if (!selectedMamorios || selectedMamorios.length === 0 || !hasRecentLog) {
    return (
      <div className="privacy-desc" style={{ paddingLeft: '16px' }}>
        位置を表示できるMAMORIOが存在しません。
      </div>
    )
  }

  return (
    <div className="map-view">
      <GoogleMapWrapperForMamoriosView
        containerStyle={{ height: '700px' }}
        zoom={zoom}
        maxZoom={maxZoom}
        mamoriosOrDevices={selectedMamorios}
      />
    </div>
  )
}

export default MamoriosMapDetailView
