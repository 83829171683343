import React, { useState } from 'react'
import {
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
  useJsApiLoader,
  GroundOverlay,
} from '@react-google-maps/api'
import type { Libraries } from '@googlemaps/js-api-loader'
import { GOOGLE_MAP_API_KEY } from '../../constants/Constants'
import { getFromNowAsText } from '../../helpers/timeHelper'

type Props = {
  zoom: number
  detectionLogs: Pick<IDetectionLog, 'detected_at' | 'latitude' | 'longitude'>[]
  containerStyle: any
  mapPinIconSrc: string
  blueprints: IBlueprint[]
}

const GoogleMapWrapperForDetailView = ({
  zoom,
  detectionLogs,
  containerStyle,
  mapPinIconSrc,
  blueprints,
}: Props) => {
  const [libraries] = useState<Libraries>(['visualization'])
  const {
    isLoaded,
    loadError,
  } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  })
  const [size, setSize] = useState<undefined | google.maps.Size>(undefined)
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(true)
  const infoWindowOptions = {
    pixelOffset: size,
  }
  const updateOffsetSize = () => setSize(new window.google.maps.Size(0, 0))
  const recentLog = detectionLogs[0]
  const { latitude, longitude } = recentLog || { latitude: undefined, longitude: undefined }

  const renderMap = () => (
    <GoogleMap
      zoom={zoom}
      center={{
        lat: Number(latitude),
        lng: Number(longitude),
      }}
      onLoad={() => {
        updateOffsetSize()
      }}
      mapContainerStyle={containerStyle}
    >
      {
        blueprints.map((blueprint) => (
          <GroundOverlay
            key={blueprint.id}
            url={blueprint.image.url}
            opacity={0.5}
            bounds={{
              north: blueprint.north,
              south: blueprint.south,
              east: blueprint.east,
              west: blueprint.west,
            }}
          />
        ))
      }
      <Marker
        icon={{
          url: mapPinIconSrc,
          scaledSize: new window.google.maps.Size(48, 48),
        }}
        position={{
          lat: Number(latitude),
          lng: Number(longitude),
        }}
        onClick={() => { setShowInfoWindow(true) }}
      >
        {
          showInfoWindow && (
            <InfoWindow
              options={infoWindowOptions}
              position={{
                lat: Number(latitude),
                lng: Number(longitude),
              }}
              onCloseClick={() => { setShowInfoWindow(false) }}
            >
              <div>
                <span>最後にここにありました</span>
                <br />
                {
                  detectionLogs[0] && (
                    <span>{getFromNowAsText(detectionLogs[0]!.detected_at)}</span>
                  )
                }
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                >
                  <span>Googleマップで見る</span>
                </a>
              </div>
            </InfoWindow>
          )
        }
      </Marker>
      <Polyline
        path={detectionLogs.map((log) => ({ lat: Number(log.latitude), lng: Number(log.longitude) }))}
        options={{
          strokeColor: '#1F95F4',
          strokeOpacity: 0.77,
          strokeWeight: 10,
        }}
      />
    </GoogleMap>
  )

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }
  return isLoaded ? renderMap() : null
}

export default GoogleMapWrapperForDetailView
