/**
 * 渡された配列とconditionクロージャーから要素を削除する。conditionがfalseを返すなら何もせずにarrを返す。
 * @param arr 対象の配列
 * @param condition arrの要素を引数に持ちbool値を返すクロージャー。
 * @returns 新しい配列
 */
export const deleteElement = <T>(arr: T[], condition: (item: T) => boolean): T[] => {
  const idx = arr.findIndex((el) => condition(el))
  const dupArr = [...arr]

  if (idx === -1) {
    return arr
  }
  dupArr.splice(idx, 1)
  return dupArr
}

export const deleteLastElement = <T>(arr: T[], condition: (item: T) => boolean): T[] => {
  const arrR = arr.slice().reverse()

  return deleteElement(arrR, condition)
    .slice()
    .reverse()
}

export const deleteElementWithIndex = <T>(arr: T[], idx: number): T[] => {
  const dupArr = [...arr]
  dupArr.splice(idx, 1)
  return dupArr
}

export const isBlank = <T>(arr: T[]): boolean => (arr.length === 0)

/**
 * 配列の要素があるかどうかを判定する関数。配列オブジェクトが存在し、かつ要素が1以上あればtrueを返す。
 * その他はfalseを返す。
 * @param arr 対象の配列
 * @returns 引数の配列が要素をもつならtrue.それ以外はfalse
 */
export const isAnyElementInArray = <T>(arr: T[] | undefined): boolean => {
  if (!arr || arr.length === 0) return false
  return true
}
