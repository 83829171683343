import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { parseAsync } from 'json2csv'
import './CSVModal.css'
import {
  SortOrderAsc, getTeamMamorios, SortOrder, updateWithCSV,
} from '../../../modules/Mamorio'
import { HOSTNAME } from '../../../constants/Constants'
import { useAppDispatch } from '../../../hooks'
import Loading from '../../common/Loading'

const handleBlob = (data: BlobPart[], options: BlobPropertyBag, callback: (url: string) => void) => {
  const url = URL.createObjectURL(new Blob(data, options))
  callback(url)
  URL.revokeObjectURL(url)
}

const CSVModal = ({
  didUpdteMamorios,
  closeModalFunction,
}: { didUpdteMamorios: VoidFunction, closeModalFunction: VoidFunction }) => {
  const dispatch = useAppDispatch()
  const [mamorios, setMamorios] = useState<IMamorio[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState<File | null | undefined>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isZeroPaddingProtected, setIsZeroPaddingProtected] = useState(false)

  const downloadFormatCSV = async () => {
    setIsLoading(true)
    const header = ['major', 'minor', 'name', 'memo']
    handleBlob([`${header}\n`], { type: 'text/csv' }, (url) => {
      setIsLoading(false)
      const a = document.createElement('a')
      a.href = url
      a.download = 'mamorio_format.csv'
      a.click()
    })
  }

  const downloadMamorios = async () => {
    setIsLoading(true)
    let mamoriosData = mamorios
    if (mamoriosData.length === 0) {
      const filterArg = {
        teamDomain: HOSTNAME?.split('.')[0],
        sort: 'name',
        order: SortOrderAsc as SortOrder,
        page: 1,
        maxMamorios: true,
      }
      const result = await dispatch(getTeamMamorios({
        filter: filterArg,
      })).unwrap()
      setMamorios(result.mamorios)
      mamoriosData = result.mamorios
    }

    const formattedData = isZeroPaddingProtected
      ? mamoriosData.map((item) => ({
        ...item,
        name: `="${item.name}"`,
      }))
      : mamoriosData

    const csv = await parseAsync(formattedData, {
      fields: [
        {
          label: 'major',
          value: 'major',
        },
        {
          label: 'minor',
          value: 'minor',
        },
        {
          label: 'name',
          value: 'name',
        },
        {
          label: 'memo',
          value: 'memo',
        },
      ],
      withBOM: true,
    })
    handleBlob([`${csv}\n`], { type: 'text/csv' }, (url) => {
      setIsLoading(false)
      const a = document.createElement('a')
      a.href = url
      a.download = 'mamorios.csv'
      a.click()
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  const handleUpload = async () => {
    if (file) {
      setIsLoading(true)
      try {
        const result = await dispatch(updateWithCSV({ file })).unwrap()
        // eslint-disable-next-line no-alert
        alert(result.message)
        didUpdteMamorios()
      } catch (error: unknown) {
        // eslint-disable-next-line no-alert
        alert(`エラーが発生しました: ${error}`)
      } finally {
        setIsLoading(false)
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('ファイルが選択されていません')
    }
  }

  return (
    <div className="modal-form">
      <Loading show={isLoading} />
      <div style={{ justifyContent: 'center' }}>
        <span>
          CSVファイルをアップロードしMAMORIOの名前とメモを一括更新できます。
        </span>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={downloadFormatCSV}
      >
        フォーマットをダウンロード
      </button>
      <div>
        <div>
          <label
            htmlFor="preserve-zero-padding"
            style={{
              display: 'flex', width: '400px', alignItems: 'center', justifyContent: 'center', marginRight: '10px', borderRadius: '4px', border: '1px solid #dcdcdc',
            }}
          >
            <input
              id="preserve-zero-padding"
              type="checkbox"
              style={{ width: '25px', height: '25px', marginRight: '10px' }}
              checked={isZeroPaddingProtected}
              onChange={(e) => setIsZeroPaddingProtected(e.target.checked)}
            />
            ゼロ埋め表示を保護する
          </label>
        </div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={downloadMamorios}
        >
          現在のMAMORIOの一覧をダウンロード
        </button>
      </div>
      <div>
        <input
          id="csvupload"
          type="file"
          accept=".csv"
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleUpload}
        >
          アップロードする
        </button>
      </div>
      <button
        type="button"
        className="btn btn-outline-primary button-cancel"
        onClick={() => {
          console.error('ll;')
          if (closeModalFunction) closeModalFunction()
        }}
      >
        キャンセル
      </button>
    </div>
  )
}

CSVModal.defaultProps = {
  didUpdteMamorios: null,
  closeModalFunction: null,
}

CSVModal.propTypes = {
  didUpdteMamorios: PropTypes.func,
  closeModalFunction: PropTypes.func,
}

export default CSVModal
