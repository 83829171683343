export const INDEX = '/index.html'
export const OVERVIEW = '/'
export const LOGIN = '/login'
export const VERIFICATION = '/verification'
export const LOGOUT = '/logout'
export const GROUPS = '/groups'
export const GROUP = '/groups/:group_id'
export const SETTINGS = '/settings'
export const SETTINGS_EDIT_MAMORIO = '/settings/mamorio'
export const SETTINGS_GROUP_MANAGEMENT = '/settings/group-management'
export const SETTINGS_GROUP_MANAGEMENT_USERS = '/settings/group-management/:group_id'
export const SETTINGS_MAMORIO_SPOT = '/settings/mamorio-spot'
export const USER_SETTING = '/users/:user_id/setting'
export const SPOTS = '/spots'
export const NEWS = '/news'
export const UNAUTHORIZED = '/unauthorized'
export const HEATMAP = '/heatmap'
export const BIZ_PLUS_TEST = '/biz_plus_test'
export const BIZ_PLUS_AKITA = '/biz_plus_akita'
export const MAMORIOS_DETAIL = '/mamorios/:major_minor'
