import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
import { HeaderNavi, GroupNavi } from '.'
import Loading from '../common/Loading'
import { SettingTabContent } from '../../constants/SettingTabsInfo'
import { useAppSelector } from '../../hooks'
import './TabMenu.css'

type Props = {
  children: JSX.Element | JSX.Element[]
  tabsInfo?: SettingTabContent[]
}

const TabMenu = ({
  children,
  tabsInfo,
}: Props) => {
  // TODO: 複数のrentalグループに対応する
  const isLoading = useAppSelector((state) => state.ui.loading)
  const location = useLocation()

  const createTab = (item: {
    label: string
    url: string
  }, index: number) => (
    <div className={`tab ${location.pathname.split('/').slice(0, 3).join('/') === item.url ? 'tab-active' : ''}`} key={index}>
      <NavLink to={item.url}>
        <div className="tab-label">
          {item.label}
        </div>
      </NavLink>
    </div>
  )

  return (
    <div className="content">
      <HeaderNavi />
      <Loading show={isLoading} />
      <GroupNavi>
        <div className="tab-view">
          <div className="tab-menu">
            {tabsInfo?.map((item, index) => createTab(item, index))}
          </div>
          <div className="tab-content">
            {children}
          </div>
        </div>
      </GroupNavi>
    </div>
  )
}

TabMenu.defaultProps = {
  tabsInfo: null,
}

TabMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  tabsInfo: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  })),
}

export default TabMenu
