import React, { CSSProperties, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap, InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api'
import type { Libraries } from '@googlemaps/js-api-loader'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { MAMORIO_SHAPE } from '../../constants/Shapes'
import { GOOGLE_MAP_API_KEY } from '../../constants/Constants'
import {
  getMamorioImageSrc,
  isDevice,
} from '../../helpers/CommonMamorioHelper'
import { getFromNowAsText } from '../../helpers/timeHelper'
import {
  deviceIcon,
} from '../../icons'

type Props = {
  zoom: number
  maxZoom: number
  mamoriosOrDevices: IMamorio[]
  containerStyle: CSSProperties
}

type Position = {
  lat: number
  lng: number
}

const GoogleMapWrapperForMamoriosView = ({
  zoom,
  maxZoom,
  mamoriosOrDevices,
  containerStyle,
}: Props) => {
  const [libraries] = useState<Libraries>(['visualization'])
  const {
    isLoaded,
    loadError,
  } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  })

  const [size, setSize] = useState<undefined | google.maps.Size>(undefined)
  const [selectedMamorio, setSelectedMamorio] = useState<IMamorio | null>(null)
  const [mapCenter, setMapCenter] = useState<Position>({
    lat: Number(35.7007505),
    lng: Number(139.769443),
  })

  useEffect(() => {
    // MAMORIOのフィルターの変更が行われたらデセレクトする
    setSelectedMamorio(null)
  }, [mamoriosOrDevices])

  useEffect(() => {
    if (selectedMamorio && selectedMamorio.recent_log) {
      setMapCenter({ lat: Number(selectedMamorio.recent_log.latitude), lng: Number(selectedMamorio.recent_log.longitude) })
    } else if (mamoriosOrDevices.length > 0) {
      const recentLog = mamoriosOrDevices.filter((m) => m.recent_log)[0]?.recent_log
      if (recentLog) {
        setMapCenter({ lat: Number(recentLog.latitude), lng: Number(recentLog.longitude) })
      }
    }
  }, [selectedMamorio, mamoriosOrDevices])

  const infoWindowOptions = {
    pixelOffset: size,
  }

  const updateOffsetSize = () => setSize(new window.google.maps.Size(0, 0))

  function recentPosition(mamorio: IMamorio): Position | null | undefined {
    const recentLog = mamorio.recent_log
    if (!recentLog) return null

    const position = {
      lat: Number(recentLog.latitude),
      lng: Number(recentLog.longitude),
    }
    return position
  }

  function recentDetectedAt(mamorio: IMamorio): string {
    const recentLog = mamorio.recent_log
    if (!recentLog) return ''
    return getFromNowAsText(recentLog.at)
  }

  const clusters = (clusterer: Clusterer): JSX.Element[] => mamoriosOrDevices.filter((mamorio) => selectedMamorio === null || selectedMamorio === mamorio)
    .filter((mamorio) => recentPosition(mamorio) != null).map((mamorio) => (
      <Marker
        key={`mamorios-map-pin-${mamorio.id}`}
        icon={{
          url: isDevice(mamorio) ? deviceIcon : getMamorioImageSrc(mamorio),
          scaledSize: new window.google.maps.Size(48, 48),
        }}
        position={recentPosition(mamorio)!}
        clusterer={clusterer}
        onClick={() => setSelectedMamorio(mamorio)}
      >
         {selectedMamorio === mamorio && (
          <InfoWindow
            position={recentPosition(mamorio)!}
            options={infoWindowOptions}
            onCloseClick={() => { setSelectedMamorio(null) }}
          >
            <div>
              <span>{mamorio.name}</span>
              <br />
              <span>{recentDetectedAt(mamorio)}</span>
              <br />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${recentPosition(mamorio)!.lat},${recentPosition(mamorio)!.lng}`}
              >
                <span>Googleマップで見る</span>
              </a>
            </div>
          </InfoWindow>
         )}
      </Marker>
    ))

  const renderMap = () => (
    <GoogleMap
      zoom={zoom}
      center={mapCenter}
      mapContainerStyle={containerStyle}
      onLoad={() => updateOffsetSize()}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        maxZoom={maxZoom}
      >
        {(clusterer) => (<>{clusters(clusterer)}</>)}
      </MarkerClusterer>
    </GoogleMap>
  )

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? renderMap() : null
}

GoogleMapWrapperForMamoriosView.propTypes = {
  zoom: PropTypes.number.isRequired,
  maxZoom: PropTypes.number.isRequired,
  mamoriosOrDevices: PropTypes.arrayOf(MAMORIO_SHAPE).isRequired,
  containerStyle: PropTypes.shape({
    height: PropTypes.string,
  }).isRequired,
}

export default GoogleMapWrapperForMamoriosView
