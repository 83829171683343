import React from 'react'
import PropTypes from 'prop-types'
import { mamorioIcon } from '../../icons'
import './Loading.css'

type Props = {
  show: boolean
}

const Loading = ({ show }: Props): JSX.Element | null => {
  if (!show) return null

  return (
    <div className="loading-bg" data-testid="loading">
      <div className="loading-circle">
        <div className="loading">
          <img src={mamorioIcon} className="jump" alt="loading" />
          <br />
          Loading...
        </div>
      </div>
    </div>
  )
}

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default Loading
