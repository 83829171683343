import React, { useCallback, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { login } from '../../modules/User'
import {
  closeModal, showModal, requestStart, requestFinished,
} from '../../modules/UI'
import { ERROR_MODAL } from '../../components/common/RootModal'
import { LOGIN, OVERVIEW } from '../../constants/Paths'
import { HeaderNavi } from '../../components/navigation'
import InlineLoadingAnimation from '../../components/common/InlineLoadingAnimation'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { buildUrl } from '../../modules/common/httpUtils'
import { HOSTNAME } from '../../constants/Constants'
import { getAccessToken, saveSession } from '../../modules/common/session'
import { loginLogoIcon } from '../../icons'
import '../Login/Login.css'
import { selectLoadingState } from '../../store'
import { getErrorString } from '../../utils/errorUtils'

type VerificationLocationState = {
  email: string
  password: string
}

const checkCodeValid = (target: HTMLInputElement) => target.validity.valid && target.value.length === 6

/**
 * 認証画面 (使われてない？)
 * @returns {JSX.Element}
 * @constructor
 */
export default () => {
  const [code, setCode] = useState({
    value: '',
    valid: false,
  })
  // Redux -----------------------------------------
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectLoadingState)

  // History -----------------------------------------
  const location = useLocation()

  // Methods -----------------------------------------
  const showErrorModal = useCallback((message) => {
    if (message === null || message === undefined) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  const verify = async (email: string, password: string): Promise<{ error?: string | null }> => {
    const accessToken = getAccessToken()

    dispatch(requestStart())

    try {
      const response = await fetch(buildUrl('/users/verify.json'), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          user: {
            email,
            password,
            hostname: HOSTNAME,
          },
          mfa: {
            code: code.value,
          },
        }),
      })

      const data = await response.json()
      if (response.status >= 400) {
        return { error: data.error || response.statusText }
      }

      saveSession(data.access_token, data.root_group_id)

      return { error: null }
    } catch (e: unknown) {
      return { error: getErrorString(e) }
    } finally {
      dispatch(requestFinished())
    }
  }

  /**
   * 入力フォームの onChange イベントのハンドラー
   * 入力値の検証を行う
   * @param target HTMLInputElement
   */
  const handleChangeVerificationCode = (target: HTMLInputElement) => {
    setCode({
      value: target.value,
      valid: checkCodeValid(target),
    })
  }

  /**
   * 「認証コードを再送信する」ボタンの onClick イベントのハンドラー
   * @param event
   */
  const handleClickCreateVerificationAgainButton = async (event: React.MouseEvent) => {
    event.preventDefault()

    try {
      const { email, password } = location.state as VerificationLocationState

      await dispatch(login({
        email,
        password,
      })).unwrap()

      // TODO: alert からモーダルへの置き換え
      // eslint-disable-next-line no-alert
      alert('認証コードを再送しました。')
    } catch (e: unknown) {
      showErrorModal(getErrorString(e))
    }
  }

  /**
   * 「認証する」ボタンの onClick イベントのハンドラー
   * @param event
   * @returns {Promise<void>}
   */
  const handleClickVerifyButton = async (event: React.MouseEvent | React.FormEvent) => {
    event.preventDefault()

    if (!code.valid) {
      showErrorModal('認証コードが正しくありません。')
      return
    }

    try {
      const { email, password } = location.state as VerificationLocationState

      const result = await verify(email, password)

      if (result.error) showErrorModal(result.error)
    } catch (e: unknown) {
      showErrorModal(getErrorString(e))
    }
  }

  const redirect = <Redirect to={OVERVIEW} />

  try {
    const { email, password } = location.state as VerificationLocationState

    if (!email || !password) {
      return redirect
    }
  } catch (e: unknown) {
    return redirect
  }

  if (getAccessToken() !== null) {
    return redirect
  }

  // Render -----------------------------------------
  return (
    <div
      className="login-content"
      data-testid="verification"
    >
      <HeaderNavi />
      <div className="mamorio-login-form">
        <form onSubmit={handleClickVerifyButton}>
          <img
            src={loginLogoIcon}
            className="mamorio-login-logo"
            alt="office-logo"
          />
          <input
            type="text"
            name="code"
            size={80}
            placeholder="認証コード（6桁）"
            onChange={(event) => handleChangeVerificationCode(event.target)}
            data-testid="verification-code-input"
          />
          <button
            type="submit"
            name="login"
            className="btn btn-primary btn-lg"
            onClick={handleClickVerifyButton}
            data-testid="verification-submit-button"
          >
            {isLoading ? (
              <div>
                <InlineLoadingAnimation
                  size="32px"
                  color="#FFF"
                />
              </div>
            ) : '認証する'}
          </button>
        </form>
      </div>

      <div className="mamorio-login-menu">
        <button
          type="button"
          className="createVerificationAgain"
          onClick={handleClickCreateVerificationAgainButton}
          data-testid="verification-resend-code-button"
        >
          認証コードを再送信する
        </button>
        <br />
        <a href={LOGIN}>
          ログイン画面に戻る
        </a>
      </div>
      <div
        className="mamorio-login-copyright"
        data-testid="verification-copyright"
      >
        Copyright &copy; 2018 MAMORIO,Inc. All Rights Reserved.
      </div>
    </div>
  )
}
