import React from 'react'
import GroupCreationInquiryInfo from './GroupCreationInquiryInfo'
import { emptyStateGroup } from '../../../icons'

type Props = {
  onClick: () => void
}

const EmptyGroup = ({ onClick }: Props) => (
  <div className="empty-list">
    <img src={emptyStateGroup} alt="group" />
    <p className="title">
      グループがありません
    </p>
    <button
      type="button"
      className="btn btn-primary"
      onClick={onClick}
      disabled
    >
      グループを作成
    </button>
    <GroupCreationInquiryInfo />
  </div>
)

export default EmptyGroup
