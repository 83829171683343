import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import AnimateHeight from 'react-animate-height'
import GroupMemberListItem from './GroupMemberListItem'
import { iconPlusSettings } from '../../../icons'
import { closeModal, showModal } from '../../../modules/UI'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { BASE_MODAL, CONFIRM_MODAL, ERROR_MODAL } from '../../common/RootModal'
import EditUserModal from '../modal/EditUserModal'
import ChipsInput from '../../common/ChipsInput'
import { getGroupUsers, inviteUserToGroup, removeUserFromGroup } from '../../../modules/Group'
import { isBlank } from '../../../utils/arrayUtil'
import { getErrorString } from '../../../utils/errorUtils'
import UserPagination from '../../common/UserPagination'

type Props = {
  group: Pick<IGroup, 'id' | 'name' | 'users'>
}

const GroupMemberList = ({
  group,
}: Props) => {
  const dispatch = useAppDispatch()
  const users = useAppSelector((state) => state.group.users?.users)
  const totalPages = useAppSelector((state) => state.group.users?.total_pages || 1)
  const [currentPage, setCurrentPage] = useState<number>(useAppSelector((state) => state.group.users?.current_page || 1))
  const [existingUsersEmail, setExistingUsersEmail] = useState<string[]>(group?.users.map((user) => user.email) || [])
  const [heightExpand, setHeightExpand] = useState<number | string>(0)
  const [candidateUserEmailsForInvitation, setCandidateUserEmailsForInvitation] = useState<string[]>([])

  const chipsInputRef = useRef(null)

  const clearChips = () => {
    if (!chipsInputRef?.current) return

    // @ts-ignore
    chipsInputRef.current.clear()
  }

  const onClickPagination = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const fetchGroupUsers = useCallback(async (pageNumber: number = currentPage) => {
    try {
      await dispatch(getGroupUsers({ groupId: group.id, page: pageNumber })).unwrap()
    } catch (error: unknown) {
      // TODO: 例外処理
      console.error(getErrorString(error))
    }
  }, [group, currentPage, dispatch])

  useEffect(() => {
    fetchGroupUsers(currentPage)
  }, [currentPage, fetchGroupUsers])

  /**
  * 「招待する」ボタン押下時の処理
  */
  const onClickInviteUser = async () => {
    try {
      const groupId = group.id
      await dispatch(inviteUserToGroup({
        groupId,
        emails: candidateUserEmailsForInvitation,
      })).unwrap()
    } catch (error) {
      dispatch(showModal({
        modalType: ERROR_MODAL,
        modalProps: {
          message:
            'ご入力に誤りがあるか、招待対象のmamorioアカウントが存在しません。\n'
            + 'アカウント作成がお済みでない場合は、mamorioアプリからもしくは\n'
            + 'https://user.mamorio.jp よりご作成をお願いします。',
        },
      }))
    } finally {
      clearChips()
      fetchGroupUsers()
    }
  }

  const handleUpdateChips = (items: string[]) => {
    setCandidateUserEmailsForInvitation(items)
  }

  const onClickInviteUserExpand = () => {
    if (!chipsInputRef?.current) return

    if (heightExpand === 0) {
      // @ts-ignore
      chipsInputRef.current.focus() // FIXME: NOT WORK
    }

    setHeightExpand(heightExpand === 0 ? 'auto' : 0)
  }

  const onSuccessRemoveUser = (targetUserId: UserId) => {
    if (!group) return

    setExistingUsersEmail(
      group.users
        .filter((user) => user.id !== targetUserId)
        .map((user) => user.email),
    )
    fetchGroupUsers()
  }

  // 「招待を取り消す」ボタン押下時の処理
  const handleClickCancelInvitation = (targetUser: { id: UserId }) => {
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: 'チームから削除すると、もう一度参加するには再度招待メールを送る必要があります。チームから削除しますか？',
        confirmButtonText: '削除',
        onActionConfirmed: async () => {
          const groupId = group.id
          try {
            // 取り消しをリクエスト
            await dispatch(removeUserFromGroup({
              groupId,
              userId: targetUser.id,
            })).unwrap()

            onSuccessRemoveUser(targetUser.id)

            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          } catch (error: unknown) {
            // TODO: 例外処理
            console.error(error)
          }
        },
      },
    }))
  }

  const onEditUser = (user: any) => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'メンバーの編集',
        user,
        group,
        onActionConfirmed: () => fetchGroupUsers(),
        // @ts-ignore
        children: <EditUserModal onSuccessRemoveUser={onSuccessRemoveUser} />,
      },
    }))
  }

  return (
    <div>
      <div className="group-settings-column">
        <div className="group-user-cell-expand">
          <div
            className="group-user-invitation"
            onClick={onClickInviteUserExpand}
          >
            <img src={iconPlusSettings} alt="expand" />
            <div>
              メンバーを招待
            </div>
          </div>
          <AnimateHeight
            className="member-invite-container"
            duration={500}
            height={heightExpand}
          >
            <div className="member-invite">
              <ChipsInput
                className="add-member-input"
                existingItems={existingUsersEmail}
                fullWidth
                onUpdateItems={(items) => handleUpdateChips(items)}
                placeholder="招待したいMAMORIOユーザーのメールアドレスを入力してください。（複数入力可能）"
                ref={chipsInputRef}
              />
              <button
                type="button"
                disabled={isBlank(candidateUserEmailsForInvitation)}
                className="btn btn-primary btn-sm"
                onClick={onClickInviteUser}
              >
                招待する
              </button>
            </div>
          </AnimateHeight>
        </div>
        <div className="group-user-column">
          {users?.map((user) => (
            <GroupMemberListItem
              key={`${Date.now()}${user.id}`}
              member={user}
              onEditUser={onEditUser}
              onRemoveUser={handleClickCancelInvitation}
            />
          ))}
          <div className="pagination">
            <UserPagination currentPageNumber={currentPage} totalPages={totalPages} onChangePage={onClickPagination} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GroupMemberList
