import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { closeModal, showModal } from '../../modules/UI'
import { returnRental } from '../../modules/Rental'
import {
  CONFIRM_MODAL, ERROR_MODAL, MAMORIO_DETAIL_MODAL,
} from '../common/RootModal'
import { isAnyElementInArray } from '../../utils/arrayUtil'
import MamoriosListItem from './GroupMamorioListItem'
import './OverviewItemList.css'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  Filter, SortOrder, SortOrderAsc, SortOrderDesc, updateGroupMamorio,
} from '../../modules/Mamorio'
import { getErrorString } from '../../utils/errorUtils'
import { getFullDate } from '../../helpers/timeHelper'
import { showLostAndBattery, showMemo } from '../../modules/common/session'
import { GROUP_SHAPE } from '../../constants/Shapes'
import HeaderType from '../../modules/common/headerType'
import getSortIcon from '../../modules/common/sortUtil'

type SortAndOrder = {
  sort?: string
  order?: SortOrder
}

type Props = {
  group: IGroup
  filter?: Filter
  onClickHeader: Function
  onUpdateMamorio: Function
}

const SortKey = {
  Name: 'name',
  Memo: 'memo',
  Time: undefined,
  BatteryLifeRessetedAt: 'battery_life_resetted_at',
  Status: 'status',
} as TSortKey

const GroupMamorioList = ({
  group,
  filter,
  onClickHeader,
  onUpdateMamorio,
}: Props) => {
  const dispatch = useAppDispatch()
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)
  const [sort, setSort] = useState<Nullable<string>>(filter?.sort)
  const [order, setOrder] = useState<Nullable<string>>(filter?.order)

  const onClick = (type: HeaderType) => {
    let params: SortAndOrder = {
      sort: '',
      order: SortOrderAsc,
    } as SortAndOrder

    const sortKeyMap = {
      [HeaderType.Name]: SortKey.Name,
      [HeaderType.Memo]: SortKey.Memo,
      [HeaderType.Time]: SortKey.Time,
      [HeaderType.BatteryLifeRessetedAt]: SortKey.BatteryLifeRessetedAt,
      [HeaderType.Status]: SortKey.Status,
    }

    if (Object.prototype.hasOwnProperty.call(sortKeyMap, type)) {
      const targetSortKey = sortKeyMap[type]
      let targetOrder: SortOrder
      if (sort !== targetSortKey) {
        targetOrder = SortOrderAsc
      } else {
        targetOrder = order === SortOrderDesc ? SortOrderAsc : SortOrderDesc
      }
      params = {
        sort: targetSortKey,
        order: targetOrder,
      }
    } else {
      // eslint-disable-next-line no-console
      console.warn(`${type} does not exist type`)
    }

    setSort(params.sort)
    setOrder(params.order)

    onClickHeader(params, () => {

    })
  }

  const onForceReturnRentalError = (message: string) => {
    if (message === null || message === undefined) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }

  const onClickForceReturnRental = async (targetMamorio: IMamorio, detailViewCallback: VoidFunction) => {
    if (!group?.id) return
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: '強制的に返却すると、持ち出しが解除され<br />所有者がオーナーに戻ります。よろしいですか？',
        confirmButtonText: '強制的に返却する',
        onActionConfirmed: async () => {
          try {
            await dispatch(returnRental({
              groupId: group.id,
              mamorioId: targetMamorio.id,
              filter,
            })).unwrap()
            detailViewCallback()
          } catch (e: unknown) {
            onForceReturnRentalError(getErrorString(e))
          } finally {
            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          }
        },
      },
    }))
  }

  const onClickResetBattery = async (targetMamorio: IMamorio, detailViewCallback: VoidFunction) => {
    if (!group?.id) return
    dispatch(showModal({
      modalType: CONFIRM_MODAL,
      modalProps: {
        message: '電池交換日を本日に設定します。よろしいですか？',
        confirmButtonText: 'はい',
        onActionConfirmed: async () => {
          try {
            await dispatch(updateGroupMamorio({
              mamorio: targetMamorio,
              params: { battery_life_resetted_at: getFullDate(new Date()) },
            })).unwrap()
            detailViewCallback()
            onUpdateMamorio()
          } catch (e: unknown) {
            onForceReturnRentalError(getErrorString(e))
          } finally {
            dispatch(closeModal({ modalType: CONFIRM_MODAL }))
          }
        },
      },
    }))
  }

  const onClickList = (mamorio: IMamorio) => {
    dispatch(showModal({
      modalType: MAMORIO_DETAIL_MODAL,
      modalProps: {
        mamorio,
        onRentalForceReturnClick: (callback: VoidFunction) => onClickForceReturnRental(mamorio, callback),
        onClickResetBattery: (callback: VoidFunction) => onClickResetBattery(mamorio, callback),
      },
    }))
  }

  const emptyItem = () => (
    <tr>
      <td colSpan={6} className="list-row">
        {
          filter && Object.values(filter)
            .some(Boolean)
            ? '検索条件に該当するMAMORIOがありません。'
            : 'このグループにはまだMAMORIOがありません。'
        }
      </td>
    </tr>
  )

  const handleClickNameHeader = () => onClick(HeaderType.Name)
  const handleClickMemoHeader = () => onClick(HeaderType.Memo)
  const handleClickLastFoundTimeHeader = () => onClick(HeaderType.Time)
  const handleClickBatteryLifeResettedAt = () => onClick(HeaderType.BatteryLifeRessetedAt)
  const handleClickStatus = () => onClick(HeaderType.Status)

  const icon = getSortIcon(sort, order)
  const cursor = 'pointer'

  return (
    <div className="overview-list" data-testid="overview-item-list">
      <table className="table">
        <thead className="header" data-testid="overview-item-list-header">
        <tr>
          <th>
            <div
              className="title"
              style={{ cursor }}
              onClick={handleClickNameHeader}
            >
              MAMORIOの名前
                <img alt="sort" src={icon.name} />
            </div>
          </th>
          {showMemo() && (
            <th>
              <div
                className="title"
                style={{ cursor, minWidth: '40px' }}
                onClick={handleClickMemoHeader}
              >
                  メモ
                <img alt="sort" src={icon.memo} />
              </div>
            </th>
          )}
          <th>
            <div
              className="title"
              style={{ cursor }}
              onClick={handleClickLastFoundTimeHeader}
            >
              最終検知時刻
                <img alt="sort" src={icon.time} />
            </div>
          </th>
          <th>
            <div className="title">
              最後に検知した端末
            </div>
          </th>
          <th>
            <div
              className="title"
              style={{ cursor, minWidth: '75px' }}
              onClick={handleClickBatteryLifeResettedAt}
            >
              バッテリー
              <img alt="sort" src={icon.batteryLifeResettedAt} />
            </div>
          </th>
          {showLostAndBattery() && (
            <th>
              <div
                className="title"
                style={{ cursor }}
                onClick={handleClickStatus}
              >
                状態
                <img alt="sort" src={icon.status} />
              </div>
            </th>
          )}
          <th>
            <div className="title">
              所持者
            </div>
          </th>
        </tr>
        </thead>
        <tbody className="list" data-testid="overview-item-list-body">
        {
          !mamorios || !isAnyElementInArray(mamorios)
            ? emptyItem()
            : mamorios.map((mamorio: IMamorio) => MamoriosListItem({
              mamorio,
              onClickList: () => onClickList(mamorio),
            }))
        }
        </tbody>
      </table>
    </div>
  )
}

GroupMamorioList.defaultProps = {
  filter: undefined,
}

GroupMamorioList.propTypes = {
  group: GROUP_SHAPE.isRequired,
  // @ts-ignore
  filter: PropTypes.shape(),
  onClickHeader: PropTypes.func.isRequired,
  onUpdateMamorio: PropTypes.func.isRequired,
}

export default GroupMamorioList
