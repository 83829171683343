import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { requestStart, requestFinished } from './UI'
import { isAnyElementInArray } from '../utils/arrayUtil'
import { getGroupMamorios, Filter } from './Mamorio'
import { buildUrl, createRequest, normalizeQueryParams } from './common/httpUtils'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'

interface IRentalState {
  rentalItems: any[]
  selectedReturnItems: any[]
}

interface IRentalAction {
  id: string
  rentals: any
}

// state
const initialState = {
  rentalItems: [],
  selectedReturnItems: [],
} as IRentalState

const rentalSlice = createSlice({
  name: 'rental',
  initialState,
  reducers: {
    successGetRentals: (state, action: PayloadAction<IRentalAction>) => ({
      rentalItems: action.payload.rentals,
      selectedReturnItems: state.selectedReturnItems,
    }),
    toggleSelectedRentalItem: (state, action: PayloadAction<IRentalAction>) => {
      const result = state
        .selectedReturnItems
        .filter((item) => item.id === action.payload.id)

      if (isAnyElementInArray(result)) {
        const newSelectedReturnItems = state
          .selectedReturnItems
          .filter((item) => item.id !== result[0].id)

        return {
          rentalItems: state.rentalItems,
          selectedReturnItems: newSelectedReturnItems,
        }
      }

      return {
        rentalItems: state.rentalItems,
        selectedReturnItems: [
          ...state.selectedReturnItems,
          action.payload,
        ],
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addDefaultCase((state) => state)
  },
})

export default rentalSlice.reducer

/**
 * グループ内のすべて MAMORIO の QR コードを取得する
 */
export const getGroupMamorioQrCodes = createAsyncThunk<
IQrCode[],
{
  groupId: GroupId
},
{
  rejectValue: string
}
>('rental/getGroupMamoriosQrCode', async (arg: any, thunkApi) => {
  const { groupId } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/qr_codes`),
        'GET',
      ),
    )

    const data = await response.json()

    if (response.status >= 400 || !data.qr_codes) {
      return rejectWithValue(data.error || response.statusText)
    }

    return data.qr_codes
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * 貸し出しリストを取得する
 */
export const getRentals = createAsyncThunk<
IRental[],
{
  groupId: GroupId
  rentalStartDate: Date
  rentalEndDate: Date
},
{
  rejectValue: string
}
>('rental/getRentals', async (arg: any, thunkApi) => {
  const { groupId, rentalStartDate, rentalEndDate } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !rentalStartDate || !rentalEndDate) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams({
      'created_at[gteq]': rentalStartDate.toISOString(),
      'created_at[lteq]': rentalEndDate.toISOString(),
    }))

    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/rentals?${queryParams}`),
        'GET',
      ),
    )

    const data = await response.json()

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return data.rentals as IRental[]
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * 貸出中のデバイスを返却する
 */
export const returnRental = createAsyncThunk<
boolean,
{
  groupId: GroupId
  mamorioId: DeviceId
  filter?: Filter
},
{
  rejectValue: string
}
>('rental/returnRental', async (arg: any, thunkApi) => {
  const { groupId, mamorioId, filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !mamorioId) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_mamorios/${mamorioId}/rental`),
        'DELETE',
      ),
    )

    if (response.status >= 400) {
      return rejectWithValue(response.statusText)
    }

    await dispatch(getGroupMamorios({ groupId, filter }))

    return true
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})
