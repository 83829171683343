// @see https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns#updating-an-item-in-an-array
// @params array Target array
// @param conditionFunc condition func for detect target object
// @param updateParam   update Parameter
const updateObjectInArray = <T>(
  targetArray: T[],
  conditionFunc: (item: T) => boolean,
  updateParam: object,
): T[] => targetArray.map((item) => {
    if (!conditionFunc(item)) {
    // This isn't the item we care about - keep it as-is
      return item as T
    }

    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...updateParam,
    } as T
  })

export default updateObjectInArray
