import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { MAMORIO_SHAPE } from '../../../constants/Shapes'
import { closeModal, showModal } from '../../../modules/UI'
import { registerGroupMamorio, updateGroupMamorio } from '../../../modules/Mamorio'
import { isAnyElementInArray } from '../../../utils/arrayUtil'
import { ERROR_MODAL } from '../../common/RootModal'
import FormThumbNail from '../../common/FormThumbNail'
import { miniCameraIcon } from '../../../icons'
import './EditMamorioModal.css'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { showMemo } from '../../../modules/common/session'
import { getErrorString } from '../../../utils/errorUtils'
import { isValidString } from '../../../utils/commonUtils'

type ModalProps = {
  title: string
  mamorio: Pick<IMamorio, 'id' | 'name' | 'memo' | 'image' | 'group_id'>
  onActionConfirmed: () => void
}

type Props = {
  closeModalFunction?: () => void
  modalProps: ModalProps
}

const EditMamorioModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const dispatch = useAppDispatch()

  const [mamorio] = useState(modalProps.mamorio)
  const [newName, setNewName] = useState(modalProps.mamorio.name)
  const [newMemo, setNewMemo] = useState(modalProps.mamorio.memo)
  const [newImage, setNewImage] = useState<File | null>(null)
  const groups = useAppSelector((state) => state.team.groups)
  const [newGroup, setNewGroup] = useState(modalProps.mamorio.group_id)
  const isGroupChanged = () => mamorio.group_id !== newGroup

  const showInputEmptyError = () => {
    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message: 'MAMORIOの名前を入力してください。',
      },
    }))
  }

  /**
   * エラーモーダルを表示する
  */
  const showErrorModal = useCallback((message: string) => {
    if (!isValidString(message)) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  const onGroupChange = async () => {
    try {
      if (isGroupChanged()) {
        await dispatch(registerGroupMamorio({
          mamorio,
          groupId: newGroup,
        })).unwrap()

        modalProps.onActionConfirmed()

        if (closeModalFunction) closeModalFunction()
      }
    } catch (error: unknown) {
      showErrorModal(getErrorString(error))
    }
  }

  const onClickUpdateButton = async () => {
    if (newName.length === 0) {
      showInputEmptyError()
      return
    }

    try {
      await dispatch(updateGroupMamorio({
        mamorio,
        params: { name: newName, memo: newMemo },
      })).unwrap()

      modalProps.onActionConfirmed()

      if (closeModalFunction) closeModalFunction()
    } catch (error: unknown) {
      // TODO: エラーモーダル
      console.error(error)
    }
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewName(event.target.value)
  }

  const onChangeMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMemo(event.target.value)
  }

  const onChangeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return

    const { files } = event.target
    if (files.length === 0) return

    const imageFile = event.target.files[0]
    if (!imageFile) return

    if (isAnyElementInArray(Array.from(files))) {
      setNewImage(imageFile)
    }

    try {
      await dispatch(updateGroupMamorio({
        mamorio,
        params: { image: imageFile },
      })).unwrap()

      modalProps.onActionConfirmed()

      if (closeModalFunction) closeModalFunction()
    } catch (error: unknown) {
      // TODO: エラーモーダル
      console.error(error)
    }
  }

  let thumbnailImageUrl
  if (newImage) {
    thumbnailImageUrl = window.URL.createObjectURL(newImage)
  } else {
    thumbnailImageUrl = mamorio.image?.url || miniCameraIcon
  }

  return (
    <div className="edit-mamorio-modal modal-form">
      <div className="edit-mamorio-form">
        <label htmlFor="edit-mamorio-input">
          グループ
        </label>
        <select
          id="edit-mamorio-input"
          value={newGroup}
          onChange={(e) => setNewGroup(parseInt(e.target.value, 10) as GroupId)}
        >
          {groups?.map((group) => (
            <option value={group.id} key={group.id}>
              {group.name}
            </option>
          ))}
        </select>
        {isGroupChanged() && (
          <button
            type="button"
            className="btn btn-primary save-button"
            onClick={onGroupChange}
            style={{
              marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', marginBottom: '30px',
            }}
          >
            グループ変更
          </button>
        )}
      </div>
      <div className="edit-mamorio-form">
        <label htmlFor="edit-mamorio-input">
          名前*
        </label>
        <input
          id="edit-mamorio-input"
          type="text"
          placeholder="例: 事務所の鍵"
          value={newName}
          onChange={(e) => onChangeName(e)}
        />
      </div>
      {
        showMemo() && (
          <div className="edit-mamorio-form">
            <label htmlFor="edit-mamorio-input">
              メモ
            </label>
            <input
              id="edit-mamorio-input"
              type="text"
              value={newMemo}
              onChange={(e) => onChangeMemo(e)}
            />
          </div>
        )
      }
      <div className="edit-mamorio-form">
        <label>
          サムネイル画像
        </label>
        <div>
          <FormThumbNail imageUrl={thumbnailImageUrl} />
          <input
            type="file"
            className="d-none"
            name="image"
            id="image"
            onChange={onChangeImage}
          />
          <label htmlFor="image">
            <img
              src={miniCameraIcon}
              className="h-100"
              alt="camera"
            />
            写真を変更する
          </label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary save-button"
        onClick={onClickUpdateButton}
      >
        保存
      </button>
      <button
        type="button"
        className="btn btn-outline-primary cancel-button"
        onClick={closeModalFunction}
      >
        キャンセル
      </button>
    </div>
  )
}

EditMamorioModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    mamorio: MAMORIO_SHAPE.isRequired,
    onActionConfirmed: PropTypes.func.isRequired,
  }).isRequired,
}

export default EditMamorioModal
