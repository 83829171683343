import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import Pagination from '../../common/Pagination'
import { defaultItemIcon } from '../../../icons'
import { Filter, getTeamMamorios } from '../../../modules/Mamorio'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getErrorString } from '../../../utils/errorUtils'
import { showModal } from '../../../modules/UI'
import { BASE_MODAL } from '../../common/RootModal'
import EmptyListView from './EmptyMamorioList'
import EditMamorioModal from '../modal/EditMamorioModal'
import './MamorioSettingList.css'
import { showMemo } from '../../../modules/common/session'
import HeaderType from '../../../modules/common/headerType'
import getSortIcon from '../../../modules/common/sortUtil'
import { getMamorioImageSrc } from '../../../helpers/CommonMamorioHelper'

type Props = {
  filter: Filter
  onChangeSort: (args: any) => any
  onClickPagination: (args: any) => any
}

const MamorioSettingList = ({
  filter,
  onChangeSort,
  onClickPagination,
}: Props) => {
  const dispatch = useAppDispatch()
  const groups = useAppSelector((state) => state.team.groups)
  const mamorios = useAppSelector((state) => state.mamorio.mamorios)

  const fetchTeamMamorios = useCallback(async (filterArg: Filter) => {
    try {
      await dispatch(getTeamMamorios({
        filter: filterArg,
      })).unwrap()
    } catch (error: unknown) {
      // TODO: 例外処理
      console.error(getErrorString(error))
    }
  }, [dispatch])

  const onClickEdit = (selectedMamorio: IMamorio) => {
    dispatch(showModal({
      modalType: BASE_MODAL,
      modalProps: {
        title: 'MAMORIO編集',
        mamorio: selectedMamorio,
        onActionConfirmed: () => fetchTeamMamorios(filter),
        // @ts-ignore
        children: <EditMamorioModal />,
      },
    }))
  }

  useEffect(() => {
    fetchTeamMamorios(filter)
  }, [filter, fetchTeamMamorios])

  if (!mamorios || mamorios.length === 0) {
    return (<EmptyListView />)
  }

  const icon = getSortIcon(filter.sort, filter.order)
  const cursor = 'pointer'

  return (
    <>
      <div className="mamorio-settings">
        <table className="table">
          <thead className="header" data-testid="mamorio-item-list-header">
            <tr>
              <th>
                <div
                  className="title"
                  style={{ cursor }}
                  onClick={() => onChangeSort(HeaderType.Name)}
                >
                  MAMORIOの名前
                  <img alt="sort" src={icon.name} />
                </div>
              </th>
              {showMemo() && (
                <th>
                  <div
                    className="title"
                    style={{ cursor }}
                    onClick={() => onChangeSort(HeaderType.Memo)}
                  >
                      メモ
                    <img alt="sort" src={icon.memo} />
                  </div>
                </th>
              )}
              <th>
                <div className="title">
                  グループ
                </div>
              </th>
              <th>
                <div className="title" />
              </th>
            </tr>
          </thead>
          <tbody className="list" data-testid="mamorio-item-list-body">
            {
              mamorios.map((mamorio: IMamorio) => (
                <tr
                  key={mamorio.id}
                  className="mamorio-item-list-item"
                >
                  <td className="item mamorio-item">
                    <div style={{ display: 'flex' }}>
                      <img width={40} className="image" src={getMamorioImageSrc(mamorio, defaultItemIcon)} alt="member" onError={(e) => { e.currentTarget.src = defaultItemIcon }} />
                      <div>
                        <span className="name">
                          {mamorio.name}
                        </span>
                        <br />
                        <span className="item-majorminor-text" style={{ fontSize: '12px', color: '#757575' }}>
                          {`${mamorio.major}-${mamorio.minor}`}
                        </span>
                      </div>
                    </div>
                  </td>
                  {showMemo() && (
                    <td className="item mamorio-item">
                      <span className="name">
                        {mamorio.memo}
                      </span>
                    </td>
                  )}
                  <td className="item mamorio-item">
                    <span className="name">
                      {groups?.find((group) => group.id === mamorio.group_id)?.name}
                    </span>
                  </td>
                  <td className="item options">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        e.stopPropagation()
                        onClickEdit(mamorio)
                      }}
                    >
                      編集する
                    </button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <Pagination onChangePage={onClickPagination} />
      </div>
    </>
  )
}

MamorioSettingList.propTypes = {
  filter: PropTypes.shape({
    order: PropTypes.string,
    sort: PropTypes.string,
    isRent: PropTypes.bool,
    name: PropTypes.string,
    page: PropTypes.number,
    teamDomain: PropTypes.string,
  }).isRequired,
}

export default MamorioSettingList
