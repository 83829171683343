import React, { useState } from 'react'
import Geocode from 'react-geocode'
import DetailDeviceMap from './DetailDeviceMap'
import { closeIcon, iconSpotSideMenu } from '../../icons'
import './DetailModalCommon.css'
import './DetailModal.css'

type Props = {
  closeModalFunction: (arg: any | void) => void
  modalProps: {
    device: IMamorio
    group: IGroup
  }
}
Geocode.setLanguage('ja')

const DetailDeviceModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const [address, setAddress] = useState('')
  const [device] = useState(modalProps.device)
  const { group } = modalProps
  const recentLog = modalProps.device.recent_log

  const requestAddress = (lat: string, lng: string) => {
    Geocode.fromLatLng(lat, lng)
      .then(
        (response: any) => {
          setAddress(`${response.results[0].formatted_address}付近`)
        },
        () => {
          setAddress('住所の取得に失敗しました。')
        },
      )
  }

  if (address?.length === 0) {
    if (device.recent_log) {
      requestAddress(device.recent_log.latitude, device.recent_log.longitude)
    }
  }

  return (
    <div className="detail-modal-container" data-testid="detail-modal">
      <div
        className="overlay"
        onClick={closeModalFunction}
      />
      <div className="detail-base">
        <div className="scrollable">
          <div className="title-row">
            <div className="detail-title">
              詳細
            </div>
            <div>
              <button
                onClick={closeModalFunction}
                className="close-button"
                type="button"
              >
                <img
                  src={closeIcon}
                  alt="close-button"
                />
              </button>
            </div>
          </div>
          <div className="detail-modal-content">
            <div className="main-view">
              <div className="icon-wrapper">
                <img className="mamorio-icon" src={iconSpotSideMenu} alt="mamorio" />
              </div>
              <div className="mamorio-name">
                {device.name}
              </div>
            </div>
          </div>
          <div className="detail-view">
          {
            (recentLog) ? (
              <DetailDeviceMap
                detectionLogs={[{
                  latitude: recentLog.latitude,
                  longitude: recentLog.longitude,
                  detected_at: recentLog.at,
                }]}
                address={address}
                blueprints={group?.blueprint ? [group.blueprint] : []}
              />
            ) : (
              <div className="privacy-desc">
                一度も検知していません
              </div>
            )
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailDeviceModal
