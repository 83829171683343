import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
// @ts-ignore
import { unregister } from './registerServiceWorker'
import { store } from './store'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

// Mock Service Worker for development
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCK === 'true') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser')
  worker.start()
}

Bugsnag.start({
  apiKey: '05895bdb8e959222b9015ebadb5748f6',
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

ReactDOM.render(
  (
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  ),
  document.getElementById('root'),
)

unregister()
