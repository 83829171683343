import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import updateObjectInArray from './common/reducer_util'
import { requestStart, requestFinished } from './UI'
import { buildUrl, createRequest, normalizeQueryParams } from './common/httpUtils'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'
import { HOSTNAME } from '../constants/Constants'

export const SortOrderAsc = 'asc'
export const SortOrderDesc = 'desc'
export type SortOrder = typeof SortOrderAsc | typeof SortOrderDesc

export type Filter = {
  order?: SortOrder
  sort?: string
  isRent?: boolean | null
  name?: string
  memo?: string
  page?: number
  teamDomain?: string
  histories?: boolean | null
  maxMamorios?: boolean | null
}

type FilterForQueryParams = {
  order: SortOrder
  sort: string
  is_rent?: boolean | null
  name?: string
  memo?: string
  page?: number
}

interface IMamorioState {
  filter?: any
  mamorio?: IMamorio | null
  mamorios?: IMamorio[] | null
  currentPage: number
  totalPages: number
}

interface IGetMamorioAction {
  mamorios: IMamorio[]
  currentPage: number
  totalPages: number
}

interface IGetDevicesAction {
  devices: IDevice[]
  currentPage: number
  totalPages: number
}

interface IUpdateMamorioAction {
  mamorio: IMamorio
}

interface IRegisterMamorioAction {
  mamorio_id: number
  group_id: number
}

const transformFilterForQueryParams = (filter: Filter): FilterForQueryParams => normalizeQueryParams({
  order: filter.order,
  sort: filter.sort,
  is_rent: filter.isRent,
  name: filter.name,
  memo: filter.memo,
  page: filter.page,
  team_domain: filter.teamDomain,
  histories: filter.histories,
  max_mamorios: filter.maxMamorios,
}) as FilterForQueryParams

/**
 * グループの MAMORIO の取得
 */
export const getGroupMamorios = createAsyncThunk<
{
  mamorios: IMamorio[]
  currentPage: number
  totalPages: number
},
{
  groupId: GroupId
  filter: Filter
},
{
  rejectValue: string
}
>('mamorio/getGroupMamorios', async (arg, thunkApi) => {
  const { groupId, filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !filter) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams(transformFilterForQueryParams(filter)))
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/groups/${groupId}/group_mamorios?${queryParams}`), 'GET'),
    )

    const data = await response.json()

    const {
      mamorios,
      meta,
    }: {
      mamorios: IMamorio[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data

    if (response.status >= 400 || !mamorios) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      mamorios,
      totalPages: meta.total_pages,
      currentPage: meta.current_page,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export const getGroupMamoriosWithoutUpdatingState = createAsyncThunk<
{
  mamorios: IMamorio[]
  currentPage: number
  totalPages: number
},
{
  groupId: GroupId
  filter: Filter
},
{
  rejectValue: string
}
>('mamorio/getGroupMamoriosWithoutUpdatingState', async (arg, thunkApi) => {
  const { groupId, filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !filter) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams(transformFilterForQueryParams(filter)))
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/groups/${groupId}/group_mamorios?${queryParams}`), 'GET'),
    )

    const data = await response.json()

    const {
      mamorios,
      meta,
    }: {
      mamorios: IMamorio[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data

    if (response.status >= 400 || !mamorios) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      mamorios,
      totalPages: meta.total_pages,
      currentPage: meta.current_page,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * チームの MAMORIO の取得
 */
export const getTeamMamorios = createAsyncThunk<
{
  mamorios: IMamorio[]
  currentPage: number
  totalPages: number
},
{
  filter: Filter
},
{
  rejectValue: string
}
>('mamorio/getTeamMamorios', async (arg, thunkApi) => {
  const { filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!filter) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams(transformFilterForQueryParams(filter)))
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/team_mamorios?${queryParams}`), 'GET'),
    )

    const data = await response.json()

    const {
      mamorios,
      meta,
    }: {
      mamorios: IMamorio[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data

    if (response.status >= 400 || !mamorios) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      mamorios,
      totalPages: meta.total_pages,
      currentPage: meta.current_page,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export const getTeamMamoriosWithoutUpdatingState = createAsyncThunk<
{
  mamorios: IMamorio[]
},
{
  filter: Filter
},
{
  rejectValue: string
}
>('mamorio/getTeamMamoriosWithoutUpdatingState', async (arg, thunkApi) => {
  const { filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!filter) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams(transformFilterForQueryParams(filter)))
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/team_mamorios?${queryParams}`), 'GET'),
    )

    const data = await response.json()

    const {
      mamorios,
    }: {
      mamorios: IMamorio[]
    } = data

    if (response.status >= 400 || !mamorios) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      mamorios,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export const updateWithCSV = createAsyncThunk<
{ message: string },
{ file: File },
{ rejectValue: string }
>('mamorio/updateWithCSV', async (arg, thunkApi) => {
  const { file } = arg
  const { rejectWithValue } = thunkApi

  if (!file) return rejectWithValue('No file provided.')

  const formData = new FormData()
  formData.append('file', file)

  try {
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/team_mamorios/update_from_csv?team_domain=${HOSTNAME?.split('.')[0]}`), 'POST', true, null, formData),
    )

    const data = await response.json()
    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return { message: `送信に成功しました。 反映成功：${data.updated_mamorios_count}件、失敗：${data.failed_count}件` }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  }
})

/**
 * グループの端末の取得
 */
export const getGroupDevices = createAsyncThunk<
{
  devices: IDevice[]
  currentPage: number
  totalPages: number
},
{
  groupId: GroupId
  filter: Filter
},
{
  rejectValue: string
}>('mamorios/getGroupDevices', async (arg, thunkApi) => {
  const { groupId, filter } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!groupId || !filter) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const queryParams = new URLSearchParams(normalizeQueryParams(transformFilterForQueryParams(filter)))
    const response = await fetch(
      createRequest(buildUrl(`/api/v1/groups/${groupId}/group_devices?${queryParams}`), 'GET'),
    )

    const data = await response.json()

    const {
      devices,
      meta,
    }: {
      devices: IDevice[]
      meta: {
        total_pages: number
        current_page: number
      }
    } = data

    if (response.status >= 400 || !devices) {
      return rejectWithValue(data.error || response.statusText)
    }

    return {
      devices,
      totalPages: meta.total_pages,
      currentPage: meta.current_page,
    }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループの MAMORIO の取得
 */
export const getGroupMamorio = createAsyncThunk<
{
  mamorio: IMamorio
},
{
  mamorio_id: number | string,
  group_id: number,
  date?: string,
},
{
  rejectValue: string
}
>('mamorio/getGroupMamorio', async (arg, thunkApi) => {
  const { mamorio_id, group_id, date } = arg
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    const url = `/api/v1/groups/${group_id}/group_mamorios/${mamorio_id}${date ? `?date=${date}` : ''}`
    const response = await fetch(
      createRequest(buildUrl(url), 'GET'),
    )
    const data = await response.json()

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return { mamorio: data.mamorio as IMamorio }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * グループの MAMORIO のアップデート
 */
export const updateGroupMamorio = createAsyncThunk<
{
  mamorio: IMamorio
},
{
  mamorio: Pick<IMamorio, 'id' | 'group_id'>
  params: {
    image?: File
    name?: string
    memo?: string
    battery_life_resetted_at?: string
  }
},
{
  rejectValue: string
}
>('mamorio/updateGroupMamorio', async (arg, thunkApi) => {
  const { mamorio, params } = arg
  const {
    image, name, memo, battery_life_resetted_at,
  } = params
  const { dispatch, rejectWithValue } = thunkApi

  if (!image && !name && !memo && !battery_life_resetted_at) return rejectWithValue('')

  dispatch(requestStart())

  try {
    let body

    if (image) {
      body = new FormData()
      body.append('image', image)
    } else if (name) {
      body = { name, memo }
    } else if (battery_life_resetted_at) {
      body = { battery_life_resetted_at }
    } else {
      return rejectWithValue('')
    }

    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${mamorio.group_id}/group_mamorios/${mamorio.id}`),
        'PATCH',
        true,
        !image ? 'application/json' : undefined,
        body,
      ),
    )

    const data = await response.json()

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return { mamorio: data.mamorio as IMamorio }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * MAMORIOのグループの登録・変更
 */
export const registerGroupMamorio = createAsyncThunk<
{
  mamorio_id: DeviceId
  group_id: GroupId
},
{
  mamorio: Pick<IMamorio, 'id' | 'group_id'>
  groupId: GroupId
},
{
  rejectValue: string
}
>('mamorio/registerGroupMamorio', async (arg, thunkApi) => {
  const { mamorio, groupId } = arg
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    const response = await fetch(
      createRequest(
        buildUrl(`/api/v1/groups/${groupId}/group_mamorios/${mamorio.id}/register`),
        'POST',
        true,
        'application/json',
      ),
    )

    const data = await response.json()
    console.error('data ispppp ', data)

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return { mamorio_id: data.groups_mamorio.mamorio_id, group_id: data.groups_mamorio.group_id }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

const initialState = {
  mamorios: [],
  currentPage: 1,
  totalPages: 0,
} as IMamorioState

const mamorioSlice = createSlice({
  name: 'mamorio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupMamorios.fulfilled, (state, action: PayloadAction<IGetMamorioAction>): IMamorioState => ({
        ...state,
        mamorios: action.payload.mamorios,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      }))
      .addCase(getTeamMamorios.fulfilled, (state, action: PayloadAction<IGetMamorioAction>): IMamorioState => ({
        ...state,
        mamorios: action.payload.mamorios,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      }))
      .addCase(getGroupMamorios.rejected, (): IMamorioState => ({
        ...initialState,
        mamorios: null,
      }))
      .addCase(getGroupDevices.fulfilled, (state, action: PayloadAction<IGetDevicesAction>): IMamorioState => ({
        ...state,
        mamorios: action.payload.devices as IMamorio[],
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      }))
      .addCase(getGroupDevices.rejected, (): IMamorioState => ({
        ...initialState,
        mamorios: null,
      }))
      .addCase(registerGroupMamorio.fulfilled, (state, action: PayloadAction<IRegisterMamorioAction>) => {
        const { mamorio_id, group_id } = action.payload

        let mamorios: IMamorio[] = state.mamorios || []

        if (state.mamorios) {
          mamorios = updateObjectInArray(
            state.mamorios || [],
            (e) => e.id === mamorio_id,
            { group_id },
          )
        }

        return {
          ...state,
          mamorios,
        }
      })
      .addCase(updateGroupMamorio.fulfilled, (state, action: PayloadAction<IUpdateMamorioAction>) => {
        const { mamorio } = action.payload

        let mamorios: IMamorio[]

        if (state.mamorios) {
          mamorios = updateObjectInArray(
            state.mamorios || [],
            (e) => e.id === mamorio.id,
            { minsaga: mamorio.minsaga },
          )
        } else {
          mamorios = [mamorio]
        }

        return {
          ...state,
          mamorios,
        }
      })
  },
})

/**
 * チームの MAMORIO の取得
 */
export const getTeamMamorio = createAsyncThunk<
{
  mamorio: IMamorio
},
{
  mamorio_id: number | string,
  date?: string,
},
{
  rejectValue: string
}
>('mamorio/getTeamMamorio', async (arg, thunkApi) => {
  const { mamorio_id, date } = arg
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())
  try {
    const url = `/api/v1/team_mamorios/${mamorio_id}?team_domain=${HOSTNAME?.split('.')[0]}${date ? `&&date=${date}` : ''}`
    const response = await fetch(
      createRequest(buildUrl(url), 'GET'),
    )
    const data = await response.json()

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return { mamorio: data.mamorio as IMamorio }
  } catch (error: unknown) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

export default mamorioSlice.reducer
