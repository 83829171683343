import {
  createAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
  PayloadAction,
} from '@reduxjs/toolkit'
import { saveSession, removeSession } from './common/session'
import { requestStart, requestFinished } from './UI'
import { buildUrl, createRequest } from './common/httpUtils'
import { HOSTNAME } from '../constants/Constants'
import { retrieveResponseErrorMessage } from '../utils/errorUtils'

// Type definitions ---------------------------------
interface ILoginResponse {
  accessToken: string
  rootGroupId: number
  mfaStatus?: string
}

interface IUserState {
  user: IUser | null
  error?: any
  testStatus?: boolean
}
// --------------------------------------------------

// Action creators ---------------------------------
const successLogout = createAction('user/successLogout')

/**
 * ログイン /users/sign_in_office.json
 * @param email
 * @param password
 */
export const login = createAsyncThunk<
ILoginResponse,
{
  email: string
  password: string
},
{
  rejectValue: string
}
>('user/login', async (arg, thunkApi) => {
  const { email, password } = arg
  const { dispatch, rejectWithValue } = thunkApi

  if (!email || !password) return rejectWithValue('')

  dispatch(requestStart())

  try {
    const response = await fetch(createRequest(
      buildUrl('/users/sign_in_office.json'),
      'POST',
      false,
      'application/json',
      {
        user: {
          email,
          password,
          hostname: HOSTNAME,
        },
      },
    ))

    const data = await response.json()
    const { access_token, root_group_id, mfa_status } = data

    if (response.status >= 400 || !access_token || !root_group_id) {
      return rejectWithValue(data.error || response.statusText)
    }

    saveSession(access_token, root_group_id)

    return {
      accessToken: access_token,
      rootGroupId: root_group_id,
      mfaStatus: mfa_status,
    }
  } catch (error: any) {
    if (error.response) {
      return rejectWithValue(error.response.body.error)
    }

    return rejectWithValue(error.toString())
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * ログアウト
 */
export const logout = () => (dispatch: Dispatch) => {
  removeSession()

  dispatch(successLogout())
}

/**
 * ユーザーの取得
 */
export const getUser = createAsyncThunk<
IUser,
undefined,
{
  rejectValue: string
}
>('user/get', async (_, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    const response = await fetch(createRequest(buildUrl(`/api/v1/users/me?hostname=${HOSTNAME}`), 'GET'))
    const data = await response.json()
    const { user } = data

    if (response.status >= 400) {
      return rejectWithValue(data.error || response.statusText)
    }

    return user
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

/**
 * ユーザー情報の更新
 */
export const updateUser = createAsyncThunk<
IUser,
{
  name?: Nullable<string>
  password?: Nullable<string>
  read_news_at?: Date
  image?: File
},
{
  rejectValue: string
}
>('user/update', async (arg, thunkApi) => {
  const { dispatch, rejectWithValue } = thunkApi

  dispatch(requestStart())

  try {
    let response: any

    if (arg.image) {
      const formData = new FormData()
      formData.append('hostname', HOSTNAME)
      formData.append('image', arg.image)

      response = await fetch(
        createRequest(
          buildUrl(
            '/api/v1/users/me',
          ),
          'PATCH',
          true,
          undefined,
          formData,
        ),
      )
    } else {
      const { name, password, read_news_at } = arg
      const body: { name?: string, password?: string, read_news_at?: Date } = {}
      if (name) body.name = name
      if (password) body.password = password
      if (read_news_at) body.read_news_at = read_news_at

      response = await fetch(
        createRequest(
          buildUrl(
            '/api/v1/users/me',
          ),
          'PATCH',
          true,
          'application/json',
          {
            hostname: HOSTNAME,
            user: body,
          },
        ),
      )
    }

    const data = await response.json()
    const { user } = data

    if (data.status >= 400 || !user) {
      return rejectWithValue(data.error || data.statusText)
    }

    return user
  } catch (error: any) {
    return rejectWithValue(retrieveResponseErrorMessage(error))
  } finally {
    dispatch(requestFinished())
  }
})

const initialState = {
  user: {
    id: NaN,
    image: {
      url: '',
    },
    name: '',
    email: '',
    admin: false,
  },
  error: null,
} as IUserState

/**
 * userSlice
 */
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(successLogout, (state) => {
        if (state.error) {
          return state
        }

        return initialState
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<IUser>) => {
        if (state.error) {
          return state
        }

        return {
          user: action.payload,
          error: null,
        }
      })
      .addCase(updateUser.fulfilled, (state, action: PayloadAction<IUser>) => {
        if (state.error) {
          return state
        }

        return {
          user: action.payload,
          error: null,
        }
      })
      .addDefaultCase((state) => state)
  },
})

export default userSlice.reducer
export const userSliceActionKeys = Object.keys(userSlice.actions).map((key) => `user/${key}`)
