import React from 'react'

const AddMamorioInquiryInfo = () => (
  <p className="message">
    MAMORIOを追加したい際は
    <a target="blank" href="https://mamorio.typeform.com/to/dwak6v">
      MAMORIOまでお問い合わせ
    </a>
    ください。
  </p>
)

export default AddMamorioInquiryInfo
