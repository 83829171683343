import React from 'react'
import { defaultUserIcon } from '../../../icons'

type Props = {
  member: Pick<IUser, 'email' | 'id' | 'name' | 'image' | 'approved' | 'admin'>
  onEditUser: (user: any) => void
  onRemoveUser: (user: any) => void
}

const GroupMemberListItem = ({
  member,
  onEditUser,
  onRemoveUser,
}: Props) => (
  <div className="group-user-cell">
    <div className="group-user">
      <img src={(member.image?.url && member.image?.url !== 'null') ? member.image?.url : defaultUserIcon} className="group-user-settins-image" alt="users" />
      <div className="user-mail">
        {member.name ? member.name : member.email}
      </div>
      {
        member.admin && (
          <div className="admin-label">
            管理者
          </div>
        )
      }
      {
        (!member.approved) ? (
          <>
            <button
              type="button"
              className="btn btn-danger btn-sm desktop cancel-invite-user-button"
              onClick={() => onRemoveUser(member)}
            >
              招待を取り消し
            </button>
            <button
              type="button"
              className="btn btn-danger btn-sm mobile cancel-invite-user-button"
              onClick={() => onRemoveUser(member)}
            >
              取り消し
            </button>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-primary btn-sm edit-user-button"
            onClick={() => onEditUser(member)}
          >
            設定
          </button>
        )
      }
    </div>
  </div>
)

export default GroupMemberListItem
