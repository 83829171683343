import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { attention } from '../../icons'
import './ConfirmModal.css'

type Props = {
  closeModalFunction: (arg: unknown | void) => void
  modalProps: {
    message: string
    confirmButtonText: string
    onActionConfirmed: (arg: unknown | void) => void
  }
}

const ConfirmModal = ({
  closeModalFunction,
  modalProps,
}: Props) => (
  <div className="confirm-modal-background">
    <div className="confirm-modal-container">
      <div className="modal-attention-icon">
        <img src={attention} alt="attention" />
      </div>
      <div className="modal-message">
        {
          parse(modalProps.message)
        }
      </div>
      <div className="delete-group-button">
        <button
          type="button"
          className="btn btn-danger"
          onClick={modalProps.onActionConfirmed}
        >
          {modalProps.confirmButtonText}
        </button>
      </div>
      <div className="cancel-delete-button">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={closeModalFunction}
        >
          キャンセル
        </button>
      </div>
    </div>
  </div>
)

ConfirmModal.propTypes = {
  closeModalFunction: PropTypes.func.isRequired,
  modalProps: PropTypes.shape({
    confirmButtonText: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onActionConfirmed: PropTypes.func.isRequired,
  }).isRequired,
}

export default ConfirmModal
