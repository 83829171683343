/* eslint-disable */
// @ts-nocheck 

const maxLat = 34.657883;
const minLat = 34.643621;
const minLng = 135.379669;
const maxLng = 135.397399;


const maxLatAkita = 39.290;
const minLatAkita = 39.266;
const maxLngAkita = 140.643;
const minLngAkita = 140.589;

function randomLatLng(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export const mockMamoriosAkita: IMamorio[] = Array.from({ length: 100 }, (_, id) => {
  let previousLat = randomLatLng(minLatAkita, maxLatAkita);
  let previousLng = randomLatLng(minLngAkita, maxLngAkita);
  const region_histories: IDetectionLog[] = Array.from({ length: 10 }, (_, i) => {
    const date = new Date(Date.now() - i * 10 * 60000);
  
    // Ensure the new location is within the specified range from the previous location
    let newLat = previousLat + (Math.random() - 0.5) * 0.0003 * 5; // ±0.015 range
    let newLng = previousLng + (Math.random() - 0.5) * 0.0005 * 5; // ±0.025 range
  
    // Make sure the new latitude and longitude are within the min/max boundaries
    newLat = Math.max(minLatAkita, Math.min(maxLatAkita, newLat));
    newLng = Math.max(minLngAkita, Math.min(maxLngAkita, newLng));
  
    // Update the previous latitude and longitude
    previousLat = newLat;
    previousLng = newLng;
  
    return {
      detected_at: date.toISOString(),
      latitude: newLat.toString(),
      longitude: newLng.toString(),
      rssi: -30 - Math.random() * 70,
      location: "",
      precision: Math.random() * 100,
      status: "enter",
    };
  });

  const recent_log: IRecentDetectionLog = {
    at: region_histories[0].detected_at,
    finder: null,
    group: null,
    id: id as any,
    rssi: region_histories[0].rssi,
    latitude: region_histories[0].latitude,
    longitude: region_histories[0].longitude,
    precision: region_histories[0]?.precision,
  };

  let name = "MAMORIO"
  let imageUrl = ''

  if (id < 20) {
    name = `発電機${id + 1}号機`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566486/retina_9b0aac77-c001-4888-8738-493d9939775a.jpg'
  } else if (id < 40) {
    name = `立馬${id - 20 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566487/retina_121aa699-5a9f-47fa-bb0e-36383f51622d.jpeg'
  } else if (id < 60) { 
    name = `水中ポンプ${id - 40 + 1}号機`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566488/retina_185dfba2-0c96-420a-af85-30b376fbe5fb.jpg'
  } else if (id < 80) {
    name = `投光器${id - 60 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566489/retina_46a7d49a-389e-4458-a560-07e1b73229e5.jpg'
  } else {
    name = `立馬${id - 80 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566490/retina_208dc57a-d798-4df5-bbb0-90d1c41f3d8b.jpg'
  }

  return {
    id: id as any,
    image: { url: imageUrl, retina: { url: imageUrl } },
    name: name,
    is_rent: false,
    minsaga: false,
    estimated_remain_battery: Math.random() * 100,
    recent_log: recent_log,
    region_histories: region_histories,
    status: Math.random() < 0.5 ? "lost" : "verified",
    memo: "",
    user: { id: 1 as any, name: "MAMORIO管理者", email: "maintenance@mamorio.jp"},
    group_id: 1 as any,  // This is a dummy value and should be replaced by a real GroupId.
  };
});

export const mockMamorios: IMamorio[] = Array.from({ length: 100 }, (_, id) => {
  let previousLat = randomLatLng(minLat, maxLat);
  let previousLng = randomLatLng(minLng, maxLng);
  const region_histories: IDetectionLog[] = Array.from({ length: 10 }, (_, i) => {
    const date = new Date(Date.now() - i * 10 * 60000);
  
    // Ensure the new location is within the specified range from the previous location
    let newLat = previousLat + (Math.random() - 0.5) * 0.0003 * 5; // ±0.015 range
    let newLng = previousLng + (Math.random() - 0.5) * 0.0005 * 5; // ±0.025 range
  
    // Make sure the new latitude and longitude are within the min/max boundaries
    newLat = Math.max(minLat, Math.min(maxLat, newLat));
    newLng = Math.max(minLng, Math.min(maxLng, newLng));
  
    // Update the previous latitude and longitude
    previousLat = newLat;
    previousLng = newLng;
  
    return {
      detected_at: date.toISOString(),
      latitude: newLat.toString(),
      longitude: newLng.toString(),
      rssi: -30 - Math.random() * 70,
      location: "",
      precision: Math.random() * 100,
      status: "enter",
    };
  });

  const recent_log: IRecentDetectionLog = {
    at: region_histories[0].detected_at,
    finder: null,
    group: null,
    id: id as any,
    rssi: region_histories[0].rssi,
    latitude: region_histories[0].latitude,
    longitude: region_histories[0].longitude,
    precision: region_histories[0]?.precision,
  };

  let name = "MAMORIO"
  let imageUrl = ''

  if (id < 20) {
    name = `発電機${id + 1}号機`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566486/retina_9b0aac77-c001-4888-8738-493d9939775a.jpg'
  } else if (id < 40) {
    name = `立馬${id - 20 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566487/retina_121aa699-5a9f-47fa-bb0e-36383f51622d.jpeg'
  } else if (id < 60) { 
    name = `水中ポンプ${id - 40 + 1}号機`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566488/retina_185dfba2-0c96-420a-af85-30b376fbe5fb.jpg'
  } else if (id < 80) {
    name = `投光器${id - 60 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566489/retina_46a7d49a-389e-4458-a560-07e1b73229e5.jpg'
  } else {
    name = `立馬${id - 80 + 1}`
    imageUrl = 'https://mamorio-server-assets.s3.amazonaws.com/uploads/mamorio/image/566490/retina_208dc57a-d798-4df5-bbb0-90d1c41f3d8b.jpg'
  }

  return {
    id: id as any,
    image: { url: imageUrl, retina: { url: imageUrl } },
    name: name,
    is_rent: false,
    minsaga: false,
    estimated_remain_battery: Math.random() * 100,
    recent_log: recent_log,
    region_histories: region_histories,
    status: Math.random() < 0.5 ? "lost" : "verified",
    memo: "",
    user: { id: 1 as any, name: "MAMORIO管理者", email: "maintenance@mamorio.jp"},
    group_id: 1 as any,  // This is a dummy value and should be replaced by a real GroupId.
  };
});
