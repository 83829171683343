import { isMobile } from '../utils/commonUtils'
import {
  SETTINGS_EDIT_MAMORIO, SETTINGS_GROUP_MANAGEMENT, SETTINGS_MAMORIO_SPOT,
} from './Paths'

export interface SettingTabContent {
  label: string
  url: string
}

const SettingTabsInfo = (): SettingTabContent[] => {
  if (isMobile()) {
    return [
      {
        label: 'MAMORIO',
        url: SETTINGS_EDIT_MAMORIO,
      },
      {
        label: 'グループ',
        url: SETTINGS_GROUP_MANAGEMENT,
      },
      {
        label: 'Spot',
        url: SETTINGS_MAMORIO_SPOT,
      },
    ]
  }

  return [
    {
      label: 'MAMORIOの設定',
      url: SETTINGS_EDIT_MAMORIO,
    },
    {
      label: 'グループの管理',
      url: SETTINGS_GROUP_MANAGEMENT,
    },
    {
      label: 'MAMORIO Spotの管理',
      url: SETTINGS_MAMORIO_SPOT,
    },
  ]
}

export default SettingTabsInfo
