import React, { useState, useEffect } from 'react'
import Geocode from 'react-geocode'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { parseAsync } from 'json2csv'
import DetailMamorioMap from './DetailMamorioMap'
import {
  getMamorioImageSrc,
  getRentalStatus,
} from '../../helpers/CommonMamorioHelper'
import { closeIcon, iconDots } from '../../icons'
import './DetailModalCommon.css'
import './DetailModal.css'
import { getFromNowAsText, getFullDate, getFormattedDate } from '../../helpers/timeHelper'
import { getGroupMamorio } from '../../modules/Mamorio'
import { useAppDispatch } from '../../hooks'
import { getErrorString } from '../../utils/errorUtils'
import Loading from '../common/Loading'
import { getGroup } from '../../modules/Group'
import { toId } from '../../utils/commonUtils'

type Props = {
  closeModalFunction: (arg: any | void) => void
  modalProps: {
    mamorio: IMamorio
    group?: IGroup
    onRentalForceReturnClick?: (arg: any) => void
    onClickResetBattery?: (arg: any) => void
  }
}
Geocode.setLanguage('ja')
const handleBlob = (data: BlobPart[], options: BlobPropertyBag, callback: (url: string) => void) => {
  const url = URL.createObjectURL(new Blob(data, options))
  callback(url)
  URL.revokeObjectURL(url)
}

const DetailMamorioModal = ({
  closeModalFunction,
  modalProps,
}: Props) => {
  const dispatch = useAppDispatch()
  const { mamorio } = modalProps
  const [group, setGroup] = useState<IGroup | undefined>(modalProps.group)
  const recentLog = mamorio?.recent_log
  const recentDetectionLog = recentLog ? {
    detected_at: recentLog!.at,
    latitude: recentLog!.latitude,
    longitude: recentLog!.longitude,
    status: 'enter',
    location: '',
  } : undefined
  const [regionHistories, setRegionHistories] = useState<IDetectionLog[]>([])
  const [detectionLogs, setDetectionLogs] = useState<IDetectionLog[]>(recentDetectionLog ? [recentDetectionLog] : [])
  const [date, setDate] = useState<string>(mamorio.recent_log ? getFormattedDate(mamorio.recent_log.at, 'YYYY-MM-DD') : getFormattedDate(new Date(), 'YYYY-MM-DD'))
  const [isLoading, setIsLoading] = useState(false)
  const [isRent, setIsRent] = useState(modalProps.mamorio.is_rent)
  const [batteryLifeResettedAt, setBatteryLifeResettedAt] = useState(modalProps.mamorio.battery_life_resetted_at!)
  const blueprints = group?.blueprint ? [group.blueprint] : []
  const genereateSortedLogs = (_regionHistories: IDetectionLog[], dateStr: String) => {
    if (!recentLog) {
      return []
    }

    const transformedRecentLog = {
      detected_at: recentLog!.at,
      latitude: recentLog!.latitude,
      longitude: recentLog!.longitude,
    } as IDetectionLog

    const isToday = dateStr === getFormattedDate(new Date(), 'YYYY-MM-DD')
    const initialLogs = (isToday || _regionHistories.length === 0) ? [transformedRecentLog].concat(_regionHistories) : _regionHistories
    // 履歴の重複を排除して降順にソート
    const sortedLogs = initialLogs.reduce((result: IDetectionLog[], current) => {
      if (!result.find((item) => item.detected_at === current.detected_at)) {
        result.push(current)
      }
      return result
    }, []).sort((a, b) => new Date(b.detected_at).getTime() - new Date(a.detected_at).getTime())
    return sortedLogs
  }

  const onRentalForceCompleted = () => {
    setIsRent(false)
  }

  const onResetBatteryCompleted = () => {
    setBatteryLifeResettedAt(getFullDate(new Date()))
  }

  const onRentalForceReturnClick = () => {
    if (modalProps.onRentalForceReturnClick) {
      modalProps.onRentalForceReturnClick(onRentalForceCompleted)
    }
  }

  const onClickResetBattery = () => {
    if (modalProps.onClickResetBattery) {
      modalProps.onClickResetBattery(onResetBatteryCompleted)
    }
  }

  const updateMamorioLogs = (dateStr: string) => {
    setDate(dateStr)
    const updatedMamorioLogs = async () => {
      try {
        const updatedMamorio = await dispatch(getGroupMamorio({ mamorio_id: mamorio.id, group_id: mamorio.group_id, date: dateStr })).unwrap()
        setRegionHistories(updatedMamorio.mamorio.region_histories)
        setDetectionLogs(genereateSortedLogs(updatedMamorio.mamorio.region_histories, dateStr))
      } catch (error: unknown) {
        // TODO: 例外処理
        console.error(getErrorString(error))
      } finally {
        setIsLoading(false)
      }
    }
    setIsLoading(true)
    updatedMamorioLogs()
  }

  const downloadLogs = async () => {
    let formattedData = regionHistories
    if (date === getFormattedDate(recentLog?.at, 'YYYY-MM-DD')) {
      formattedData = genereateSortedLogs(regionHistories, date)
    }

    const csv = await parseAsync(formattedData, {
      fields: [
        {
          label: 'name',
          value: () => mamorio.name,
        },
        {
          label: 'latitude',
          value: 'latitude',
        },
        {
          label: 'longitude',
          value: 'longitude',
        },
        {
          label: 'precision',
          value: 'precision',
        },
        {
          label: 'detected_at',
          value: 'detected_at',
        },
      ],
      withBOM: true,
    })
    handleBlob([`${csv}\n`], { type: 'text/csv' }, (url) => {
      setIsLoading(false)
      const a = document.createElement('a')
      a.href = url
      a.download = date ? `logs_${date}.csv` : `logs_${getFormattedDate(recentLog?.at, 'YYYY-MM-DD')}.csv`
      a.click()
    })
  }

  //  ページロード時に一度だけ実行
  useEffect(() => {
    const updatedMamorioLogs = async () => {
      try {
        const updatedMamorio = await dispatch(getGroupMamorio({ mamorio_id: mamorio.id, group_id: mamorio.group_id })).unwrap()
        setRegionHistories(updatedMamorio.mamorio.region_histories)
        setDetectionLogs(genereateSortedLogs(updatedMamorio.mamorio.region_histories, getFormattedDate(new Date(), 'YYYY-MM-DD')))
      } catch (error: unknown) {
        // TODO: 例外処理
        console.error(getErrorString(error))
      } finally {
        setIsLoading(false)
      }
    }
    setIsLoading(true)
    updatedMamorioLogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mamorio.group_id) {
      return
    }

    if (group) {
      return
    }

    const fetchGroup = async () => {
      try {
        const response = await dispatch(getGroup({
          groupId: toId(mamorio.group_id),
        })).unwrap()
        setGroup(response)
      } catch (error) {
        console.error('Failed to fetch mamorio:', error)
        setGroup(undefined)
      }
    }
    fetchGroup()
  }, [dispatch, mamorio.group_id, group])

  return (
    <div className="detail-modal-container" data-testid="detail-modal">
      <Loading show={isLoading} />
      <div
        className="overlay"
        onClick={closeModalFunction}
      />
      <div className="detail-base">
        <div className="scrollable">
          <div className="title-row">
            <div className="detail-title">
              詳細
            </div>
            <div>
              {(isRent || modalProps.onClickResetBattery) && (
                <UncontrolledDropdown nav inNavbar className="uncontrolled-dropdown">
                  <DropdownToggle nav caret>
                    <button type="button">
                      <img
                        src={iconDots}
                        alt="rental-menu"
                      />
                    </button>
                  </DropdownToggle>
                  <DropdownMenu>
                    { isRent && (
                      <DropdownItem
                        onClick={onRentalForceReturnClick}
                      >
                        強制的に返却する
                      </DropdownItem>
                    )}
                    { modalProps.onClickResetBattery && (
                      <DropdownItem
                        onClick={onClickResetBattery}
                      >
                        バッテリー交換を反映する
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <button
                onClick={closeModalFunction}
                className="close-button"
                type="button"
              >
                <img
                  src={closeIcon}
                  alt="close-button"
                />
              </button>
            </div>
          </div>
          <div className="detail-modal-content">
            <div className="main-view">
              <div className="icon-wrapper">
                <img className="mamorio-icon" src={getMamorioImageSrc(mamorio)} alt="mamorio" />
              </div>
              <div className="mamorio-name">
                {mamorio.name}
              </div>
              <div style={{ marginTop: '15px' }}>
                <a className="item-majorminor-text" target="_blank" rel="noreferrer" href={`/mamorios/${mamorio.major}-${mamorio.minor}`} style={{ fontSize: '12px', color: '#757575' }}>
                  {`${mamorio.major}-${mamorio.minor}`}
                </a>
              </div>
              {(batteryLifeResettedAt) && (
                  <div className="mamorio-battery">
                    <span className="mamorio-battery-title">バッテリー交換日</span>
                    <span>
                      {getFromNowAsText(batteryLifeResettedAt)}
                    </span>
                  </div>
              )}
              {isRent && (
                <div className="rental-area">
                  {getRentalStatus(mamorio)}
                </div>
              )}
            </div>
          </div>
          <div className="detail-view">
            {
              (mamorio.recent_log) ? (
                <DetailMamorioMap
                  date={date}
                  detectionLogs={detectionLogs}
                  updateMamorioLogs={updateMamorioLogs}
                  downloadLogs={downloadLogs}
                  blueprints={blueprints}
                />
              ) : (
                <div className="privacy-desc">
                  一度も検知していません
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailMamorioModal
