import PropTypes from 'prop-types'
import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import Geocode from 'react-geocode'
import { getFromNowAsText, getFormattedDate } from '../../helpers/timeHelper'
import GoogleMapWrapperForBizPlusTest from './GoogleMapWrapperForBizPlusTest'
import { firstMapPinIcon } from '../../icons'
import { showCalender } from '../../modules/common/session'
import '../overview/DetailMamorioMap.css'

type Props = {
  date: string
  detectionLogs: IDetectionLog[]
  updateMamorioLogs: (arg: string) => void
  downloadLogs: () => void
}

const DetailMamorioMapForBizPlusTest = ({
  date,
  detectionLogs,
  updateMamorioLogs,
  downloadLogs,
}: Props) => {
  const [address, setAddress] = useState('')
  const requestAddress = (lat: string, lng: string) => {
    Geocode.fromLatLng(lat, lng)
      .then(
        (response: any) => {
          setAddress(`${response.results[0].formatted_address}付近`)
        },
        () => {
          setAddress('住所の取得に失敗しました。')
        },
      )
  }
  const [sliderValue, setSliderValue] = useState(Math.max(...detectionLogs.map((log) => new Date(log.detected_at).getTime())))
  const { minTimestamp, maxTimestamp } = useMemo(() => {
    const timestamps = detectionLogs.map((log) => new Date(log.detected_at).getTime())
    const min = Math.min(...timestamps)
    const max = Math.max(...timestamps)
    return { minTimestamp: min, maxTimestamp: max }
  }, [detectionLogs])
  const handleSliderChange = (event:any) => {
    setSliderValue(event.target.value)
  }

  const sliderLabel = useMemo(() => {
    const selectedTimestamp = detectionLogs.find((log) => new Date(log.detected_at).getTime() <= sliderValue)
    return selectedTimestamp ? getFromNowAsText(selectedTimestamp.detected_at) : ''
  }, [detectionLogs, sliderValue])

  const filteredDetectionLogs = useMemo(() => detectionLogs.filter((log) => new Date(log.detected_at).getTime() <= sliderValue), [detectionLogs, sliderValue])

  const sliderPercentage = useCallback(() => Math.min(((sliderValue - minTimestamp) / (maxTimestamp - minTimestamp)) * 100, 88), [sliderValue, minTimestamp, maxTimestamp])

  if (address?.length === 0) {
    if (detectionLogs[0]) {
      requestAddress(detectionLogs[0]!.latitude, detectionLogs[0]!.longitude)
    }
  }

  return (
    <div className="map-view">
      <div className="info-box">
        <div className="found-at">
          <div className="address">
            {address}
          </div>
        </div>
        <div className="detected-at">
          {getFromNowAsText(detectionLogs[0]?.detected_at)}
        </div>
      </div>
      <div className="map" style={{ height: '360px', marginBottom: '20px' }}>
        <GoogleMapWrapperForBizPlusTest
          containerStyle={{ height: '360px' }}
          zoom={18}
          detectionLogs={filteredDetectionLogs}
          mapPinIconSrc={firstMapPinIcon}
        />
      </div>
      <div className="slider-container" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
        <input
          type="range"
          min={minTimestamp}
          max={maxTimestamp}
          value={sliderValue}
          onChange={handleSliderChange}
          style={{ maxWidth: '100%', padding: '0px' }}
          className="time-slider"
        />
        <div className="slider-label" style={{ marginLeft: `calc(${sliderPercentage()}%)` }}>
          {sliderLabel}
        </div>
      </div>
      {
        showCalender() && (
          <div className="date-conatiner">
            <label>日付</label>
            <input className="date" defaultValue={date} type="date" onChange={(e) => updateMamorioLogs(e.target.value)} />
            {
              detectionLogs.length > 1 || date === getFormattedDate(detectionLogs[0]?.detected_at, 'YYYY-MM-DD') ? (
                <button type="button" className="btn btn-primary btn-sm" onClick={downloadLogs}>
                  CSVをダウンロード(
                  {detectionLogs.length}
                  件)
                </button>
              ) : (
                <button type="button" disabled className="btn btn-primary btn-sm">
                  ログがありません
                </button>
              )
            }
          </div>
        )
      }
    </div>
  )
}

DetailMamorioMapForBizPlusTest.propTypes = {
  date: PropTypes.string.isRequired,
  detectionLogs: PropTypes.arrayOf(
    PropTypes.shape({
      detected_at: PropTypes.string.isRequired,
    }),
  ).isRequired,
  updateMamorioLogs: PropTypes.func.isRequired,
  downloadLogs: PropTypes.func.isRequired,
}

export default DetailMamorioMapForBizPlusTest
