import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { parseAsync } from 'json2csv'
import ReactDOMServer from 'react-dom/server'
import { getRentals } from '../../../modules/Rental'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import Loading from '../../common/Loading'
import { getErrorString } from '../../../utils/errorUtils'
import { getFormattedDate } from '../../../helpers/timeHelper'
import { GROUP_SHAPE } from '../../../constants/Shapes'

const enum InputName {
  StartDate = 'gtEq',
  EndDate = 'ltEq',
  Format = 'format',
}

const handleBlob = (data: BlobPart[], options: BlobPropertyBag, callback: (url: string) => void) => {
  const url = URL.createObjectURL(new Blob(data, options))
  callback(url)
  URL.revokeObjectURL(url)
}

const GetRentalsModal = ({
  group,
  closeModalFunction,
}: { group: IGroup, closeModalFunction: VoidFunction }) => {
  const today = new Date()
  const year = today.getFullYear()
  const month = today.getMonth()
  const offset = today.getTimezoneOffset() / -60

  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.ui.loading)

  const [rentalStartDate, setRentalStartDate] = useState(new Date(year, month, 1, offset).toISOString().substring(0, 10))
  const [rentalEndDate, setRentalEndDate] = useState(new Date(year, month + 1, 0, offset).toISOString().substring(0, 10))
  const [format, setFormat] = useState('csv')

  const saveRentals = async () => {
    if (!group) return

    const dateGtEq = new Date(rentalStartDate)
    const dateLtEq = new Date(rentalEndDate)

    dateGtEq.setHours(0)
    dateLtEq.setHours(23, 59, 59, 999)

    try {
      const response = await dispatch(getRentals({
        groupId: group.id,
        rentalStartDate: dateGtEq,
        rentalEndDate: dateLtEq,
      })).unwrap()

      const now = new Date()
      const pad = (n: number) => (n < 10 ? `0${n}` : n)

      const yyyyMmDdHhMm = `${now.getFullYear()
        .toString()
      }${pad(now.getMonth() + 1)
      }${pad(now.getDate())
      }${pad(now.getHours())
      }${pad(now.getMinutes())
      }`

      if (format === 'csv') {
        const csv = await parseAsync(response, {
          fields: [
            {
              label: '持出日時',
              value: (row: IRental) => getFormattedDate(row.created_at),
            },
            {
              label: '持出者',
              value: 'user.name',
            },
            {
              label: 'メールアドレス',
              value: 'user.email',
            },
            {
              label: 'mamorio名称',
              value: 'name',
            },
            {
              label: '持出元',
              value: 'group.name',
            },
            {
              label: '返却日時',
              value: (row: IRental) => (
                row.deleted_at ? getFormattedDate(row.deleted_at) : ''
              ),
            },
          ],
          withBOM: true,
        })

        handleBlob([`${csv}\n`], { type: 'text/csv' }, (url) => {
          const a = document.createElement('a')
          a.href = url
          a.download = `${group.name}_${yyyyMmDdHhMm}.csv`
          a.click()
        })
      } else if (format === 'report') {
        const table = ReactDOMServer.renderToString(
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">持出日時</th>
              <th scope="col">
                持出者
                <br />
                (メールアドレス)
              </th>
              <th scope="col">mamorio名称</th>
              <th scope="col">
                持出元
                <br />
                (チームorグループ名)
              </th>
              <th scope="col">返却日時</th>
              <th scope="col">確認欄</th>
              <th scope="col">備考</th>
            </tr>
            </thead>
            <tbody>
            {response.map((row, i) => (
              <tr key={`${Date.now()}${row.id}`}>
                <th scope="row">{i + 1}</th>
                <td>{getFormattedDate(row.created_at)}</td>
                <td>
                  {row.user.name}
                  <br />
                  (
                  {row.user.email}
                  )
                </td>
                <td>{row.name}</td>
                <td>{row.group.name}</td>
                <td>{row.deleted_at ? getFormattedDate(row.deleted_at) : ''}</td>
                <td>{}</td>
                <td>{}</td>
              </tr>
            ))}
            </tbody>
          </table>,
        )

        handleBlob(
          [`<!DOCTYPE html><html><head><meta charset="utf-8"/><link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" crossorigin="anonymous" /><title>${
            group.name
          }_${
            yyyyMmDdHhMm
          }</title></head><body>${
            table
          }</body></html>`],
          { type: 'text/html' },
          (url) => { window.open(url) },
        )
      }
    } catch (e: unknown) {
      throw new Error(getErrorString(e))
    }
  }

  return (
    <div className="modal-form">
      <Loading show={isLoading} />
      <div>
        <label>開始日*</label>
        <input
          type="date"
          name={InputName.StartDate}
          value={rentalStartDate}
          onChange={(e) => setRentalStartDate(e.target.value)}
        />
      </div>
      <div>
        <label>終了日*</label>
        <input
          type="date"
          name={InputName.EndDate}
          value={rentalEndDate}
          onChange={(e) => setRentalEndDate(e.target.value)}
        />
      </div>
      <div>
        <label>フォーマット*</label>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id="input-format-csv"
            name={InputName.Format}
            value="csv"
            checked={format === 'csv'}
            onChange={(e) => setFormat(e.target.value)}
          />
          <label className="form-check-label" htmlFor="input-format-csv">CSV保存</label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id="input-format-report"
            name={InputName.Format}
            value="report"
            checked={format === 'report'}
            onChange={(e) => setFormat(e.target.value)}
          />
          <label className="form-check-label" htmlFor="input-format-report">帳票表示</label>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        onClick={saveRentals}
      >
        出力
      </button>
      <button
        type="button"
        className="btn btn-outline-primary button-cancel"
        onClick={() => {
          if (closeModalFunction) closeModalFunction()
        }}
      >
        キャンセル
      </button>
    </div>
  )
}

GetRentalsModal.defaultProps = {
  closeModalFunction: null,
}

GetRentalsModal.propTypes = {
  closeModalFunction: PropTypes.func,
  group: GROUP_SHAPE.isRequired,
}

export default GetRentalsModal
