import React, { useState } from 'react'
import {
  defaultItemIcon,
} from '../../../icons'
import { getFromNowAsText } from '../../../helpers/timeHelper'
import {
  getUserName,
  getLastStatus,
  getBatteryImageSrc,
  getLostFoundStatus,
  getMamorioImageSrc,
} from '../../../helpers/CommonMamorioHelper'
import EmptyListView from './EmptyMamorioList'
import { showLostAndBattery, showMemo } from '../../../modules/common/session'
import DetailMamorioModal from '../../overview/DetailMamorioModal'

type Props = {
  group: IGroup
  mamorios: IMamorio[] | undefined | null
}

const MamorioListOnHeatmap = ({
  group,
  mamorios,
}: Props) => {
  const [selectedMamorio, setSelectedMamorio] = useState<IMamorio | null>(null)
  const closeModal = () => {
    setSelectedMamorio(null)
  }

  if (!mamorios || mamorios.length === 0) {
    return (<EmptyListView />)
  }

  const cursor = 'pointer'

  return (
    <div className="mamorio-settings">
      <table className="table">
        <thead className="header" data-testid="mamorio-item-list-header">
          <tr>
            <th>
              <div
                className="title"
              >
                MAMORIOの名前
              </div>
            </th>
            {showMemo() && (
              <th>
                <div
                  className="title"
                  style={{ cursor, minWidth: '40px' }}
                >
                  メモ
                </div>
              </th>
            )}
            <th>
              <div
                className="title"
                style={{ cursor }}
              >
                最終検知時刻
              </div>
            </th>
            <th>
              <div className="title">
                最後に検知した端末
              </div>
            </th>
            <th>
              <div
                className="title"
                style={{ cursor, minWidth: '75px' }}
              >
                バッテリー
              </div>
            </th>
            {showLostAndBattery() && (
              <th>
                <div
                  className="title"
                  style={{ cursor, minWidth: '40px' }}
                >
                  状態
                </div>
              </th>
            )}
            <th>
              <div className="title">
                所持者
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="list" data-testid="mamorio-item-list-body">
          {
            mamorios.map((mamorio: IMamorio) => (
              <tr
                key={mamorio.id}
                className="mamorio-item-list-item"
                onClick={() => setSelectedMamorio(mamorio)}
              >
                <td className="item mamorio-item">
                  <img width={40} className="image" src={getMamorioImageSrc(mamorio, defaultItemIcon)} alt="member" onError={(e) => { e.currentTarget.src = defaultItemIcon }} />
                  <span className="name">
                    {mamorio.name}
                  </span>
                </td>
                {showMemo() && (
                  <td className="item mamorio-item">
                    <span className="name">
                      {mamorio.memo ? mamorio.memo : '-'}
                    </span>
                  </td>
                )}
                <td className="item item-last-detected-at">
                  {mamorio?.recent_log ? getFromNowAsText(mamorio.recent_log.at) : '-'}
                </td>
                <td className="item item-detected-by">
                  <img className="item-icon" src={getLastStatus(mamorio).imgSrc} style={{ borderRadius: 0 }} alt="status" />
                  {getLastStatus(mamorio).text}
                </td>

                {/* ----- バッテリー残量 ----- */}
                <td className="item item-detected-by">
                  <div className="img-name-item">
                    <img className="item-icon" src={getBatteryImageSrc(mamorio)} style={{ borderRadius: 0 }} alt="status" />
                  </div>
                </td>

                {/* ----- 紛失ステータス ----- */}
                {showLostAndBattery() && (
                  <td className="item item-detected-by">
                    <div className="img-name-item">
                      {getLostFoundStatus(mamorio)}
                    </div>
                  </td>
                )}
                <td className="item">
                  {mamorio.is_rent
                    ? (
                      <div className="sub">
                        <div className="status-rental rental">
                          持出中
                        </div>
                        <div className="rental-owner">
                          {mamorio.user ? getUserName(mamorio.user) : '所有者がいません'}
                        </div>
                      </div>
                    )
                    : (
                      <div className="sub">
                        <span className="rental-owner">
                          { mamorio.user ? getUserName(mamorio.user) : '-' }
                        </span>
                      </div>
                    )}
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      {selectedMamorio && (
        <DetailMamorioModal
          modalProps={{
            mamorio: selectedMamorio,
            group,
          }}
          closeModalFunction={closeModal}
        />
      )}
    </div>
  )
}

export default MamorioListOnHeatmap
