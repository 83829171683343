import PropTypes from 'prop-types'
import React from 'react'
import CheckedIcon from '../../icons/checked.png'
import NotCheckedIcon from '../../icons/not_checked.png'

type Props = {
  id: GeneralId
  label: string
  onCheckItem: (id: GeneralId, label: string) => void
  checked: boolean
}

const CheckableListItem = ({
  id,
  label,
  onCheckItem,
  checked,
}: Props) => (
  <div className="selectable-item">
    <div
      className="selectable-item-content"
      onClick={() => onCheckItem(id, label)}
    >
      <img
        src={checked ? CheckedIcon : NotCheckedIcon}
        className="selectable-item-check-icon"
        alt="check-icon"
      />
      <div className="selectable-item-label">
        {label}
      </div>
    </div>
  </div>
)

CheckableListItem.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

export default CheckableListItem
