import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { ERROR_MODAL } from '../../components/common/RootModal'
import { showModal, closeModal } from '../../modules/UI'
import { login } from '../../modules/User'
import { OVERVIEW } from '../../constants/Paths'
import { HeaderNavi } from '../../components/navigation'
import { loginLogoIcon } from '../../icons'
import InlineLoadingAnimation from '../../components/common/InlineLoadingAnimation'
import { useAppDispatch, useAppSelector } from '../../hooks'
import './Login.css'
import { getAccessToken } from '../../modules/common/session'
import { selectLoadingState } from '../../store'
import { getErrorString } from '../../utils/errorUtils'

const enum TextInputType {
  Email,
  Password,
}

type LoginInfo = {
  value: string,
  valid: boolean,
}

/**
 * Login 画面
 * @returns {JSX.Element}
 * @constructor
 */
const Login = () => {
  // States -----------------------------------------
  const [email, setEmail] = useState<LoginInfo>({
    value: '',
    valid: false,
  })

  const [password, setPassword] = useState<LoginInfo>({
    value: '',
    valid: false,
  })

  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(!(email.valid && password.valid))

  // Redux -----------------------------------------
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectLoadingState)

  // History -----------------------------------------
  const history = useHistory()

  // Effects -----------------------------------------
  useEffect(() => {
    setIsLoginButtonDisabled(!(email.valid && password.valid))
  }, [email.valid, password.valid])

  // Methods -----------------------------------------
  const showErrorModal = useCallback((message: string) => {
    if (message === undefined || message === null) return

    dispatch(closeModal({ modalType: ERROR_MODAL }))

    dispatch(showModal({
      modalType: ERROR_MODAL,
      modalProps: {
        message,
      },
    }))
  }, [dispatch])

  /**
   * 現在のメールアドレスとパスワードが正しいフォーマットかを返す
   * @returns {boolean}
   */
  const isEmailAndPasswordValid = () => {
    if (email.valid === false) {
      showErrorModal('メールアドレスが正しくありません。')
      return false
    }

    if (password.valid === false) {
      showErrorModal('パスワードは8文字以上で入力して下さい。')
      return false
    }

    return true
  }

  // UI event userApiHandlers -----------------------------------------
  /**
   * 入力フォームの onChange イベントのハンドラー
   * 入力値の検証を行う
   * @param type 入力値のタイプを表す文字列 ('EMAIL' || 'PASSWORD')
   * @param target event.target
   */
  const handleChangeTextInput = (type: TextInputType, target: HTMLInputElement) => {
    switch (type) {
      case TextInputType.Email:
        setEmail({
          value: target.value,
          valid: target.validity.valid && target.value.length > 0,
        })
        break
      case TextInputType.Password:
        setPassword({
          value: target.value,
          valid: target.validity.valid && target.value.length > 7,
        })
        break
      default:
        break
    }
  }

  /**
   * ログインボタンの onClick イベントのハンドラー
   * @param event
   * @returns {Promise<void>}
   */
  const handleSubmitLoginForm = async (event: React.FormEvent) => {
    event.preventDefault()

    if (!isEmailAndPasswordValid()) return

    try {
      const response = await dispatch(login({
        email: email.value,
        password: password.value,
      })).unwrap()

      if (response.mfaStatus) {
        history.push({
          pathname: '/verification',
          state: {
            email: email.value,
            password: password.value,
          },
        })
      } else {
        window.location.reload()
      }
    } catch (e: unknown) {
      showErrorModal(getErrorString(e))
    }
  }

  if (getAccessToken() !== null) {
    return (<Redirect to={OVERVIEW} />)
  }

  // Render -----------------------------------------
  return (
    <div className="login-content">
      <HeaderNavi />
      <div className="mamorio-login-form">
        <form
          onSubmit={handleSubmitLoginForm}
          data-testid="login-form"
        >
          <img src={loginLogoIcon} className="mamorio-login-logo" alt="office-logo" />
          <input
            type="email"
            name="mail"
            autoComplete="email"
            size={80}
            placeholder="メールアドレス"
            onChange={(event) => handleChangeTextInput(TextInputType.Email, event.target)}
            data-testid="email-input"
          />
          <input
            type="password"
            name="password"
            autoComplete="password"
            size={80}
            placeholder="パスワード"
            onChange={(event) => handleChangeTextInput(TextInputType.Password, event.target)}
            data-testid="password-input"
          />
          <button
            type="submit"
            name="login"
            className="btn btn-primary btn-lg"
            disabled={isLoginButtonDisabled}
            data-testid="login-button"
          >
            {isLoading ? (
              <div>
                <InlineLoadingAnimation
                  size="32px"
                  color="#FFF"
                />
              </div>
            ) : 'ログイン'}
          </button>
        </form>
      </div>
      <div className="mamorio-login-menu">
        <a href={`${process.env.REACT_APP_BASE_PATH}/users/sign_in?office=1`}>
          新規アカウントを作成
        </a>
        <br />
        <a href={`${process.env.REACT_APP_BASE_PATH}/users/password/new`}>
          パスワードをお忘れですか？
        </a>
      </div>
      <div className="mamorio-login-copyright">
        Copyright &copy; 2018 MAMORIO,Inc. All Rights Reserved.
      </div>
    </div>
  )
}

export default Login
