import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import uiReducer from '../modules/UI'
import userReducer from '../modules/User'
import mamorioReducer from '../modules/Mamorio'
import groupReducer from '../modules/Group'
import rentalReducer from '../modules/Rental'
import teamReducer from '../modules/Team'
import newsReducer from '../modules/News'

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    user: userReducer,
    mamorio: mamorioReducer,
    group: groupReducer,
    rental: rentalReducer,
    team: teamReducer,
    news: newsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        'mamorio/successGetGroupMamorios',
        'mamorio/successUpdateMamorio',
        'mamorio/successUpdateFilter',
        'mamorio/getGroupMamorios/pending',
        'mamorio/getGroupMamorios/fulfilled',
        'mamorio/getGroupMamorios/rejected',
        'group/successGetRootGroup',
        'group/getRootGroup/pending',
        'group/getRootGroup/fulfilled',
        'group/getRootGroup/rejected',
        'group/get/pending',
        'group/get/fulfilled',
        'group/get/rejected',
        'ui/setGroupNaviState',
        'ui/toggleGroupNavi',
        'ui/showModal',
        'ui/closeModal',
        'ui/showLoading',
        'ui/hideLoading',
        'ui/requestStart',
        'ui/requestFinished',
        'user/get/fulfilled',
        'user/successLogout',
        'rental/getGroupMamoriosQrCode/pending',
        'rental/getGroupMamoriosQrCode/fulfilled',
      ],
    },
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>

export const selectLoadingState = (state: RootState) => state.ui.loading
