import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getNews } from '../../modules/News'
import { GroupNavi, HeaderNavi } from '../navigation'
import { updateUser } from '../../modules/User'
import './news.css'
import { getFormattedDate } from '../../helpers/timeHelper'

const NewsPage = () => {
  const dispatch = useAppDispatch()
  const news = useAppSelector((state) => state.news.newsItems)

  useEffect(() => {
    /* eslint-disable */
    dispatch(getNews()).then((_) => {
      dispatch(updateUser({ read_news_at: new Date() }))
    })
    /* eslint-enable */
  }, [dispatch])

  return (
    <div
      className="content"
      data-testid="overview"
    >
      <HeaderNavi />
      <GroupNavi>
        <div className="news-body">
          <h1 className="news-page-title">
            おしらせ
          </h1>
          {news.map((n: INews) => (
            <div className="news" key={n.id}>
              <h2 className="title">{n.title}</h2>
              <p className="posted_at">{getFormattedDate(n.updated_at)}</p>
              <p className="news-content">{n.content}</p>
            </div>
          ))}
        </div>
      </GroupNavi>
    </div>
  )
}

export default NewsPage
