/* returns true if resolution is mobile */
export const isMobile = (): boolean => window.innerWidth <= 768

export default isMobile()

export const isSafari: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

/**
 * 与えられた文字列が有効かどうかを判定する
 * @param value 文字列
 * @return boolean 与えられた文字列が null / undefined / 空文字列のいずれかであれば false, そうでなければ true
 */
export const isValidString = (value: string): boolean => !(!value || value.length === 0)

export const isNullOrUndefined = (value: any): boolean => typeof value === 'undefined' || value === null

/**
 * string または number を任意の Id 型に変換する
 * @param value
 */
export const toId = <T extends GeneralId>(value: string | number): T | never => {
  const typeofValue = (typeof value)

  if (typeofValue === 'number' || typeofValue === 'bigint') {
    return value as T
  }

  if (typeofValue === 'string') {
    return Number(value) as T
  }

  throw new Error('Argument must be string or number')
}
