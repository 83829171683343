import React from 'react'
import PropTypes from 'prop-types'

type Props = {
  onClick: (event: React.MouseEvent) => Promise<void> | void
}

const LendMamorioButton = ({
  onClick,
}: Props) => (
  <button
    type="button"
    className="btn btn-primary"
    onClick={onClick}
    data-testid="lend-mamorio-button"
  >
    このグループのMAMORIOを貸し出す
  </button>
)

LendMamorioButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default LendMamorioButton
