import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import 'moment/locale/ja'
import {
  getUserName,
  getLastStatus,
  getMamorioImageSrc,
  getBatteryImageSrc,
  getLostFoundStatus,
} from '../../helpers/CommonMamorioHelper'
import { getFromNowAsText } from '../../helpers/timeHelper'
import { MAMORIO_SHAPE } from '../../constants/Shapes'
import { itemIcon } from '../../icons'
import { showLostAndBattery, showMemo } from '../../modules/common/session'

type Props = {
  mamorio: IMamorio
  isStolen: boolean
  onClickList: (arg: any | void) => void
}

const AntiStealListItem = ({
  mamorio,
  isStolen,
  onClickList,
}: Props) => {
  const recentLog = mamorio.recent_log

  return (
    <Fragment key={mamorio.id + Math.round(Math.random() * 10000).toString()}>
      <tr
        className="list-row overview-item-list-item"
        key={mamorio.id.toString()}
        onClick={onClickList}
      >
        {/* ----- アイテム名 ----- */}
        <td className="item item-name">
          <div className="img-name-item">
            <img className="item-icon" src={getMamorioImageSrc(mamorio)} alt="mamorio" onError={(e) => { e.currentTarget.src = itemIcon }} />
            <div>
              <strong className="item-name-text">
                {mamorio.name}
              </strong>
              <br />
              <span className="item-majorminor-text" style={{ fontSize: '12px', color: '#757575' }}>
                {`${mamorio.major}-${mamorio.minor}`}
              </span>
            </div>
          </div>
        </td>
        <td className="item item-name">
          {isStolen ? (
            <div
              style={{
                background: 'red',
                padding: '5px',
                display: 'inline',
                borderRadius: '5px',
                color: 'white',
              }}
            >
              取り外し中
            </div>
          ) : (
            <div>-</div>
          )}
        </td>
        {showMemo() && (
          <td className="item item-detected-by">
            <div className="img-name-item">
             {mamorio.memo ? mamorio.memo : '-'}
            </div>
          </td>
        )}

        {/* ----- 最終検知時刻 ----- */}
        <td className="item item-last-detected-at">
          {recentLog ? getFromNowAsText(recentLog.at) : '-'}
        </td>

        {/* ----- 検知した端末 ----- */}
        <td className="item item-detected-by">
          <div className="img-name-item">
            <img className="item-icon" src={getLastStatus(mamorio).imgSrc} style={{ borderRadius: 0 }} alt="status" />
            <div className="status">
              <div className="main item-detected-by-text">
                {getLastStatus(mamorio).text}
              </div>
            </div>
          </div>
        </td>

        {/* ----- バッテリー残量 ----- */}
        <td className="item item-detected-by">
          <div className="img-name-item">
            <img className="item-icon" src={getBatteryImageSrc(mamorio)} style={{ borderRadius: 0 }} alt="status" />
          </div>
        </td>

        {/* ----- 紛失ステータス ----- */}
        {showLostAndBattery() && (
          <td className="item item-detected-by">
            <div className="img-name-item">
              {getLostFoundStatus(mamorio)}
            </div>
          </td>
        )}

        {/* ----- 所持者 ----- */}
        <td className="item">
          <div className="status">
            <div className="main">
              {mamorio.is_rent
                ? (
                  <div className="sub">
                    <div className="status-rental rental">
                      持出中
                    </div>
                    <div className="rental-owner">
                      {mamorio.user ? getUserName(mamorio.user) : '所有者がいません'}
                    </div>
                  </div>
                )
                : (
                  <div className="sub">
                    <span className="rental-owner">
                      { mamorio.user ? getUserName(mamorio.user) : '-' }
                    </span>
                  </div>
                )}
            </div>
          </div>
        </td>
      </tr>

      <tr key={`divider-${mamorio.id}`} className="divider">
        <td colSpan={6}>
          <div className="line" />
        </td>
      </tr>
    </Fragment>
  )
}

AntiStealListItem.propTypes = {
  mamorio: MAMORIO_SHAPE.isRequired,
  onClickList: PropTypes.func.isRequired,
}

export default AntiStealListItem
