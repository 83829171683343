import React from 'react'
import moment from 'moment'
import { parseAsync } from 'json2csv'
import {
  iconStatusNetwork,
  iconStatusSmartPhone,
  iconStatusSpot,
  iconStatusNotFound,
  iconBatteryUnknown,
  iconBatteryFull,
  iconBatteryHalf,
  iconBatteryLittle,
  iconBatteryEmpty,
  itemIcon,
} from '../icons'
import { getDurationFrom } from './timeHelper'

moment.locale('ja')

export const getMamorioImageSrc = (mamorio: IMamorio | undefined, defaultIconSrc: string = itemIcon): string => {
  if (!mamorio || !mamorio.image.url || !mamorio.image.retina || mamorio.image.retina.url === 'null') {
    return defaultIconSrc
  }
  return mamorio.image.retina.url
}

export const getUserName = (user: Pick<IUser, 'name' | 'email'>): string => user.name || user.email

export const isMamorio = (object: IMamorio): boolean => typeof object.minsaga !== 'undefined'
export const isDevice = (object: IMamorio): boolean => typeof object.minsaga === 'undefined'

export const getLastStatus = (mamorio: IMamorio): {
  imgSrc: string,
  text: string
} => {
  const lastLog = mamorio.recent_log

  if (!lastLog) {
    return {
      imgSrc: iconStatusNotFound,
      text: '発見情報がありません',
    }
  }

  // FIXME 情報公開の可否はサーバー側で判断すべきか？
  if (lastLog.group) {
    if (lastLog.spot && lastLog.spot.name) {
      return {
        imgSrc: iconStatusSpot,
        text: lastLog.spot.name,
      }
    }
    if (lastLog.device && lastLog.device.name) {
      return {
        imgSrc: iconStatusSmartPhone,
        text: lastLog.device.name,
      }
    }
    if (lastLog.finder) {
      return {
        imgSrc: iconStatusSmartPhone,
        text: getUserName(lastLog.finder),
      }
    }
    return {
      imgSrc: iconStatusSmartPhone,
      text: 'メンバー',
    }
  }
  if (lastLog.finder) {
    return {
      imgSrc: iconStatusSmartPhone,
      text: getUserName(lastLog.finder),
    }
  }
  return {
    imgSrc: iconStatusNetwork,
    text: 'クラウドネットワーク',
  }
}

export const getMamoriosWithLocation = (mamorios: IMamorio[]): any[] => (
  mamorios.filter((mamorio) => mamorio.recent_log)
)

export const getRentalStatus = (mamorio: IMamorio): JSX.Element => {
  if (mamorio.is_rent === true) {
    return (
      <div className="status-rental rental">
        持出中
      </div>
    )
  }
  if (mamorio.is_rent === false) {
    return (
      <div className="status-rental returned">
        返却済
      </div>
    )
  }
  return (
    <div className="status-rental">
      -
    </div>
  )
}

export const getLostFoundStatus = (mamorio: IMamorio): JSX.Element => {
  if (mamorio.recent_log?.at && getDurationFrom(mamorio.recent_log?.at).asMinutes() < 30) {
    return (
      <div className="status-detection detected">
        検知
      </div>
    )
  }
  return (
    <div className="status-detection notdetected">
      未検知
    </div>
  )
}

export const getBatteryImageSrc = ({ estimated_remain_battery }: IMamorio): string => {
  if (estimated_remain_battery == null) {
    return iconBatteryUnknown
  }

  switch (true) {
    case estimated_remain_battery > 50:
      return iconBatteryFull
    case estimated_remain_battery > 20:
      return iconBatteryHalf
    case estimated_remain_battery > 0:
      return iconBatteryLittle
    default:
      return iconBatteryEmpty
  }
}

const handleBlob = (data: BlobPart[], options: BlobPropertyBag, callback: (url: string) => void) => {
  const url = URL.createObjectURL(new Blob(data, options))
  callback(url)
  URL.revokeObjectURL(url)
}

export const generateRentalDigitCSV = async (qrCodes: IQrCode[]) => {
  const csv = await parseAsync(qrCodes, {
    fields: [
      {
        label: '名称',
        value: 'resource_name',
      },
      {
        label: 'digit_code',
        value: 'digit_code',
      },
      {
        label: 'code',
        value: 'digit_code',
      },
    ],
    withBOM: true,
  })
  console.error(csv)
  handleBlob([`${csv}\n`], { type: 'text/csv' }, (url) => {
    const a = document.createElement('a')
    a.href = url
    a.download = 'qrCodes.csv'
    a.click()
  })
}
